const initialState = {
  countries: {
    loading: false,
    error: null,
    data: [],
  },
};

export default (state = initialState, { type, error, data }) => {
  switch (type) {
    case 'GET_COUNTRIES':
      return {
        ...state,
        countries: {
          ...state.countries,
          loading: true,
        },
      };

    case 'GET_COUNTRIES_SUCCESS':
      return {
        ...state,
        countries: {
          ...state.countries,
          loading: false,
          data,
        },
      };

    case 'GET_COUNTRIES_FAIL':
      return {
        ...state,
        countries: {
          ...state.countries,
          loading: false,
          error,
        },
      };

    default:
      return state;
  }
};
