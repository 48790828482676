import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormControl, NavDropdown, MenuItem } from 'react-bootstrap';

import * as researchActions from '../actionCreators/Research';

const Component = ({
  suggestions,
  value,
  defaultValue,
  onSelect,
  onChange,
  maxResults,
  remote,
  remoteData,
  getSuggestions,
  ...props
}) => {
  const [text, setText] = React.useState(defaultValue || value);
  const [showSuggestions, setShowSuggestions] = React.useState(true);
  const valueText = value || text;
  let suggestionValues = [];

  if (remote) {
    Object.keys(remoteData.data).forEach(key =>
      remoteData.data[key].forEach(name => suggestionValues.push({ name }))
    );
  } else {
    suggestionValues = suggestions;
  }

  React.useEffect(() => {
    if (remote) {
      getSuggestions({ q: valueText });
    }
  }, [valueText, getSuggestions, remote]);

  return (
    <>
      <FormControl
        type="text"
        value={valueText}
        onFocus={() => {
          if (!showSuggestions) {
            setShowSuggestions(true);
          }
        }}
        onClick={() => {
          if (!showSuggestions) {
            setShowSuggestions(true);
          }
        }}
        onChange={e => {
          if (!showSuggestions) {
            setShowSuggestions(true);
          }

          setText(e.target.value);
          onChange(e.target.value);
        }}
        onKeyDown={e => {
          if (e.keyCode === 40) {
            const element = document.getElementById('autosuggest_localRef');
            if (element) {
              element.focus();
            }
          }
        }}
        {...props}
      />
      {showSuggestions && valueText && suggestionValues[0] && (
        <NavDropdown title="" className="auto-suggestion-dropdown open" id={valueText}>
          {suggestionValues
            .filter(suggestion =>
              String(suggestion.name)
                .toLowerCase()
                .includes(valueText.toLowerCase())
            )
            .filter((v, i) => i < maxResults)
            .map((suggestion, index) => (
              <MenuItem
                id={index === 0 && `autosuggest_localRef`}
                tabIndex={0}
                key={`autosuggest_suggestion_${index}`}
                onClick={() => {
                  setText(suggestion.name);

                  if (showSuggestions) {
                    setShowSuggestions(false);
                  }

                  onSelect(suggestion);
                }}
              >
                {suggestion.name}
              </MenuItem>
            ))}
        </NavDropdown>
      )}
    </>
  );
};

const mapStateToProps = ({ research: { suggestions } }) => ({
  remoteData: suggestions,
});

const mapDispatchToProps = dispatch => ({
  getSuggestions: bindActionCreators(researchActions.getSuggestions, dispatch),
});

Component.propTypes = {
  remoteData: PropTypes.object.isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  maxResults: PropTypes.number,
  remote: PropTypes.bool,
  getSuggestions: PropTypes.func.isRequired,
};

Component.defaultProps = {
  suggestions: [],
  value: '',
  defaultValue: null,
  onChange: () => {},
  maxResults: 5,
  remote: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
