/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
    M001 : 24 Jun 2021 : Aman
    * M-0003453: Event registration Waiting for Approval table should not show the columns Approved By and Approved Date
**************************************************************************************************************
*/
import React, { useState } from 'react';
import { Col, Glyphicon } from 'react-bootstrap';
import { connect } from 'react-redux';
import Box from '../../../components/Box';
import { doPost } from '../../../utils/request';

const TranscriptContainer = ({
    azureMP,
    transcriptLink,
    currentTime,
    setNewTime,
    vidId
}) => {
  //STATE
  const [transcirptData, setTranscriptData] = useState();
  const [idToEdit, setIdToEdit] = useState(-1);
  const [tempTranscription, setTempTranscription] = useState([]);
  const [isTranscriptChanged, setIsTranscriptChanged] = useState(false);
  const [idEditVisible, setIdEditVisible] = useState(-1);

  //EFFECT
  React.useEffect(()=>{
    async function fetchTranscript() {
        if (transcriptLink) {
            const res = await fetch(transcriptLink);
            const jsonRes = await res.json();
            setTranscriptData(jsonRes);
            setTempTranscription(jsonRes.transcript)
        } else {
            setTranscriptData(null);
        }
    }
    fetchTranscript();
  },[transcriptLink])

  React.useEffect(()=>{
    if (transcirptData) {
        for (var i = 0; i< transcirptData.transcript.length; i++) {
            var item = transcirptData.transcript[i];
            if (hmsToSecondsOnly(item.instances[0].start)<currentTime&&hmsToSecondsOnly(item.instances[0].end)>currentTime) {
                let obj =document.getElementById("transcript_"+i);
                let f = navigator.userAgent.search("Firefox");
                if (!(f>-1)){
                    obj.scrollIntoViewIfNeeded(
                        true
                    );
                } else {
                    obj.scrollIntoView({inline:"center",block:"center"})
                }
                return;
            }
        }
    }
  }, [currentTime])

  //INLINE FUNCTION
    const hmsToSecondsOnly = (str) => {
        var p = str.split(':'),
            s = 0, m = 1;
        while (p.length > 0) {
            s += m * parseInt(p.pop(), 10);
            m *= 60;
        }
        return s;
    }

    const changeTranscript = (id, text) => {
        const tr = [...tempTranscription];
        const foundIndex = tr.findIndex(t => t.id === id);

        if (found < 0) {
            return
        }
        
        const found = tr[foundIndex];
        
        if (found.text !== text) {
            found.text = text;
            setIsTranscriptChanged(true);
        }

        
        tr[foundIndex] = found;

        setTempTranscription(tr);
        setIdToEdit(-1);
    }

    const showEditBtn = (id) => {
        setIdEditVisible(id);
    }

    const onSaveTranscription = () => {
        const jsonObj = transcirptData;
        jsonObj.transcript = tempTranscription;


        doPost(`/transcript/${vidId}`, { jsonObj }).then((res) => {
            if (res.data && res.data.success) {
                alert('Transcript Updated');
                window.location.reload(false);
            } else {
                alert('There was an error updating the transcript');
            }
        })
    }

    

  return (
    <Col xsHidden={true} mdHidden={true} smHidden={true} sm={4} style={{marginBottom:10}}>
        <Box style={{ padding: 0, borderRadius: '5px' }}>
            <p style={{ 
                textAlign: 'center', 
                padding: '2%',
                background: 'rgb(3, 108, 166)',
                color: 'white',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                fontSize: '115%',
                fontWeight: '700',
                marginBottom: 2
              }}>
                  Audio Transcript
                  {
                      isTranscriptChanged &&
                      <button onClick={onSaveTranscription} className="btn trasncript-save-btn">Save</button>
                  }
            </p>
            {/* SEARCH BAR */}
            <div style={{
                boxShadow: '0 1px 1px 0 rgb(0 0 0 / 12%), 0 0 4px 0 rgb(0 0 0 / 14%)'
            }}>
                <span>
                    <input
                        onChange={event=>{
                            azureMP.pause();
                            if (transcirptData) {
                                for (var i = 0; i< tempTranscription.length; i++) {
                                    var item = tempTranscription[i];
                                    if (item.text.indexOf(event.target.value)>0) {
                                        setTimeout(()=>{
                                            let obj =document.getElementById("transcript_"+i);
                                            obj.click();
                                            let f = navigator.userAgent.search("Firefox");
                                            if (!(f>-1)){
                                                obj.scrollIntoViewIfNeeded(
                                                    true
                                                );
                                            }
                                        },50)
                                        return;
                                    }
                                }
                            }
                        }}
                        className="react-tagsinput-input"
                        type="text"
                        id="inputField"
                        autoComplete="off"
                        placeholder="Search transcript"
                    />
                </span>
            </div>
            {/* SEARCH BAR */}

            {/* SCROLLABLE TRANSCRIPT ITEMS */}
            <div onMouseLeave={() => { showEditBtn(-1) }} style={{ padding: '2%', maxHeight: 385, overflowY: 'scroll' }}>
                {transcirptData?
                    <>
                        {
                            tempTranscription?
                            tempTranscription.map((item,i)=>(
                                <div    id={"transcript_" + i} 
                                        key={i}
                                        onMouseEnter={() => { showEditBtn(item.id) }}
                                        className={
                                            hmsToSecondsOnly(item.instances[0].start)<=currentTime&&hmsToSecondsOnly(item.instances[0].end)>currentTime?
                                            "highlighted-transcript row":"row"}
                                        onClick={()=>{
                                            setNewTime(hmsToSecondsOnly(item.instances[0].start))
                                            azureMP.play();
                                        }}
                                >
                                    <Col md={11} sm={11}>
                                        {
                                            idToEdit != item.id &&
                                            <div>
                                                {/* TIME */}
                                                <span>{
                                                    i==0?item.instances[0].start:
                                                    item.instances[0].start.substring(0,item.instances[0].start.lastIndexOf('.'))
                                                } : </span>
                                                {/* TIME */}
                                                {/* Transcript */}
                                                <span>{item.text}</span>
                                            </div>
                                        }

                                        {
                                            idToEdit === item.id &&
                                            <textarea
                                                onClick={(e) => { e.stopPropagation() }}
                                                onBlur={(e) => { changeTranscript(item.id, e.target.value) }}
                                                cols="30"
                                                className="form-control"
                                                defaultValue={item.text}
                                            />
                                        }

                                         {/* Transcript */}
                                    </Col>
                                    {idEditVisible === item.id &&
                                        <Col md={1} sm={1}>
                                            <Glyphicon className="transcript-edit-icon" onClick={(e) => { e.stopPropagation(); setIdToEdit(item.id) }} glyph="glyphicon glyphicon-pencil" />
                                        </Col>
                                    }
                                </div>
                            ))
                            :
                            <p>
                                Transcript not available yet
                            </p>
                        }
                    </>
                    :
                    <p style={{textAlign:'center'}}>
                        Transcript not available yet
                    </p>
                }
            </div>
            <p style={{padding:6, textAlign: 'right', fontSize: 9}}>Powered by <a href="https://azure.microsoft.com/en-us/services/media-services/">Azure Media Services</a></p>
            {/* SCROLLABLE TRANSCRIPT ITEMS */}
        </Box>
    </Col>
  );
};

const mapStateToProps = (state) => {
    const currentTime = state.videos.currentVideo.currentTime;
    return { currentTime };
}
const mapDispatchToProps = dispatch => ({
});


export default connect(mapStateToProps,mapDispatchToProps)(TranscriptContainer);

