/*
**************************************************************************************************************
										R&D TECH LIMITED
==============================================================================================================
The contents of this file ("Software") is the property of R&D Tech Limited ("R&D Tech") and is protected by
copyright and other intellectual property laws and treaties.

R&D Tech owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of copies of the
Software, redistribution of the Software and creation of derived works from the Software (and the
redistribution thereof) are subject to the terms of a license agreement issued by R&D Tech.

If the person in possession of the Software has not entered into a license agreement with R&D Tech, then any
use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author:
	Date:
**************************************************************************************************************
											Usage Notes
==============================================================================================================

**************************************************************************************************************
											Amendments
==============================================================================================================
	M003 : 21 Sep 2022 : Nick Ng
      * M-3844: Allow video access without subscription checking
  M002 : 27 Apr 2021 : Nick Ng
    * M-3382: Website UI Improve - Aletheia Connect
  M001 : 3 Dec 2020 : Aman
    * M-3149: Various minor changes to CONNECT page
**************************************************************************************************************
*/

import React, { useEffect, useState } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom'; //M002
import * as videoActions from '../../../actionCreators/Videos';
import * as AnalystActions from '../../../actionCreators/Analyst';
import * as SectorActions from '../../../actionCreators/Sector';
import * as configActions from '../../../actionCreators/Config';
import ConnectHeader from './connectHeader';
import ConnectPlayer from './connectPlayer';
import FullPageLoader from '../../../components/FullPageLoader';
import starLine from '../../../assets/images/bookmark-4-starline.svg';
import starFilled from '../../../assets/images/bookmark-4-yellow-filled.svg';
import ReportCard from '../../../containers/Reports/components/ReportCard'; //M002
import { getStandardDateTime } from '../../../utils/dateTime'; //M002
import HighlightedPanel from '../../../components/HighlightedPanel'; //M002
import { OnAtLeastTablet } from '../../../components/ReactBreak'; //M002
import TranscriptContainer from './transcriptContainer';

const VideosList = ({
  videos,
  getAllVideos,
  mostwatch,
  getMostWatched,
  accessvideo,
  getAccessVideo,
  savedvideos,
  getSavedVideos,
  loading,
  SaveVideo,
  history,
  getParameter,
  parameters,
}) => {
  const location = useLocation();

  let urlParameters = location.pathname.split('/');
  var id = urlParameters[3];
  const [TITLE, setTITLE] = React.useState();
  const [DESCRIPTION, setDESCRIPTION] = React.useState();
  //====================M001==========================
  const [SavedNew, setSavedNew] = React.useState();
  const [azureMP, setAzureMP] = React.useState();
  const [newTime, setNewTime] = React.useState();
  //====================M001==========================

  const [vidId, setVidId] = useState(-1);
  const [connectConfigJSON, setConnectConfigJSON] = useState(undefined);
  const [subtitleHint, setSubtitleHint] = useState(undefined);
  const [hideTranscript, setHideTranscript] = useState(undefined);

  // Loading All the API when first loaded
  React.useEffect(() => {
    getAllVideos();
    getMostWatched();
    getSavedVideos();
    getParameter('sConnectConfigJSON,rw_SubtitleHintText,rw_HideTranscriptPanel');
  }, []);

  React.useEffect(() => {
    if (id != null) {
      getAccessVideo(id, id);
    }
  }, [id]);

  React.useEffect(() => {
    if (id == undefined && 'video_path' in accessvideo.data) {
      window.location.reload();
    }
  }, [id, accessvideo]);

  function useQuery() {
    const { search } = location;
    return new URLSearchParams(search);
  }

  const query = useQuery();

  //====================M001==========================
  React.useEffect(() => {
    setSavedNew(savedvideos.data);
  }, [savedvideos]);
  //====================M001==========================
  React.useEffect(() => {
    if (id != null) {
      const selectedVideo = videos.data.find(item => item.id == id);
      if (!selectedVideo) {
        return;
      }
      setTITLE(selectedVideo.title);
      setDESCRIPTION(selectedVideo.description);
      setVidId(selectedVideo.id);
    }
  }, [videos.data]);

  React.useEffect(() => {
    if (accessvideo.error !== null) {
      accessvideo.error = null;
      history.push('/login');
    }
  }, [accessvideo.error]);

  const setPlayerTimeFromQuery = player => {
    let time = query.get('time');
    time = Number.parseInt(time);

    if (!player || isNaN(time)) return;

    setNewTime(time);
    player.currentTime(time);
    player.play();
  };

  React.useEffect(() => {
    if (azureMP && newTime) {
      azureMP.currentTime(newTime);
    }
  }, [newTime]);

  //====================M002==========================
  const saveVideo = docid => {
    var videoId;
    videos.data.map(item => {
      if (item.doc_id == docid) {
        videoId = item.id;
      }
    });
    var savedarray = SavedNew;
    const index = savedarray.findIndex(x => x.doc_id == docid);
    if (index > -1) {
      savedarray.splice(index, 1);
      setSavedNew(JSON.parse(JSON.stringify(savedarray)));
    } else {
      savedarray.push({ doc_id: docid });
      setSavedNew(JSON.parse(JSON.stringify(savedarray)));
    }
    SaveVideo(docid, docid);
  };

  const formatMostWatch = () => {
    var returnMostWatchArr = [];
    mostwatch.data.map(mostvideo => {
      videos.data.map(item => {
        if (item.id == mostvideo.id) {
          returnMostWatchArr.push(formatVideoResearchObj(item));
        }
      });
    });
    return returnMostWatchArr;
  };

  const formatSaved = () => {
    var returnSavedArr = [];
    if (SavedNew != null && SavedNew != undefined) {
      SavedNew.map(savedNewVideo => {
        videos.data.map(item => {
          if (item.doc_id == savedNewVideo.doc_id) {
            returnSavedArr.push(formatVideoResearchObj(item));
          }
        });
      });
    }
    return returnSavedArr;
  };

  const formatVideoResearchObj = item => {
    var returnObj = {};
    returnObj = {
      title: (
        <>
          <Link
            href={`/connect/videos/${item.id}`}
            to={`/connect/videos/${item.id}`}
            className="card-title"
          >
            {item.title}
          </Link>
        </>
      ),
      analysts: item.analysts,
      published_date: getStandardDateTime(item.published_date),
      description: item.description,
      companies: [],
      countries: [],
      coverage: item.coverage,
      sectors: item.sectors,
      id: item.doc_id,
    };
    return returnObj;
  };

  useEffect(() => {
    if (
      parameters != null &&
      parameters != undefined &&
      parameters.sConnectConfigJSON &&
      parameters.rw_SubtitleHintText &&
      parameters.rw_HideTranscriptPanel
    ) {
      console.log('parameters =====>', JSON.parse(parameters.sConnectConfigJSON));
      setConnectConfigJSON(JSON.parse(parameters.sConnectConfigJSON));
      setSubtitleHint(parameters.rw_SubtitleHintText);
      setHideTranscript(parameters.rw_HideTranscriptPanel);
    }
  }, [parameters]);

  //====================M001,M002==========================

  return (
    <>
      {/* CONNECT - M002*/}
      <Grid>
        <Row>
          <Col md={12} lg={8}>
            {/* PLAYER SECTION ==================M003===========[=============*/}
            {!loading ? (
              accessvideo.data && connectConfigJSON != undefined && subtitleHint != undefined ? (
                <ConnectPlayer
                  LINK={
                    accessvideo.data.video_path ? accessvideo.data.video_path : accessvideo.data
                  }
                  onPlayerLoaded={setPlayerTimeFromQuery}
                  setAzureMP={setAzureMP}
                  sConnectConfigJSON={connectConfigJSON}
                  subtitleHint={subtitleHint}
                ></ConnectPlayer>
              ) : null
            ) : (
              <FullPageLoader></FullPageLoader>
            )}
            {/* PLAYER SECTION ==================M003===========]=============*/}

            {/* CONNECT HEADER */}
            <ConnectHeader TITLE={TITLE} DESCRIPTION={DESCRIPTION}></ConnectHeader>
            {/* CONNECT HEADER */}

            {/* REPORT SECTION */}
            <div>
              {videos.data.map(item => (
                <Row>
                  {/* REUSE REPORT CARD */}
                  <ReportCard
                    key={`connect_video_${item.id}`}
                    style={{ margin: '4px 0px', borderRadius: '5px' }}
                    research={formatVideoResearchObj(item)}
                    Bookmark
                    SavedVideos={formatSaved()}
                    reportid={item.doc_id}
                    OnSaveClick={saveVideo}
                    PlayButton={true}
                    PlayLink={`/connect/videos/${item.id}`}
                  />
                  {/* REUSE REPORT CARD */}
                </Row>
              ))}
            </div>
            {/* REPORT SECTION */}
          </Col>

          {/* MOST WATCH AND SAVED WATCH==============M003===========[============= */}
          <OnAtLeastTablet>
            {/* INTERACTIVE TRANSCRIPT */}
            {hideTranscript != undefined && hideTranscript == 0 ? (
              <TranscriptContainer
                transcriptLink={accessvideo.data.transcript_path}
                vidId={vidId}
                setNewTime={setNewTime}
                azureMP={azureMP}
              ></TranscriptContainer>
            ) : null}

            {/* INTERACTIVE TRANSCRIPT */}
            {videos.data.length ? (
              <>
                <HighlightedPanel
                  loading={loading}
                  leftResearches={formatMostWatch()}
                  rightResearches={formatSaved()}
                  panelText={['Most Watched', 'Saved']}
                  saveReports={saveVideo}
                  savedVideos={formatSaved()}
                />
              </>
            ) : null}
          </OnAtLeastTablet>
          {/* MOST WATCH AND SAVED WATCH==============M003===========]============= */}
        </Row>
        <br />
      </Grid>
      {/* CONNECT - M002*/}
    </>
  );
};
function mapStateToProps(state) {
  const videos = state.videos.videos;
  const mostwatch = state.videos.mostwatch;
  const analysts = state.analyst.analysts;
  const sectors = state.sector.sectors;
  const accessvideo = state.videos.accessvideo;
  const savedvideos = state.videos.savedvideos;
  const parameters = state.Config.parameters;
  const loading =
    state.videos.accessvideo.loading ||
    state.videos.videos.loading ||
    state.videos.mostwatch.loading;
  return { videos, mostwatch, analysts, sectors, accessvideo, savedvideos, loading, parameters };
}

const mapDispatchToProps = dispatch => ({
  getAllVideos: bindActionCreators(videoActions.getAllVideos, dispatch),
  getMostWatched: bindActionCreators(videoActions.getMostWatched, dispatch),
  getAllAnalysts: bindActionCreators(AnalystActions.getAll, dispatch),
  getAllSectors: bindActionCreators(SectorActions.getAll, dispatch),
  getAccessVideo: bindActionCreators(videoActions.AccessVideo, dispatch),
  getSavedVideos: bindActionCreators(videoActions.getSavedVideos, dispatch),
  SaveVideo: bindActionCreators(videoActions.SaveVideo, dispatch),
  getParameter: bindActionCreators(configActions.getParameter, dispatch),
});
VideosList.propTypes = {
  getAllVideos: PropTypes.func.isRequired,
  videos: PropTypes.object.isRequired,
  getMostWatched: PropTypes.func.isRequired,
  mostwatch: PropTypes.object.isRequired,
  getAllAnalysts: PropTypes.func.isRequired,
  analysts: PropTypes.object.isRequired,
  getAllSectors: PropTypes.func.isRequired,
  sectors: PropTypes.object.isRequired,
  getSavedVideos: PropTypes.func.isRequired,
  savedvideos: PropTypes.object.isRequired,
  getAccessVideo: PropTypes.func.isRequired,
  SaveVideo: PropTypes.func.isRequired,
  accessvideo: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
VideosList.defaultProps = {
  loading: true,
  accessvideo: {
    error: null,
  },
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideosList));
