/*
**************************************************************************************************************
            ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
            Amendments
========================================================================
  M002 : 26 June 2023 : Zeeshan
    * M-4397: Changes are not being saved when editing a staff biography
  M001 : 21 April 2021 : Aman
    * M-0003347: Allow upload of Our People photos to be uploaded via Team Management page
**************************************************************************************************************
*/

/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Image, Glyphicon, Row, Col, Button, Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import Avatar from '../../../assets/images/login-logo-small.png';
import Box from '../../../components/Box';
import EditBiographyForm from '../../../components/EditBiographyForm';
import { Link } from 'react-router-dom';

const Component = ({
  data,
  style,
  loading,
  user,
  FileUploadHandler,
  error,
  onEditBiography,
  parameters,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  React.useEffect(() => {
    console.log('data =====>', data);
  }, [data]);
  if (loading) {
    return (
      <Box style={style}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (data) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{data.name}</title>
          <meta name="description" content={data.description} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <EditBiographyForm
          showModal={showModal}
          setShowModal={setShowModal}
          onSave={onEditBiography}
          description={data.description}
        />
        <Box style={{ ...style, padding: 0 }}>
          <Row>
            <Col md={12} lg={2}>
              <div className="analyst-page-small-section">
                <div style={{ padding: '0px 0px 5px 0px' }}>
                  {/* //====================M001========================== */}
                  {user != null && user.type == 'admin' ? (
                    <>
                      <div className="analyst-card-image-container text-center">
                        <Image
                          alt="analyst image"
                          src={data.image_path || Avatar}
                          className="team-member-image img-circle"
                        />
                      </div>
                      {error && (
                        <div style={{ color: 'red', fontSize: 12 }}>
                          {' '}
                          Image should be in PNG or JPEG format only.{' '}
                        </div>
                      )}
                      <div>
                        <label className="custom-input" style={{ width: '200px !important' }}>
                          <input type="file" onChange={file => FileUploadHandler(file)} />
                        </label>
                        {/* //====================M002========================== */}

                        {/* <label className="edit-biography" style={{ width: '200px !important' }}>
                          <input type="button" onClick={() => setShowModal(true)} />
                        </label> */}
                        {/* //====================M002========================== */}
                        {parameters?.bEnableConnectBookings == '1' ? (
                          <div style={{ textAlign: 'center', margin: '20px', width: '100%' }}>
                            <Link
                              href={`/connect/appointment/${data.id}`}
                              to={`/connect/appointment/${data.id}`}
                            >
                              {parameters?.rw_CNTBkTalkToThe}
                            </Link>
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="analyst-card-image-container text-center">
                        <Image
                          alt="analyst image"
                          src={data.image_path || Avatar}
                          className="team-member-image img-circle"
                        />
                      </div>
                      <div style={{ textAlign: 'center', margin: '20px', width: '100%' }}>
                        <Link
                          href={`/connect/appointment/${data.id}`}
                          to={`/connect/appointment/${data.id}`}
                        >
                          Talk to the Analyst
                        </Link>
                      </div>
                    </>
                  )}
                </div>
                {/* //====================M003========================== */}
                <div className="contact-box">
                  {data.email && (
                    <>
                      <Glyphicon glyph="glyphicon glyphicon-envelope" />
                      <p>
                        <a href={`mailto:${data.email}`}>{data.email}</a>
                      </p>
                    </>
                  )}
                </div>
                <div className="contact-box">
                  {data.phone && (
                    <>
                      <Glyphicon glyph="glyphicon glyphicon-earphone" />
                      <p>{data.phone}</p>
                    </>
                  )}
                </div>
              </div>
            </Col>
            <Col md={12} lg={10}>
              <div className="analyst-page-right-section">
                <p className="sub-heading" style={{ fontSize: 15 }}>
                  {data.name}
                </p>
                {data.designation && (
                  <p className="sector-title" style={{ fontStyle: 'normal', fontSize: 12.5 }}>
                    {data.designation}
                  </p>
                )}
                <div style={{ paddingTop: 15, fontSize: 12 }}>
                  {data.description ? (
                    <div
                      style={{ fontFamily: 'open_sansregular', paddingBottom: '5px' }}
                      dangerouslySetInnerHTML={{
                        __html: data.description.replaceAll('\n', '<br/>'),
                      }}
                    />
                  ) : (
                    <div className="card-description">No description</div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Box>
      </>
    );
  }

  return (
    <Box style={style} className="text-center">
      No such analyst exists
    </Box>
  );
};

Component.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  style: PropTypes.object,
  user: PropTypes.object,
};

Component.defaultProps = {
  data: {},
  loading: false,
  style: {},
};

export default Component;
