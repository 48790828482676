import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'querystring';

import * as userModalActions from '../../actionCreators/UserModal';
import UserModal from '../../components/UserModal';

import ResearchPageDummy from '../../assets/images/ResearchPageDummy.jpg';

import reportPageDummy from '../../assets/images/reportPageDummy.jpg';

const Component = ({ showUserModal, location }) => {
  React.useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, []);
  const redirectUrl = location.state && location.state.redirectUrl;
  let showReportPageDummy = false;
  if (redirectUrl) {
    showReportPageDummy = redirectUrl.includes('/report');
  }
  showUserModal();
  return (
    <div className="wrapper">
      {showReportPageDummy ? (
        <>
          <Image alt="report mockup" src={reportPageDummy} responsive />
          <UserModal />
        </>
      ) : (
        <>
          <Image alt="report mockup" src={ResearchPageDummy} responsive />
          <UserModal />
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  showUserModal: bindActionCreators(userModalActions.show, dispatch),
});

const mapStateToProps = ({
  research: { research },
  user: {
    currentUser: { data: user },
  },
}) => ({ research, user });

Component.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  currentUser: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  showUserModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  research: PropTypes.object.isRequired,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
