/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 5 August 2020 : Nicho
		* M-0002970: Homepage - Vision section redesign
**************************************************************************************************************
*/

import React from 'react';
import {Col} from "react-bootstrap";

const missionTextPart1 =
  '" To be a leading independent research content provider based on the client`s needs with delivery customised to the client`s choosing. " ';

const Container = () => (
    //====================M001==========================
  <Col xs={12} sm={4} md={4}>
      <div className="our-mission-section">
        <div className="mission-block">
            <h1 className="section-title">Our Mission</h1>
          <p>{missionTextPart1}</p>
        </div>
      </div>
  </Col>
    //====================M001==========================
);

export default Container;
