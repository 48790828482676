import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { Button, Form, Glyphicon } from 'react-bootstrap';

import ForgetPasswordModal from '../../../components/ForgotPasswordModal';
import { OnMobile } from '../../../components/ReactBreak';
import FormField from '../../../components/FormFields';
import { loginEmailPolicy, passwordPolicy } from '../../../config/validations';

const validate = values => {
  const errors = {};

  if (values.password && values.confirmPassword) {
    if (
      values.password.length !== values.confirmPassword.length ||
      values.password !== values.confirmPassword
    ) {
      errors.confirmPassword = 'Create password and confirm password do not match ';
    }
  }
  return errors;
};

export const Component = ({
  className,
  message,
  loading,
  showSignUpMessage,
  handleSubmit,
  externalPdfUrl,
  signUpRoute,
  goTo,
  showSignUpMessageForModal,
}) => {
  const [showPassword, setShowPassord] = React.useState(false);

  return (
    <Form className="box-signup box" onSubmit={handleSubmit}>
      <Field
        name="email"
        type="text"
        component={FormField}
        label="Email"
        validate={loginEmailPolicy}
        disabled={loading}
        required
      />
      <Field
        name="password"
        type={showPassword ? 'text' : 'password'}
        component={FormField}
        label="Password"
        validate={passwordPolicy}
        disabled={loading}
        required
      />
      {showPassword ? (
        <Glyphicon
          glyph="glyphicon glyphicon-eye-open"
          className="showHide-icon-login"
          onClick={() => {
            setShowPassord(false);
          }}
        />
      ) : (
        <Glyphicon
          glyph="glyphicon glyphicon-eye-close"
          className="showHide-icon-login"
          onClick={() => {
            setShowPassord(true);
          }}
        />
      )}
      {message && <p className="text-danger" dangerouslySetInnerHTML={{__html: message}}></p>}
      <span className="pull-right" style={{ paddingLeft: '10px' }}>
        {' '}
        <ForgetPasswordModal externalPdfUrl={externalPdfUrl} signUpRoute={signUpRoute} />
      </span>
      
      {/* SUBMIT BUTTON OR LOGIN OPTION */}
      <div>
        <Button type="submit" bsStyle="primary" className="btn-block" disabled={loading}>
          Login
        </Button>
        <div style={{textAlign:'center', marginTop:10}}>
          {showSignUpMessageForModal ? (
            <span className="signup-already-account-section">
              Not registered?{' '}
              <span className="modal-navigator" onClick={() => goTo('signup')}>
                Create an account
              </span>
            </span>
          ) : (
            <span className="signup-already-account-sectiont">
              Not registered?{' '}
              <Link href="/signup" to="/signup">
                Create an account
              </Link>
            </span>
          )}
        </div>
      </div>
      {/* SUBMIT BUTTON OR LOGIN OPTION */}
    </Form>
  );
};

Component.propTypes = {
  className: PropTypes.string,
  showSignUpMessage: PropTypes.bool,
  showSignUpMessageForModal: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  goTo: PropTypes.func,
  message: PropTypes.string,
  loading: PropTypes.bool,
  externalPdfUrl: PropTypes.object,
  signUpRoute: PropTypes.string,
};

Component.defaultProps = {
  className: null,
  showSignUpMessage: false,
  showSignUpMessageForModal: false,
  message: null,
  loading: false,
  externalPdfUrl: null,
  signUpRoute: null,
};

export default reduxForm({
  form: 'Login2', // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(Component);
