import React, { useState, useEffect } from 'react';
import AppointmentHeader from '../../components/PageHeader';
import { Helmet } from 'react-helmet';
import { Row, Col, MenuItem, DropdownButton } from 'react-bootstrap';
import BookingCard from './components/BookingCard';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import * as configActions from '../../actionCreators/Config';
import * as connectActions from '../../actionCreators/Connect';
import NextArrow from '../../assets/images/navigate_next-aletheia-dark-blue.svg';
import PrevArrow from '../../assets/images/navigate_previous-aletheia-dark-blue.svg';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import moment from 'moment';
import Confirm from '../../components/Confirm';
import { Snackbar, Alert } from '@mui/material';
import { useLocation } from 'react-router-dom';

// 0x01 = "AQ==", 0x00 = "AA=="

const AletheiaConnectAppointment = ({
  getOpenSlotDetails,
  openSlotDetails,
  bookConnectOpenSlot,
  bookOpenSlot,
  currentUser,
  cancelConnectEvent,
  cancelConnectEventBooking,
  getParameter,
  parameters,
}) => {
  const location = useLocation();

  let locationParameters = location.pathname.split('/');
  var id = locationParameters[3];

  const [selectedAnalyst, setSelectedAnalyst] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [analystDropDown, setAnalystDropDown] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarType, setSnackbarType] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('This is a success message');
  const [tobeBooked, setTobeBooked] = useState(null);
  const [tobeCancelled, setTobeCancelled] = useState(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [showPrev, setShowPrev] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageDataToShow, setCurrentPageDataToShow] = useState([]);

  useEffect(() => {
    getOpenSlotDetails(currentUser.data.id);
    getParameter('sRshWebConnAppHeadline,sRshWebConnAppSubHeading,sRshWebConnAppNoAppointments');
  }, []);

  useEffect(() => {
    if (openSlotDetails.length) {
      // const filteredData = openSlotDetails.filter(item => item.iCAO_Type_PikId == 2118700007);

      // Get unique objects based on the condition that id and age should be unique
      const uniqueObjects = getUniqueObjects(openSlotDetails, obj => `${obj.iCAO_ObjId}`);
      setAnalystDropDown(uniqueObjects);
      if (uniqueObjects.length <= 0) {
        setIsEmpty(true);
      }
      if (selectedAnalyst != null) {
        selectAnalystHandler(selectedAnalyst);
        return;
      }
      if (id != undefined) {
        const found = uniqueObjects.some(el => el.iCAO_ObjId == id);
        if (found) {
          const index = uniqueObjects.findIndex(obj => obj.iCAO_ObjId == id);
          setSelectedAnalyst(uniqueObjects[index]);
          const filteredData = openSlotDetails.filter(
            item => item.iCAO_ObjId == uniqueObjects[index].iCAO_ObjId
          );
          setEventsData(filteredData);
        }
      }
    }
  }, [openSlotDetails]);

  useEffect(() => {
    if (bookOpenSlot != null) {
      console.log('book open slot =====>', bookOpenSlot);
      if (bookOpenSlot.Status == 'failure') {
        setSnackbarType('error');
        setSnackbarMessage(bookOpenSlot?.ReasonText);
        setOpenSnackbar(true);
      } else {
        getOpenSlotDetails(currentUser.data.id);
      }
    }
  }, [bookOpenSlot]);

  useEffect(() => {
    if (cancelConnectEvent != null) {
      if (cancelConnectEvent.Status == 'failure') {
        setSnackbarType('error');
        setSnackbarMessage(cancelConnectEvent?.ReasonText);
        setOpenSnackbar(true);
      } else {
        getOpenSlotDetails(currentUser.data.id);
      }
    }
  }, [cancelConnectEvent]);

  useEffect(() => {
    if (eventsData.length) {
      displayPage(1);
    }
  }, [eventsData]);

  function getUniqueObjects(arr, conditionFn) {
    const seen = new Set();
    return arr.filter(obj => {
      const conditionValue = conditionFn(obj);
      if (seen.has(conditionValue)) {
        return false;
      }
      seen.add(conditionValue);
      return true;
    });
  }

  const selectAnalystHandler = data => {
    setSelectedAnalyst(data);
    const filteredData = openSlotDetails.filter(item => item.iCAO_ObjId == data.iCAO_ObjId);
    const hiddenFilter = filteredData.filter(item => item.hide == 'AA==');
    setEventsData(hiddenFilter);
  };

  const BookButtonClickHandler = data => {
    if (data.notSubscribed == 'AQ==') {
      setSnackbarType('error');
      setSnackbarMessage(
        '<p>You are currently not subscribed to this analyst\'s product. If you would like to SUBSCRIBE, please email <a href="mailto:info@aletheia-capital.com">info@aletheia-capital.com</a> or contact your relevant Sales Person.</p><p>If you believe that this message is in error, please contact <a href="mailto:info@aletheia-capital.com">info@aletheia-capital.com</a></p>'
      );
      setOpenSnackbar(true);
    } else {
      setTobeBooked(data);
      setShowConfirm(true);
    }

    // setTobeBooked(data);
    // setShowConfirm(true);
  };

  const BookEventHandler = () => {
    bookConnectOpenSlot(0, currentUser.data.id, tobeBooked.iCAE_Id, tobeBooked.iCAO_ObjId);
    setShowConfirm(false);
  };

  const CancelButtonClickHandler = data => {
    setTobeCancelled(data);
    setShowCancelConfirm(true);
  };

  const CancelEventHandler = () => {
    cancelConnectEventBooking(
      0,
      currentUser.data.id,
      tobeCancelled.iCAE_Id,
      tobeCancelled.iCAO_ObjId
    );
    setShowCancelConfirm(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  // Handling Pagination

  // Number of items per page
  const itemsPerPage = 8;

  // Function to display a specific page of data
  function displayPage(pageNumber) {
    setCurrentPage(pageNumber);
    if (pageNumber == 1) {
      setShowPrev(false);
    } else {
      setShowPrev(true);
    }

    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageData = eventsData.slice(startIndex, endIndex);
    const totalPages = Math.ceil(eventsData.length / itemsPerPage);
    if (pageNumber < totalPages) {
      setShowNext(true);
    } else {
      setShowNext(false);
    }
    setNumberOfPages(totalPages);
    setCurrentPageDataToShow(currentPageData);
  }

  const prevButtonClickHandler = () => {
    displayPage(currentPage - 1);
  };

  const nextButtonClickHandler = () => {
    displayPage(currentPage + 1);
  };

  const dateChangeHandler = newDate => {
    // console.log(newDate);
    const convertedDate = moment(newDate.$d).format('YYYY-MM-DD');

    const index = eventsData.findIndex(
      item => moment(item.dCAE_LocaleStartDateTime).format('YYYY-MM-DD') == convertedDate
    );

    if (index > -1) {
      const pageIndex = Math.ceil(index / itemsPerPage);
      displayPage(pageIndex);
    } else {
      setSnackbarMessage('<p>There are currently no events on this date</p>');
      setSnackbarType('error');
      setOpenSnackbar(true);
    }
    setSelectedDate(newDate);
  };
  return (
    <>
      <Helmet>
        <title>Aletheia CONNECT</title>
      </Helmet>
      <AppointmentHeader
        pageName={parameters != null ? parameters.sRshWebConnAppHeadline : ''}
        pageDesc={parameters != null ? parameters.sRshWebConnAppSubHeading : ''}
      />
      <br />

      <Confirm
        show={showConfirm}
        // loading={user.loading}
        message="Are you sure you want to book this event?"
        subHeading={null}
        onConfirm={BookEventHandler}
        onCancel={() => setShowConfirm(false)}
      />

      <Confirm
        show={showCancelConfirm}
        // loading={user.loading}
        message="Are you sure you want to cancel this event?"
        subHeading={null}
        onConfirm={CancelEventHandler}
        onCancel={() => setShowCancelConfirm(false)}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        style={{ zIndex: 1000000 }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity={snackbarType} style={{ fontSize: '16px' }}>
          <div dangerouslySetInnerHTML={{ __html: snackbarMessage }}></div>
          {/* {snackbarMessage} */}
        </Alert>
      </Snackbar>

      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Col lg={2} style={{ display: 'flex', justifyContent: 'center' }}>
          {showPrev ? (
            <img
              src={PrevArrow}
              height={140}
              width={80}
              style={{ cursor: 'pointer' }}
              onClick={prevButtonClickHandler}
            />
          ) : null}
        </Col>
        <Col lg={8} md={10} sm={12}>
          <Row style={{ display: 'flex', alignItems: 'center' }}>
            <Col xs={12} sm={10} lg={10}>
              <h3>
                {selectedAnalyst != null
                  ? selectedAnalyst.sPFN_FullName
                  : 'Please select an analyst'}
              </h3>
              {/* <h4>Sector Name</h4> */}
            </Col>
            <Col xs={12} sm={10} md={4} lg={2}>
              <DropdownButton
                // bsStyle={'Analysts'.toLowerCase()}
                title="Analysts"
                id={`dropdown-basic-1`}
              >
                {analystDropDown.length > 0
                  ? analystDropDown
                      .sort((a, b) => a.sPFN_FullName.localeCompare(b.sPFN_FullName))
                      .map((item, index) => (
                        <MenuItem key={item.iCAO_ObjId} onClick={() => selectAnalystHandler(item)}>
                          {item.sPFN_FullName}
                        </MenuItem>
                      ))
                  : null}
              </DropdownButton>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={10} md={5} lg={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    value={selectedDate}
                    onChange={newDate => dateChangeHandler(newDate)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Col>
          </Row>
          <br />
          {currentPageDataToShow.length
            ? currentPageDataToShow.map((item, index) => (
                <Col key={item.iCAE_Id} lg={3} md={4} sm={6}>
                  <BookingCard
                    Date={moment(item.dCAE_LocaleStartDateTime).format('ddd Do MMM YYYY')}
                    Time={moment(item.dCAE_LocaleStartDateTime).format('hh:mm A')}
                    Timezone={item.TimezoneValue}
                    isBooked={item.booked}
                    ClickHandler={() => BookButtonClickHandler(item)}
                    CancelClickHandler={() => CancelButtonClickHandler(item)}
                  />
                </Col>
              ))
            : null}
          {isEmpty ? parameters.sRshWebConnAppNoAppointments : null}
          {/* <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col> */}
          {/* <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col>
          <Col lg={3}>
            <BookingCard Date="21/11/2023" Time="12:00 pm" />
          </Col> */}
        </Col>

        <Col lg={2} style={{ display: 'flex', justifyContent: 'center' }}>
          {showNext ? (
            <img
              src={NextArrow}
              height={140}
              width={80}
              style={{ cursor: 'pointer' }}
              onClick={nextButtonClickHandler}
            />
          ) : null}
        </Col>
      </Row>
      <br />
    </>
  );
};

const mapStateToProps = ({
  connect: { openSlotDetails, bookOpenSlot, cancelConnectEvent },
  user: { currentUser },
  Config: { parameters },
}) => ({
  openSlotDetails,
  bookOpenSlot,
  currentUser,
  cancelConnectEvent,
  parameters,
});

const mapDispatchToProps = dispatch => ({
  getOpenSlotDetails: bindActionCreators(connectActions.getOpenSlotDetails, dispatch),
  bookConnectOpenSlot: bindActionCreators(connectActions.bookConnectOpenSlot, dispatch),
  cancelConnectEventBooking: bindActionCreators(connectActions.cancelConnectEventBooking, dispatch),
  getParameter: bindActionCreators(configActions.getParameter, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AletheiaConnectAppointment);
