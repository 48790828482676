/*
**************************************************************************************************************
            ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
            Amendments
========================================================================
  M001 : 26 June 2023 : Zeeshan
    * M-4397: Changes are not being saved when editing a staff biography
**************************************************************************************************************
*/

/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Image, Row, Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import Avatar from '../../../assets/images/placeholder-company.png';
import Box from '../../../components/Box';
import StockViewBox from './StockView';
import EditBiographyForm from '../../../components/EditBiographyForm';

const Component = ({ data, style, loading, user, onEditBiography }) => {
  const [showModal, setShowModal] = React.useState(false);

  if (loading) {
    return (
      <Box style={style}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (data) {
    return (
      <>
        <EditBiographyForm
          showModal={showModal}
          setShowModal={setShowModal}
          onSave={onEditBiography}
          description={data.description}
        />
        <Box style={{ ...style, padding: 0 }}>
          <Row>
            <Col md={12} lg={2}>
              <div rowSpan="4" style={{ verticalAlign: 'top' }} className="analyst-avatar-section">
                <div style={{ padding: '0px 0px 5px 0px' }}>
                  <div className="analyst-card-image-container">
                    <Image alt="analyst image" src={data.image || Avatar} thumbnail />
                  </div>
                </div>
              </div>
              {user != null && user.type == 'admin' ? (
                <div>
                  {/* //====================M001========================== */}

                  {/* <label className="edit-biography" style={{ width: '200px !important' }}>
                    <input type="button" onClick={() => setShowModal(true)} />
                  </label> */}
                  {/* //====================M001========================== */}

                </div>
              ) : null}
            </Col>
            <Col md={12} lg={10}>
              <div className="company-page-description-section">
                <p className="sub-heading" style={{ fontSize: 15 }}>
                  {data.name}
                </p>
                <div style={{ paddingTop: 15, fontSize: 12 }}>
                  {data.description ? (
                    <div
                      style={{ fontFamily: 'open_sansregular', paddingBottom: '5px' }}
                      dangerouslySetInnerHTML={{ __html: data.description }}
                    />
                  ) : (
                    <div className="card-description">No description</div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Box>
      </>
    );
  }

  return (
    <Box style={style} className="text-center">
      No such analyst exists
    </Box>
  );
};

Component.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  style: PropTypes.object,
};

Component.defaultProps = {
  data: {},
  loading: false,
  style: {},
};

export default Component;
