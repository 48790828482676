export const getAll = obj => ({
  type: 'GET_SECTORS',
  obj,
});

export const getAllSuccess = (data, obj) => ({
  type: 'GET_SECTORS_SUCCESS',
  data,
  obj,
});

export const getAllFail = (error, obj) => ({
  type: 'GET_SECTORS_FAILURE',
  error,
  obj,
});

export const getSector = obj => ({
  type: 'GET_SECTOR',
  obj,
});

export const getSectorSuccess = (data, obj) => ({
  type: 'GET_SECTOR_SUCCESS',
  data,
  obj,
});

export const getSectorFail = (error, obj) => ({
  type: 'GET_SECTOR_FAILURE',
  error,
  obj,
});
