/*
**************************************************************************************************************
                        R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D TECH LIMITED
and is protected by copyright and other intellectual property laws and treaties.

R&D TECH LIMITED owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D TECH LIMITED.

If the person in possession of the Software has not entered into a license agreement with
R&D TECH LIMITED, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
  Author	:	Nick Ng
  Date	:	23 January 2023
**************************************************************************************************************
												Usage Notes
**************************************************************************************************************

**************************************************************************************************************
												Amendments
**************************************************************************************************************
  M002: 20 January 2023 : Zeeshan
    * M-0004062: Smoothen homepage banner carousel spinning
  M001 : 14 August 2020 : Nick
    * M-0002944: Homepage - Banner section redesign
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Swiper from 'react-id-swiper';
import { Row, Col, Image, Glyphicon } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as configActions from '../../../actionCreators/Config';
const Component = ({ homePageSliders, getImage }) => {
  const [sliderList, setSliderList] = React.useState([]);
  React.useEffect(() => {
    getImage({ key: 'HomePageSlider' });
  }, [getImage]);
  //Clear unused sliders
  React.useEffect(() => {
    if (homePageSliders) {
      let tempSliderList = [];
      tempSliderList = homePageSliders.filter(i => i.active);
      setSliderList(tempSliderList);
    }
  }, [homePageSliders]);
  //Carousell Options
  const swiperOptions = {
    speed: 450,
    slidesPerView: 3,
    centeredSlides: true,
    rebuildOnUpdate: true,
    shouldSwiperUpdate: true,
    loop: true,
    autoplay: {
      delay: 8000,
    },
    breakpoints: {
      1170: {
        slidesPerView: 1,
        spaceBetween: 25,
      },
    },
    navigation: {
      nextEl: '.swiper-button-next.swiper-button-white',
      prevEl: '.swiper-button-prev.swiper-button-white',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    spaceBetween: 30,
  };

  const genHrefImage = (item) => {
    let link1;
    let link2;
    if (item.link) {
      if (item.link.indexOf(";") > 0) {
        let aLink = item.link.split(";");
        link1 = aLink[0];
        link2 = aLink[1];
        return <a href={link1} onClick={() => { window.open(link2) }}>
          <Image src={item.tempImage} className="SwiperBannerImages" />
        </a>;
      } else {
        link1 = item.link;
        return <a href={link1}>
          <Image src={item.tempImage} className="SwiperBannerImages" />
        </a>;
      }
    } else {
      return <Image src={item.tempImage} className="SwiperBannerImages" />;
    }
  }

  return (
    <>
      <section className="banner-holder">
        {homePageSliders ? (
          <Swiper {...swiperOptions}>
            {sliderList.map((item, index) => (
              <div>
                {genHrefImage(item)}
              </div>
            ))}
          </Swiper>
        ) : (
          <></>
        )}
      </section>
    </>
  );
};

const mapStateToProps = ({ Config: { homePageSliders } }) => ({
  homePageSliders,
});

const mapDispatchToProps = dispatch => ({
  getImage: bindActionCreators(configActions.getHeaderImage, dispatch),
});

Component.propTypes = {
  homePageSliders: PropTypes.array,
  getImage: PropTypes.func.isRequired,
};
Component.defaultProps = {
  homePageSliders: {},
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
