import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tab, ButtonToolbar, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';
import BlogEditor from '../../../pages/BlogEditor';
import '../../../assets/sass/admin-panel.scss';
import * as configActions from '../../../actionCreators/Config';
import * as reportActions from '../../../actionCreators/Research';

import BlogSetting from './blogSettings';



const Component = ({ favourites, keyword, uniqueKey, topicName, newBlog }) => {
  const [keyWord, setKeyword] = React.useState(keyword && keyword);
  const [error, setError] = React.useState(null);
  const [tags, setTags] = React.useState(keyword && keyword);
  const [topic, setTopic] = React.useState(topicName && topicName);
  const [changes, setChanges] = React.useState(false);
  const [tab, setTab] = React.useState('b');

  React.useEffect(() => {
    if (keyword) {
      setKeyword(keyword);
      setTags(keyWord);
    }
    if(newBlog) {
      setTab('a');
    }
  }, [favourites]);

  return (
    <>
      <Tab.Container defaultActiveKey={tab}>
        <Tab.Content animation>
          <Tab.Pane eventKey="b">
              <div className="button-group-box favourites">
                <ButtonToolbar>
                  <ToggleButtonGroup type="radio" name="options" value={tab}>
                    <ToggleButton value="b" onClick={() => setTab('b')}>
                      Posts
                    </ToggleButton>
                    <ToggleButton value="a" onClick={() => setTab('a')}>
                      Settings
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
              {tab === 'a' && (
                <>
                  <BlogSetting uniqueKey={uniqueKey}></BlogSetting>
                </>
              )}
              {tab === 'b' && (
                <BlogEditor
                  uniqueKey={uniqueKey}
                  topic={topic}
                  showError={err => {
                    if (err) {
                      setError(true);
                    }
                  }}
                /> 
              )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

    </>
  );
};

const mapStateToProps = ({
  Config: {
    favourites: {
      loading,
      data: { favourites },
    },
  },
}) => ({ loading, favourites });

const mapDispatchToProps = dispatch => ({
  saveFavourites: bindActionCreators(configActions.saveFavourites, dispatch),
  saveBlogResearch: bindActionCreators(configActions.saveBlogResearch, dispatch),
  GetBlogReports: bindActionCreators(reportActions.GetBlogReports, dispatch),
});

Component.propTypes = {
  history: PropTypes.object.isRequired,
  favourites: PropTypes.object.isRequired,
  keyword: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  uniqueKey: PropTypes.string.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
