import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Button, Glyphicon, } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ConfirmModal = ({show, onCancel, history}) => {
    return (
        <Modal show={show} className="container-fake" >
            <Modal.Body style={{minHeight:280}}>
                <div style={{ paddingLeft: '3%', fontSize: 12, marginTop: '5%', paddingRight: '3%'}}>
                    {/* HEADER */}
                    <Row>
                        <Col xs={10}>
                            <h2 style={{fontSize: 30}}>Are you an Investor or Analyst?</h2>
                        </Col>
                        <Col xs={2}>
                            <button type="button" class="close" onClick={()=>{onCancel(false)}}>
                                <span aria-hidden="true">×</span>
                                <span class="sr-only">Close</span>
                            </button>
                        </Col>
                    </Row>
                    {/* HEADER */}
                    {/* SUB-TITLE */}
                    <div>
                        <h4>Tell us about yourself so we can provide content relevant to you.</h4>
                    </div>
                    {/* SUB-TITLE */}
                    {/* OPTIONS */}
                    <div style={{margin:20, marginTop: 40}}>
                        <div style={{borderBottom:"1px solid #d2d2d2", cursor:'pointer'}}>
                            <h2 onClick={()=>{onCancel(false);history.push('/AOI/Investor')}}>
                                <Link>Investor</Link>
                                <span className="pull-right" style={{marginRight: 5, margin:'-0.33em 0' }}>
                                    <Button
                                    style={{fontSize: 20, borderRadius: 36, padding: '4px 9px'}}
                                    bsSize="xs"
                                    bsStyle="primary"
                                    className="pull-right"
                                    // onClick={()=>{setShowStat(true)}}
                                    >
                                        <Glyphicon style={{top:4}} glyph="arrow-right" />
                                    </Button>
                                </span>
                            </h2>
                        </div>
                        <div style={{marginTop:20, cursor:'pointer'}}>
                            <h2 onClick={()=>{onCancel(false);history.push('/AOI/Analyst')}}>
                                <Link>Analyst</Link>
                                <span className="pull-right" style={{marginRight: 5, margin:'-0.33em 0' }}>
                                    <Button
                                    style={{fontSize: 20, borderRadius: 36, padding: '4px 9px'}}
                                    bsSize="xs"
                                    bsStyle="primary"
                                    className="pull-right"
                                    // onClick={()=>{setShowStat(true)}}
                                    >
                                        <Glyphicon style={{top:4}} glyph="arrow-right" />
                                    </Button>
                                </span>
                            </h2>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

ConfirmModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
  };

export default ConfirmModal;
