import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link,useHistory, useLocation, withRouter } from 'react-router-dom';
import Table from '../../../components/Table';
import Confirm from '../../../components/Confirm';
import { websiteUserLabels, spyderUserLabels } from './usersTableConfig';
import Pagination from '../../../components/Pagination';
import ImportSpyderForm from './spyderForm';

import * as adminActions from '../../../actionCreators/Admin';


const UsersTable = ({userType, userData, loading, sendNewPassword, sendVerifyEmail, changeUserStatus, searchSpyderFirm, firms, insertUserToSpyder, spyderInsertStatus}) => {
    const [paginatedData, setPaginatedData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [showConfirmPopUp, setShowConfirmPopUp] = React.useState(false)
    const [confirmPopUpMsg, setConfirmPopUpMsg] = React.useState("")
    const [confirmAction, setConfrimAction] = React.useState()
    const [actionUserObj, setActionUserObj] = React.useState({})
    const [showSpyderImportForm, setShowSpyderImportForm] = React.useState(false)

    const searchWebsiteData = (key) => {
        var filteredData = userData.filter(
            val =>
                (val.sFull_Name && val.sFull_Name.toLowerCase().includes(key)) ||
                (val.sEmail && val.sEmail.toLowerCase().includes(key))
        );
        var paginatedArr = [];
        for (var i = 0, len = filteredData.length; i < len; i += 15) {
            paginatedArr.push(filteredData.slice(i, i + 15));
        }
        setPaginatedData(paginatedArr);
        setPage(0);
    }

    const searchSpyderData = (key) => {
        var filteredData = userData.filter(
            val =>
                (val.sPFN_FullName && val.sPFN_FullName.toLowerCase().includes(key)) ||
                (val.sNCY_FullNo && val.sNCY_FullNo.toLowerCase().includes(key)) ||
                (val.sCompanyName && val.sCompanyName.toLowerCase().includes(key))
        );
        var paginatedArr = [];
        for (var i = 0, len = filteredData.length; i < len; i += 15) {
            paginatedArr.push(filteredData.slice(i, i + 15));
        }
        setPaginatedData(paginatedArr);
        setPage(0);
    }

    const sortData = (key, order, singleData) => {
        var mergeArr = [];
        //Merge Paginated Data Back
        for(var i=0; i < paginatedData.length; i++) {
            for (var j=0; j < paginatedData[i].length; j++) {
                mergeArr.push(paginatedData[i][j])
            }
        }
        mergeArr.sort((a,b)=>{
            if(order=='desc'){
                return(a[key]>b[key]?-1:1);
            } else {
                return(a[key]<b[key]?-1:1);
            }
        })
        var paginatedArr = [];
        for (var i = 0, len = mergeArr.length; i < len; i += 15) {
            paginatedArr.push(mergeArr.slice(i, i + 15));
        }
        setPaginatedData(paginatedArr);
        setPage(0);
    }

    React.useEffect(()=>{
        var paginatedArr = [];
        for (var i = 0, len = userData.length; i < len; i += 15) {
            paginatedArr.push(userData.slice(i, i + 15));
        }
        setPaginatedData(paginatedArr);
    },[userData])

    const showConfirmWindow = (msg, obj, actionType) => {
        setConfrimAction(actionType)
        setActionUserObj(obj);
        setConfirmPopUpMsg(msg)
        setShowConfirmPopUp(true)
    }

    const doConfrimAction = () => {
        var email = userType=="website"? actionUserObj.sEmail : actionUserObj.sNCY_FullNo;
        var id = userType=="website"? actionUserObj.iTempUser_ID : actionUserObj.iPSN_Id;
        var name = userType=="website"? actionUserObj.sFull_Name : actionUserObj.sPFN_FullName;
        var updateStatus = 0;
        if (userType=="website") {
            if (actionUserObj.bActivated==0) {
                updateStatus = 1
            }
        }

        switch (confirmAction) {
            case "sendNewPassword": {
                sendNewPassword(userType, id, email)
            }
            case "sendVerifyEmail": {
                sendVerifyEmail(userType, id, email, name)
            }
            case "changeUserStatus": {
                changeUserStatus(userType, id, email, updateStatus)
                if (userType=="website") {
                    paginatedData[page].forEach(e=>{
                        if(e.iTempUser_ID == id) {
                            e.bActivated = updateStatus;
                        }
                    })
                }
            }
        }
        setShowConfirmPopUp(false);
    }

    const showSpyderImportWindow = (obj) => {
        setActionUserObj(obj);
        setShowSpyderImportForm(true);
    }

    return (
        <>
            <Confirm
                onConfirm={()=>{
                    doConfrimAction()
                }}
                subHeading={confirmPopUpMsg}
                show={showConfirmPopUp}
                onCancel={() => setShowConfirmPopUp(false)}
            />
            <ImportSpyderForm
                show={showSpyderImportForm}
                userObj={actionUserObj}
                onCancel={()=> setShowSpyderImportForm(false)}
                onSearch={searchSpyderFirm}
                firmsData={firms}
                onConfirm={insertUserToSpyder}
                userType={userType}
                spyderInsertStatus={spyderInsertStatus}
            />
            <Table 
                labels={userType=='website' ? websiteUserLabels(showConfirmWindow,showSpyderImportWindow):spyderUserLabels(showConfirmWindow)} 
                title={userType=='website' ? "Website Users":"Spyder Users"}
                data={paginatedData[page]} 
                loading={loading}
                searchAble
                searchUser={userType=='website'?searchWebsiteData:searchSpyderData}
                onOrderChange={sortData}
            />
            <Pagination
                className="centrePagination"
                current={page + 1}
                total={paginatedData.length}
                onClick={p => setPage(p-1)}
            />
        </>
    )
};

const mapStateToProps = state => {
    const {
        admin: { firms, spyderInsertStatus }
    } = state;
    return { firms, spyderInsertStatus };
};

const mapDispatchToProps = dispatch => ({
    sendNewPassword: bindActionCreators(adminActions.sendNewPassword, dispatch),
    sendVerifyEmail: bindActionCreators(adminActions.sendVerifyEmail, dispatch),
    changeUserStatus: bindActionCreators(adminActions.changeUserStatus, dispatch),
    searchSpyderFirm: bindActionCreators(adminActions.searchSpyderFirms, dispatch),
    insertUserToSpyder: bindActionCreators(adminActions.insertUserToSpyder, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersTable));