import React from 'react';
import { FormGroup, FormControl, Button, Modal, Col, Form, ControlLabel, Row, NavDropdown, MenuItem, HelpBlock } from 'react-bootstrap';

const Component = ({
  show,
  onConfirm,
  onCancel,
  userObj,
  onSearch,
  firmsData,
  userType,
  spyderInsertStatus
}) => {
    const [spyderImportEmail, setSpyderImportEmail] = React.useState("")
    const [spyderImportFamilyName, setSpyderImportFamilyName] = React.useState("")
    const [spyderImportFirstName, setSpyderImportFirstName] = React.useState("")
    const [spyderImportFirmId, setSpyderImportFirmId] = React.useState(null)
    const [showSpyderFirmSuggestion, setShowSpyderFirmSuggestion] = React.useState(false)
    const [spyderFirmSuggestion, setSpyderFirmSuggestion] = React.useState()
    const [spyderDisplayName, setSpyderDisplayName] = React.useState("")
    const [spyderImportErr, setSpyderImportErr] = React.useState({
        'familyName':'',
        'firstName':'',
        'firm':'',
    })
    const [spyderResponse, setSpyderResponse] = React.useState({
        message: null
    });

    //Set Default When Changing UserObj
    React.useEffect(()=>{
        if(userObj.sFull_Name) {
            setSpyderImportFirstName(userObj.sFull_Name.substr(0,userObj.sFull_Name.indexOf(' ')))
            setSpyderImportFamilyName(userObj.sFull_Name.substr(userObj.sFull_Name.indexOf(' ')+1));
        } else {
            setSpyderImportFirstName(userObj.sFull_Name)
        }
        setSpyderImportEmail(userObj.sEmail);
        setSpyderDisplayName("");
        setSpyderFirmSuggestion()
        setSpyderImportFirmId(null)
        setSpyderImportErr({
            'familyName': '',
            'firstName': '',
            'firm': '',
        })
        setSpyderResponse({
            message: null
        })
    },[userObj])

    const importToSpyder = () => {
        //Validation First
        var firstNameErr, familyNameErr, firmErr;
        if (!spyderImportFirstName) {
            firstNameErr = "Please input the first name of the contact."
        }
        if (!spyderImportFamilyName) {
            familyNameErr = "Please input the family name of the contact."
        }
        if (!spyderImportFirmId) {
            firmErr = "Please select the firm to which this contact belongs."
        }
        if (firstNameErr || familyNameErr || firmErr) {
            setSpyderImportErr({
                'familyName': familyNameErr,
                'firstName': firstNameErr,
                'firm': firmErr,
            })
        } else {
            setSpyderImportErr({
                'familyName': '',
                'firstName': '',
                'firm': '',
            })
            //DO SUMBIT
            var insertObj = {
                firstName: spyderImportFirstName,
                familyName: spyderImportFamilyName,
                email: spyderImportEmail,
                firmId: spyderImportFirmId
            }
            onConfirm(userType, userObj.iTempUser_ID, insertObj)
        }
    }

    const searchSpyderFirms = (e) => {
        if (e.target.value.length>=3) {
            onSearch(e.target.value);
        } else {
            setSpyderFirmSuggestion(<></>)
        }
    }

    React.useEffect(()=>{
        if (firmsData.data.length) {
            setShowSpyderFirmSuggestion(true)
            var menuItemArr = [];
            for (var i=0; i< firmsData.data.length; i++) {
                menuItemArr.push(
                <MenuItem data-id={firmsData.data[i].id} data-name={firmsData.data[i].sName} onClick={(e)=>{
                    setSpyderDisplayName(e.target.dataset.name)
                    if (showSpyderFirmSuggestion) {
                        setShowSpyderFirmSuggestion(false)
                    }
                    setSpyderImportFirmId(e.target.dataset.id);
                }}>
                        {firmsData.data[i].sName}
                </MenuItem>)
            }
            setSpyderFirmSuggestion(
                <NavDropdown className="auto-suggestion-dropdown open" id="navDropDown">
                    {menuItemArr}
                </NavDropdown>)
        } else {
            setShowSpyderFirmSuggestion(false)
            setSpyderFirmSuggestion(<></>)
        }
    }, [firmsData])

    React.useEffect(()=> {
        if(spyderImportFirmId!=null) {
            setSpyderFirmSuggestion(<></>)
        }
    }, [spyderImportFirmId])

    React.useEffect(()=>{
        setSpyderResponse(spyderInsertStatus)
    }, [spyderInsertStatus])

    return (
        <Modal bsSize="lg" show={show} onHide={() => onCancel()}>
            <Modal.Header closeButton>
            <Modal.Title>Import User To Spyder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="filter-box-table">
                <Row style={{margin:10}}>
                    <Col xs={2}>
                        <ControlLabel>Family Name: </ControlLabel>
                    </Col>
                    <Col xs={10}>
                        <FormGroup validationState={spyderImportErr.familyName?"error":null}>
                            <FormControl
                                defaultValue={spyderImportFamilyName}
                                type="text"
                                placeholder={"Family Name"}
                                required={true}
                                onKeyUp={(e)=>setSpyderImportFamilyName(e.target.value)}
                                isInvalid={spyderImportErr.familyName}
                            />
                            <HelpBlock>
                                {spyderImportErr.familyName}
                            </HelpBlock>
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{margin:10}}>
                    <Col xs={2}>
                        <ControlLabel>First Name: </ControlLabel>
                    </Col>
                    <Col xs={10}>
                    <FormGroup validationState={spyderImportErr.firstName?"error":null}>
                            <FormControl
                                defaultValue={spyderImportFirstName}
                                type="text"
                                placeholder={"First Name"}
                                required={true}
                                autoComplete="off"
                                autoFocus
                                onKeyUp={(e)=>setSpyderImportFirstName(e.target.value)}
                            />
                            <HelpBlock>
                                {spyderImportErr.firstName}
                            </HelpBlock>
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{margin:10}}>
                    <Col xs={2}>
                        <ControlLabel>Email: </ControlLabel>
                    </Col>
                    <Col xs={10}>
                        <FormGroup>
                            <FormControl
                                value={spyderImportEmail}
                                type="text"
                                placeholder={"Email"}
                                required={true}
                                autoComplete="off"
                                autoFocus
                                disabled={true}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{margin:10}}>
                    <Col xs={2}>
                        <ControlLabel>Company: </ControlLabel>
                    </Col>
                    <Col xs={10}>
                        <FormGroup validationState={spyderImportErr.firm?"error":null}>
                            <FormControl
                                value={spyderDisplayName}
                                type="text"
                                placeholder={"Company Name"}
                                required={true}
                                autoComplete="off"
                                autoFocus
                                onChange={(e)=>{
                                    setSpyderImportFirmId(null);
                                    searchSpyderFirms(e);
                                    setSpyderDisplayName(e.target.value);
                                }}
                            />
                            <HelpBlock>
                                {spyderImportErr.firm}
                            </HelpBlock>
                            {spyderFirmSuggestion}
                        </FormGroup>
                    </Col>
                </Row>
                {spyderResponse.message?<p className={spyderResponse.success?"text-success":"text-danger"}>{spyderResponse.message}</p>:<></>}
            </div>
            </Modal.Body>
            <Modal.Footer>
            <Button type="submit" bsStyle="primary" onClick={()=> importToSpyder()} block>
                Import
            </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Component;


