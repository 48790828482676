export const getEvents = obj => ({
  type: 'GET_EVENTS',
  obj,
});

export const getEventsSuccess = (data, obj) => ({
  type: 'GET_EVENTS_SUCCESS',
  data,
  obj,
});

export const getEventsFail = error => ({
  type: 'GET_EVENTS_FAIL',
  error,
});

export const registerEvents = obj => ({
  type: 'REGISTER_EVENTS',
  data: obj,
});

export const registerEventsSuccess = data => ({
  type: 'REGISTER_EVENTS_SUCCESS',
  data,
});

export const registerEventsFail = error => ({
  type: 'REGISTER_EVENTS_FAIL',
  error,
});

export const getEvent = id => ({
  type: 'GET_EVENT',
  id,
});

export const getEventSuccess = (data, obj) => ({
  type: 'GET_EVENT_SUCCESS',
  data,
  obj,
});

export const getEventFail = error => ({
  type: 'GET_EVENT_FAIL',
  error,
});

export const getOpenSlotDetails = iPSN_Id => ({
  type: 'GET_OPEN_SLOT_DETAILS',
  iPSN_Id,
});

export const getOpenSlotDetailsSuccess = (data, obj) => ({
  type: 'GET_OPEN_SLOT_DETAILS_SUCCESS',
  data,
  obj,
});

export const getOpenSlotDetailsFail = error => ({
  type: 'GET_OPEN_SLOT_DETAILS_FAIL',
  error,
});

export const bookConnectOpenSlot = (iLng, iPSN_Id, iCAE_Id, iAnalystId) => ({
  type: 'BOOK_CONNECT_OPEN_SLOT',
  iLng,
  iPSN_Id,
  iCAE_Id,
  iAnalystId,
});

export const bookConnectOpenSlotSuccess = (data, obj) => ({
  type: 'BOOK_CONNECT_OPEN_SLOT_SUCCESS',
  data,
  obj,
});

export const bookConnectOpenSlotFail = error => ({
  type: 'BOOK_CONNECT_OPEN_SLOT_FAIL',
  error,
});

export const cancelConnectEventBooking = (iLng, iPSN_Id, iCAE_Id, iAnalystId) => ({
  type: 'CANCEL_CONNECT_EVENT_BOOKING',
  iLng,
  iPSN_Id,
  iCAE_Id,
  iAnalystId,
});

export const cancelConnectEventBookingSuccess = (data, obj) => ({
  type: 'CANCEL_CONNECT_EVENT_BOOKING_SUCCESS',
  data,
  obj,
});

export const cancelConnectEventBookingFail = error => ({
  type: 'CANCEL_CONNECT_EVENT_BOOKING_FAIL',
  error,
});
