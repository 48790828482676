/*
**************************************************************************************************************
											                      R&D TECH LIMITED
==============================================================================================================
The contents of this file ("Software") is the property of R&D Tech Limited ("R&D Tech") and is protected by
copyright and other intellectual property laws and treaties.

R&D Tech owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of copies of the
Software, redistribution of the Software and creation of derived works from the Software (and the
redistribution thereof) are subject to the terms of a license agreement issued by R&D Tech.

If the person in possession of the Software has not entered into a license agreement with R&D Tech, then any
use of the Software for any purpose whatsoever is strictly prohibited.
==============================================================================================================

**************************************************************************************************************
					                                  	Amendments
==============================================================================================================
  M004 : 13 Jul 2023 : Carl
    * M-4455:  CONNECT approval have no response on admin panel of research portal
  M003 : 10 Oct 2022 : Nick
    * M-4210: Fix the campaign approve endpoints
	M002 : 24 Jun 2021 : Aman
    * M-3453: Event registration Waiting for Approval table should not show the columns Approved By and Approved Date
	M001 : 02 Jun 2021 : Nick
    * M-3428: Event registration - enhancement on the approval process
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Button, Tab, ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'; //M003
import * as configAction from '../../../actionCreators/Config'; //M003
import Confirm from './approveConfirmModal';
import ApproveForm from './approveForm';
import FullPageLoader from '../../../components/FullPageLoader';
import { Stack } from '@mui/material';

//================M003=====[=======================================
const ApproveTable = ({ cmpId, evtId, getApprovalList, approvalList, approveEvts, loading }) => {
  //M003
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [confirmEvtList, setShowConfirmEvtList] = React.useState([]);
  const [confirmTitle, setShowConfirmTitle] = React.useState('');
  const [tab, setTab] = React.useState('pending');
  const [hackLoading, setHackLoading] = React.useState(false);

  React.useEffect(() => {
    if (cmpId && evtId) {
      getApprovalList(cmpId, evtId);
    }
  }, [cmpId, evtId]);

  const showPopUp = event => {
    const { target } = event;
    const checkedEvents = [];
    const confrimedEvts = [];

    if (target.getElementsByTagName('input')) {
      //================M004=====[=======================================
      const inputArr = Array.from(target.getElementsByTagName('input'));
      inputArr.forEach(item => {
        //================M004=====]=======================================
        if (item.checked) {
          const splitItems = item.value.split('_');
          if (splitItems[0] == 'T') {
            checkedEvents.push({
              iTempUserId: splitItems[1],
            });
          } else {
            checkedEvents.push({
              iPSN_Id: splitItems[1],
            });
          }
        }
      });
    }

    approvalList.forEach(event => {
      checkedEvents.forEach(checkedEvt => {
        if (
          (event.iPSN_Id == checkedEvt.iPSN_Id && event.iPSN_Id != undefined) ||
          (event.iTempUserId == checkedEvt.iTempUserId && event.iTempUserId != undefined)
        ) {
          confrimedEvts.push(event);
        }
      });
    });

    if (tab == 'pending') {
      setShowConfirmTitle('Are you sure to approve the following participants?');
    } else {
      setShowConfirmTitle('Are you sure to send a reminder to the following participants?');
    }

    setShowConfirmEvtList(confrimedEvts);
    setShowConfirm(true);

    event.preventDefault();
  };
  //================M001=====[=======================================
  //=====================M002===========[====================
  const createApprovalForm = () => {
    if (approvalList.length == 0) {
      return (
        <ApproveForm
          pendingApproval
          title="Pending Approval"
          showButton={false}
          loading={loading}
        />
      );
    }
    const campDataSet = [];
    approvalList.forEach(item => {
      if (item.iApprovedById == null) {
        item.isChecked = item.isChecked ?? false;
        campDataSet.push(item);
      }
    });
    return (
      <ApproveForm
        pendingApproval
        title="Pending Approval"
        data={campDataSet}
        showButton
        loading={loading}
      />
    );
  };
  //= ====================M002===========[====================
  const createRemindersForm = () => {
    if (approvalList.length == 0) {
      return <ApproveForm title="Approved" showButton={false} loading={loading} />;
    }
    const campDataSet = [];
    approvalList.forEach(item => {
      if (item.iApprovedById != null) {
        item.isChecked = item.isChecked ?? false;
        campDataSet.push(item);
      }
    });
    return <ApproveForm title="Approved" data={campDataSet} showButton loading={loading} />;
  };
  //= ===============M001=====]=======================================
  const submitEvt = () => {
    approveEvts(cmpId, evtId, confirmEvtList);
    setHackLoading(true);
    setTimeout(function () {
      setHackLoading(false);
      getApprovalList(cmpId, evtId);
    }, 2500);
  };

  if (hackLoading) {
    return <FullPageLoader />;
  }

  return (
    <>
      <Confirm
        show={showConfirm}
        onConfirm={() => {
          setShowConfirm(false);
          submitEvt();
        }}
        onCancel={() => {
          setShowConfirm(false);
        }}
        evtList={confirmEvtList}
        headerText={confirmTitle}
      />
      {/* //================M001=====[======================================= */}

      <Tab.Container id="left-tabs-example" defaultActiveKey={tab}>
        <Tab.Content animation>
          <Tab.Pane eventKey="pending">
            <div className="button-group-box favourites">
              <ButtonToolbar>
                <ToggleButtonGroup type="radio" name="options" defaultValue={tab}>
                  <ToggleButton value="pending" onClick={() => setTab('pending')}>
                    Pending Approval
                  </ToggleButton>
                  <ToggleButton value="approved" onClick={() => setTab('approved')}>
                    Approved
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </div>
          </Tab.Pane>
          <Form className="box connect-table" onSubmit={showPopUp}>
            {tab == 'pending' ? (
              // Pending Approval
              <>
                <div style={{ maxHeight: '52vh', overflow: 'auto' }}>{createApprovalForm()}</div>
                <Stack direction="row" justifyContent="center" style={{ marginTop: '10px' }}>
                  <Button bsStyle="primary" type="submit">
                    Approve
                  </Button>
                </Stack>
              </>
            ) : (
              // Approved
              <>
                <div style={{ maxHeight: '52vh', overflow: 'auto' }}>{createRemindersForm()}</div>
                <Stack direction="row" justifyContent="center" style={{ marginTop: '10px' }}>
                  <Button bsStyle="primary" type="submit">
                    Send Reminders
                  </Button>
                </Stack>
              </>
            )}
          </Form>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};
// //================M001, M003=====[=======================================

function mapStateToProps(state) {
  const { approvalList } = state.Config.events;
  const { loading } = state.Config.events;
  return { approvalList, loading };
}

ApproveTable.defaultProps = {
  approvalList: [],
};

const mapDispatchToProps = dispatch => ({
  getApprovalList: bindActionCreators(configAction.getEventApprovalList, dispatch),

  approveEvts: bindActionCreators(configAction.approveEvents, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApproveTable));
