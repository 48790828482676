export const getAllVideos = obj => ({
  type: 'GET_ALL_VIDEOS',
  obj,
});

export const getAllVideosSuccess = (data, obj) => ({
  type: 'GET_ALL_VIDEOS_SUCCESS',
  data,
  obj,
});

export const getAllVideosFail = (error, obj) => ({
  type: 'GET_ALL_VIDEOS_FAILURE',
  error,
  obj,
});

export const getMostWatched = obj => ({
  type: 'GET_MOST_WATCHED',
  obj,
});

export const getMostWatchedSuccess = (data, obj) => ({
  type: 'GET_MOST_WATCHED_SUCCESS',
  data,
  obj,
});

export const getMostWatchedFail = (error, obj) => ({
  type: 'GET_MOST_WATCHED_FAILURE',
  error,
  obj,
});

export const getSavedVideos = obj => ({
  type: 'GET_SAVED_VIDEOS',
  obj,
});

export const getSavedVideosSuccess = (data, obj) => ({
  type: 'GET_SAVED_VIDEOS_SUCCESS',
  data,
  obj,
});

export const getSavedVideosFail = (error, obj) => ({
  type: 'GET_SAVED_VIDEOS_FAILURE',
  error,
  obj,
});

export const SaveVideo = (obj, id) => ({
  type: 'SAVE_VIDEO',
  obj,
  id,
});

export const SaveVideoSuccess = (data, obj) => ({
  type: 'SAVE_VIDEO_SUCCESS',
  data,
  obj,
});

export const SaveVideoFail = (error, obj) => ({
  type: 'SAVE_VIDEO_FAILURE',
  error,
  obj,
});

export const AccessVideo = (obj, id) => ({
  type: 'ACCESS_VIDEO',
  obj,
  id,
});

export const AccessVideoSuccess = (data, obj) => ({
  type: 'ACCESS_VIDEO_SUCCESS',
  data,
  obj,
});

export const AccessVideoFail = (error, obj) => ({
  type: 'ACCESS_VIDEO_FAILURE',
  error,
  obj,
});

export const setCurrentVideoTime = (obj, id) => ({
  type: 'SET_CURRENT_VIDEO_TIME',
  obj,
  id,
});

export const setCurrentVideoTimeSuccess = (data) => ({
  type: 'SET_CURRENT_VIDEO_TIME_SUCCESS',
  data
});

export const setCurrentVideoTimeFail = (error, obj) => ({
  type: 'SET_CURRENT_VIDEO_TIME_FAILURE',
  error,
  obj,
});