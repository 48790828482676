/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
    M001 : 24 Jun 2021 : Aman
    * M-0003453: Event registration Waiting for Approval table should not show the columns Approved By and Approved Date
**************************************************************************************************************
*/
import React from 'react';
import PropTypes from 'prop-types';
import { approveTableConfig, pendingApprovalTableConfig } from './approveTableConfig';
import { Button, Form } from 'react-bootstrap';
import Table from '../../../components/Table';

const ApproveForm = ({ title, data, pendingApproval, loading }) => {
  
  const sortCheckboxes = data => {
    setTimeout(() => {
      if (data?.length > 0) {
        data.forEach(d => {
          const inp = document.getElementById(d.iPSN_Id ? `S_${d.iPSN_Id}` : `T_${d.iTempUserId}`);
          if (inp) {
            inp.checked = d.isChecked;
          }
        });
      }
    });
  };

  return (
    <>
      {/* //=====================M001===========]==================== */}
      {pendingApproval ? (
        <Table
          title={title}
          searchAble={false}
          labels={pendingApprovalTableConfig()}
          data={data}
          loading={loading}
          sortCheckboxes={sortCheckboxes}
          defaultText={'Empty'}
        />
      ) : (
        <Table
          title={title}
          searchAble={false}
          labels={approveTableConfig()}
          sortCheckboxes={sortCheckboxes}
          data={data}
          loading={loading}
          defaultText={'Empty'}
        />
      )}
      {/* //=====================M001===========]==================== */}
    </>
  );
};

ApproveForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ApproveForm;
