import React from 'react';
import { Row, Col, Button, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { doPost } from '../../../utils/request';

const AOIForm = ({type}) => {

    const [aoiFormName, setaoiFormName] = React.useState("")
    const [aoiFormEmail, setaoiFormEmail] = React.useState("")
    const [aoiFormMessage, setaoiFormMessage] = React.useState("")
    const [aoiFormCaptcha, setaoiFormCaptcha] = React.useState("")
    const [aoiFormErr, setaoiFormErr] = React.useState({
        'name':'',
        'email':'',
        'message':'',
        'captcha':''
    })
    const [aoiFormSuccessMsg, setaoiFormSuccessMsg] = React.useState("");

    const submitForm = () => {
        //Validation First
        var nameErr, emailErr, messageErr, captchaErr;
        if (!aoiFormName) {
            nameErr = "Please input your name."
        }
        if (!aoiFormEmail) {
            emailErr = "Please input your email address."
        }
        if (!aoiFormMessage) {
            messageErr = "Please input your message."
        }
        if (!aoiFormCaptcha) {
            captchaErr = "Please solve the challenge."
        }
        if (nameErr || emailErr || messageErr || captchaErr) {
            setaoiFormErr({
                'name': nameErr,
                'email': emailErr,
                'message': messageErr,
                'captcha': captchaErr
            })
        } else {
            setaoiFormErr({
                'name':'',
                'email':'',
                'message':'',
                'captcha':''
            })
            //DO SUMBIT
            var insertObj = {
                name: aoiFormName,
                email: aoiFormEmail,
                message: aoiFormMessage,
                captcha: aoiFormCaptcha,
                type: type
            }
            doPost('/aoi', insertObj).then(response => {
                const { data } = response;
                console.log(response);
                if (data.error) {
                    setaoiFormErr({
                        'feedBackError':data.error
                    })
                } else {
                    setaoiFormSuccessMsg(data.success)
                }
            })
            //Submission
        }
    }

    return (
        <>
        {/* FORM SUBMISSION */}
            <Row style={{marginTop:50, marginBottom:50}} >
                <Col sm={2} xs={0}></Col>
                <Col sm={8} xs={12}>
                    <Col sm={2} xs={12} style={{textAlign:'center'}}>
                        <ControlLabel style={{paddingTop:5}}>Your Name: </ControlLabel>
                    </Col>
                    <Col sm={4} xs={12}>
                        <FormGroup validationState={aoiFormErr.name?"error":null}>
                            <FormControl
                                type="text"
                                placeholder={"Name"}
                                required={true}
                                onKeyUp={(e)=>setaoiFormName(e.target.value)}
                                isInvalid={aoiFormErr.name}
                                onClick={()=>{
                                    setaoiFormErr({
                                        'name':'',
                                        'email':'',
                                        'message':'',
                                        'captcha':''
                                    })
                                }}
                            />
                            <HelpBlock>
                                {aoiFormErr.name}
                            </HelpBlock>
                        </FormGroup>
                    </Col>
                    <Col sm={2} xs={12} style={{textAlign:'center'}}>
                        <ControlLabel style={{paddingTop:5}}>Your Email: </ControlLabel>
                    </Col>
                    <Col sm={4} xs={12}>
                        <FormGroup validationState={aoiFormErr.email?"error":null}>
                            <FormControl
                                type="text"
                                placeholder={"Email"}
                                required={true}
                                onKeyUp={(e)=>setaoiFormEmail(e.target.value)}
                                isInvalid={aoiFormErr.email}
                                onClick={()=>{
                                    setaoiFormErr({
                                        'name':'',
                                        'email':'',
                                        'message':'',
                                        'captcha':''
                                    })
                                }}
                            />
                            <HelpBlock>
                                {aoiFormErr.email}
                            </HelpBlock>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={2} style={{textAlign:'center'}}>
                        <ControlLabel>Message: </ControlLabel>
                    </Col>
                    <Col xs={12} md={10}>
                        <FormGroup validationState={aoiFormErr.message?"error":null}>
                            <FormControl
                                componentClass="textarea"
                                type="textarea"
                                placeholder={"Message"}
                                style={{minHeight:150}}
                                required={true}
                                onKeyUp={(e)=>setaoiFormMessage(e.target.value)}
                                isInvalid={aoiFormErr.message}
                                onClick={()=>{
                                    setaoiFormErr({
                                        'name':'',
                                        'email':'',
                                        'message':'',
                                        'captcha':''
                                    })
                                }}
                            />
                            <HelpBlock>
                                {aoiFormErr.message}
                            </HelpBlock>
                        </FormGroup>
                    </Col>
                    <Col xs={12} style={{textAlign:'center'}}>
                        <Col sm={3} xs={0}></Col>
                        <Col sm={6} xs={12}>
                            <FormGroup validationState={aoiFormErr.captcha?"error":null}>
                                <HCaptcha
                                    sitekey={"7c74986f-b7c7-45e6-89f3-2cfd96154639"}
                                    onVerify={(token,ekey)=>{
                                        setaoiFormErr({
                                            'name':'',
                                            'email':'',
                                            'message':'',
                                            'captcha':''
                                        })
                                        doPost('/aoi/verifyToken', {token: token}).then(response => {
                                            const { data } = response;
                                            console.log(response);
                                            if (data.error) {
                                                setaoiFormErr({
                                                    'captcha':data.error
                                                })
                                            } else {
                                                setaoiFormCaptcha(data.t)
                                            }
                                        })
                                    }}
                                />
                                <HelpBlock>
                                    {aoiFormErr.captcha}
                                </HelpBlock>
                            </FormGroup>
                            <FormGroup validationState={aoiFormErr.feedBackError?"error":null}>
                                {aoiFormSuccessMsg && <p className="text-success">{aoiFormSuccessMsg}</p>}
                                <HelpBlock>
                                    {aoiFormErr.feedBackError}
                                </HelpBlock>
                            </FormGroup>
                            <Button
                                    disabled={aoiFormSuccessMsg?true:false}
                                    type="submit"
                                    bsStyle="primary"
                                    style={{ width: '100%', marginTop: '15px' }}
                                    onClick={()=> submitForm()}
                                >
                                Submit
                            </Button>
                            </Col>
                        </Col>
                        <Col sm={3} xs={0}></Col>
                    </Col>
                <Col sm={2} xs={0}></Col>
            </Row>
        {/* FORM SUBMISSION */}
        </>
    )
}

export default AOIForm;
