import React from 'react';
import { Row, Col, Button, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import AOIForm from './AOIForm';

const AOIInvestor = () => {

    return (
        <>
        {/* INTRO */}
        <Row>
            <Col xs={2}></Col>
            <Col xs={8}>
                <div className='aoi-intro'>
                    <p>
                        <i>Aletheia Capital is Asia's first fully independent content firm built to scale. We are focused
                        purely on providing insightful content and ideas to our clients and are fiercely independent in our views
                        </i>
                    </p>
                </div> 
            </Col>
            <Col xs={2}></Col>
        </Row>
        {/* INTRO */}
        {/* QUESTION */}
        <div className='aoi-question-div-head-tablet'>
            <Row className='aoi-question-head'>
                <Col xs={2}></Col>
                <Col xs={8}>
                    <div className='aoi-question-head-sub'>are you an</div>
                    <div>analyst?</div>
                </Col>
                <Col xs={2}></Col>
            </Row>
            <Row>
                <Col xs={2}></Col>
                <Col xs={8}>
                    <div className='aoi-question-body'>
                        <p>Would you like to be part of the largest transformation sweeping the financial industry in the past two decades?</p>
                        <br></br>
                        <p>Do you have a singular focus on alpha generation?
                        </p>
                        <br></br>
                        <p>Are you in search of a sustainable and satisfying career opportunity where you can leverage your analytical skills
                    for the benefit of Aletheia clients and yourself?</p>
                        <br></br>
                        <p>If you answer 'YES' to any of the above or want more information about a career at Aletheia, please fill in this brief form
                    and we will be in touch shortly.
                        </p>
                    </div>
                </Col>
                <Col xs={2}></Col>
            </Row>
        </div>
        <div className='aoi-question-div-head-mobile'>
            <Row>
                <Col xs={2}></Col>
                <Col xs={4} className='aoi-question-head-mobile'>
                    <div className='aoi-question-head-sub'>are you an</div>
                    <div>analyst?</div>
                </Col>
                <Col xs={4}>
                    <div className='aoi-question-body'>
                        <p>Would you like to be part of the largest transformation sweeping the financial industry in the past two decades?</p>
                        <br></br>
                        <p>Do you have a singular focus on alpha generation?
                        </p>
                        <br></br>
                        <p>Are you in search of a sustainable and satisfying career opportunity where you can leverage your analytical skills
                    for the benefit of Aletheia clients and yourself?</p>
                        <br></br>
                        <p>If you answer 'YES' to any of the above or want more information about a career at Aletheia, please fill in this brief form
                    and we will be in touch shortly.
                        </p>
                    </div>
                </Col>
                <Col xs={2}></Col>
            </Row>
        </div>
        {/* QUESTION */}
        <AOIForm type={'Analyst'}></AOIForm>
        </>
    );
};

export default AOIInvestor;
