import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import CompanyCard from './components/CompanyCard';
import ReportsContainer from '../../containers/Reports';
import { notify } from 'react-notify-toast';
import * as companyActions from '../../actionCreators/Company';
import * as configActions from '../../actionCreators/Config';

const Container = ({ location: { pathname }, company, getCompany, editBiography, currentUser }) => {
  const id = pathname.split('/')[2];

  React.useEffect(() => {
    getCompany({ id });
  }, [getCompany, id]);
  const editBioGraphyHandler = data => {
    editBiography({ iDescription_AttachToID: id, sDescription: data, cDescription_Entity: 'L' });
    const popUpColor = { background: '#337ab7', text: '#FFFFFF' };
    notify.show('Your changes has been saved successfully!', 'custom', 5000, popUpColor);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{company.data && (company.data.name || company.data.display)}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <CompanyCard
        data={company.data}
        user={currentUser.data}
        loading={company.loading}
        style={{ margin: '20px 0px', borderRadius: '10px' }}
        onEditBiography={editBioGraphyHandler}
      />
      <ReportsContainer companyId={pathname.split('/')[2]} />
    </>
  );
};

const mapStateToProps = ({ company: { company }, user: { currentUser } }) => ({
  company,
  currentUser,
});

const mapDispatchToProps = dispatch => ({
  getCompany: bindActionCreators(companyActions.getCompany, dispatch),
  editBiography: bindActionCreators(configActions.editBiography, dispatch),
});

Container.propTypes = {
  getCompany: PropTypes.func.isRequired,
  editBiography: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  currentUser: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
