import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import analyst from './Analyst';
import company from './Company';
import country from './Country';
import research from './Research';
import sector from './Sector';
import user from './User';
import userModal from './UserModal';
import Config from './Config';
import videos from './Videos';
import ourfirm from './OurFirm';
import blog from './Blog';
import connect from './Connect';
import admin from './Admin';

export default combineReducers({
  analyst,
  company,
  country,
  research,
  sector,
  user,
  userModal,
  Config,
  videos,
  form,
  videos,
  ourfirm,
  blog,
  connect,
  admin,
});
