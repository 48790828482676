export const getContent = obj => ({
  type: 'GET_CONTENT',
  obj,
});

export const getContentSuccess = (data, obj) => ({
  type: 'GET_CONTENT_SUCCESS',
  data,
  obj,
});

export const getContentFail = error => ({
  type: 'GET_CONTENT_FAIL',
  error,
});

export const updateContent = (obj, content) => ({
  type: 'UPDATE_CONTENT',
  obj,
  content,
});

export const updateContentSuccess = (data, obj) => ({
  type: 'UPDATE_CONTENT_SUCCESS',
  obj,
  data,
});

export const updateContentFail = (error, obj) => ({
  type: 'UPDATE_CONTENT_FAIL',
  error,
  obj,
});
