/*
**************************************************************************************************************
												R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author	:	Nick Ng
	Date	:	10 Oct 2022
**************************************************************************************************************
												Usage Notes
**************************************************************************************************************

**************************************************************************************************************
												Amendments
**************************************************************************************************************
	M002 : 10 Oct 2022 : Nick Ng : Core
		 * M-4180: Mirgrate research portal to use public module api and deprecate Node.js
	M001 : 05 August 2020 : Aman
		* M-0002939: Our People page should allow sort ordering of staff from the admin page.
**************************************************************************************************************
*/

import { all, put, takeEvery } from 'redux-saga/effects';
import qs from 'querystring';

import {
  getAllSuccess,
  getAllFail,
  getOurPeopleSuccess,
  getOurPeopleFail,
  getAnalystSuccess,
  getAnalystFail,
  updateAnalystSuccess,
  updateAnalystFail,
  //====================M001=====[====================
  sortOurTeamSuccess,
  sortOurTeamFail,
  //====================M001=====[====================
} from '../actionCreators/Analyst';
import { doGet, doPost } from '../utils/request';

function* getAnalyst({ obj }) {
  try {
    const response = yield doGet(`/analysts?${qs.stringify(obj)}`); //M002
    yield put(getAnalystSuccess(response.data, obj));
  } catch (err) {
    yield put(getAnalystFail(err.message));
  }
}

function* getAnalysts({ obj }) {
  try {
    const response = yield doGet(`/analysts?${qs.stringify(obj)}`);
    yield put(getAllSuccess(response.data, obj));
  } catch (err) {
    yield put(getAllFail(err.message));
  }
}

function* getOurPeople({ obj }) {
  try {
    const response = yield doGet(`/overall/ourPeople?${qs.stringify(obj)}`);
    yield put(getOurPeopleSuccess(response.data, obj));
  } catch (err) {
    yield put(getOurPeopleFail(err.message));
  }
}

function* updateAnalyst({ obj }) {
  try {
    const data = new FormData();
    let { description } = obj;
    data.append('file', obj.image_path);
    data.append('name', obj.name);
    data.append('designation', obj.designation);
    data.append('email', obj.email);
    data.append('phone', obj.phone);
    data.append('country', obj.country);
    if (description === '<p><br></p>') {
      description = null;
    }
    data.append('description', description);
    const response = yield doPost(`/analysts/updateAnalyst?id=${obj.id}`, data);
    yield put(updateAnalystSuccess(response.data.data));
  } catch (err) {
    yield put(updateAnalystFail(err.message));
  }
}

function* updateOurTeam({ obj }) {
  try {
    const response = yield doPost(`/overall/updateOurPeople`, obj);
    if (obj.membershipStatus) {
      yield put(getOurPeopleSuccess(response.data.data));
    }
  } catch (err) {
    yield put(getOurPeopleFail(err.message));
  }
}

//====================M001=====[====================
function* sortOurTeam({ obj }) {
  try {
    const response = yield doPost(`/overall/sortOurPeople`, obj);
    yield put(sortOurTeamSuccess(response.data, obj));
  } catch (err) {
    yield put(sortOurTeamFail(err.message));
  }
}
//====================M001=====[====================

export default function* watcher() {
  yield all([
    takeEvery('GET_ANALYSTS', getAnalysts),
    takeEvery('GET_OUR_PEOPLE', getOurPeople),
    takeEvery('GET_ANALYST', getAnalyst),
    takeEvery('UPDATE_ANALYST', updateAnalyst),
    takeEvery('UPDATE_OUR_TEAM', updateOurTeam),
    //====================M001=====[====================
    takeEvery('SORT_OUR_TEAM', sortOurTeam),
    //====================M001=====[====================
  ]);
}
