import React from 'react';
import { Image, Grid } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import Box from '../../components/Box';

import CareersImage from '../../assets/images/career-footer.jpg';

const Container = () => {
  React.useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Careers</title>
        <meta name="description" content="This is page is for the careers" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Grid>
        <Box style={{ borderRadius: 5 }}>
          <h1>Careers</h1>

          <h4>Do YOU have a passion to analyze the past and present, and predict the future?</h4>

          <h4>Are you:</h4>
          <ul>
            <li>
              an established sell side or buy side analyst, or at a corporate/consultancy, and have
              strong analytical skills?
            </li>
            <li>
              apprehensive of uncertainty in the sector and tired of the constant changes at your
              firm?
            </li>
            <li>looking to escape from unwarranted bureaucratic barriers?</li>
            <li>
              in search of a sustainable and satisfying finance career where you can re-engage your
              core competencies?
            </li>
            <li>willing to be your own master?</li>
            <li>
              a salesperson with exceptional sales skills, and wish to build an exciting business?
            </li>
          </ul>
          <p>
            If your answers to the above are a yes, then we have a long-term career opportunity for
            you at Aletheia Capital.
          </p>

          <h4>Aletheia Capital (and/or its subsidiaries subject to regulatory approvals):</h4>
          <ul>
            <li>is Asia's first fully independent content firm built to scale</li>
            <li>
              is only - and only - focused on providing insightful content and ideas to its clients
            </li>
            <li>
              is fiercely independent in its views is compliant to regulators where ever we operate,
              with regulatory licenses in all global hubs (subject to regulatory approvals)
            </li>
            <li>
              is free from any conflicts of interest currently plaguing most of the industry players
            </li>
            <li> is owned and managed by its partners</li>
            <li>
              is managed by the most experienced and successful managers in Asian Equities
            </li>{' '}
            <li>
              is looking to redefine the customer experience with cutting edge technology -
              currently lacking in the industry
            </li>
            <li>
              already has clients with combined AUM of US$4tn and distribution access to over one
              thousand
            </li>{' '}
            <li>
              plans to redefine the Asian content creation and delivery experience for global
              clients
            </li>
          </ul>

          <h4>Aletheia is looking to offer the right person:</h4>
          <ul>
            <li>equity partnership</li>
            <li>exceptional remuneration opportunities directly correlated to performance.</li>
            <li>a non-bureaucratic working environment (but 100% compliant).</li>
            <li>life style balance and flexibility</li>
            <li>
              a true partnership environment with unparalleled short-and long-term incentive
              structure
            </li>
            <li>flexibility in product formation.</li>
            <li>a capacity to cater to a much broader and robust client base</li>
          </ul>

          <p>
            Be part of the largest transformation sweeping the industry in the past two decades.
            Aletheia is all about empowering YOU and YOUR product to its fullest potential!
          </p>
          <p>
            Contact us (in confidence) at{' '}
            <a href="mailto:careers@aletheia-capital.com">careers@aletheia-capital.com</a>
          </p>

          <div className="career-banner-image">
            <Image src={CareersImage} alt="careers footer" responsive />
          </div>
        </Box>
      </Grid>
    </>
  );
};

export default Container;
