import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';

import FormContainer from '../../components/FormContainer';

const Container = ({ location }) => {
  const type = String(location.pathname.split('/')[2]).toLowerCase();

  if (type === 'verify') {
    return (
      <FormContainer>
        <div className="box-email-confirm">
          <p>Thank you! Your email has been verified. Please login to our research portal.</p>
          <Link href="/" to="/">
            Back to home
          </Link>
        </div>
      </FormContainer>
    );
  }

  return null;
};

Container.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Container);
