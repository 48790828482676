const initialState = {
  content: {
    loading: false,
    error: null,
    data: null,
  },
};

export default (state = initialState, { type, error, data }) => {
  switch (type) {
    case 'GET_CONTENT':
      return {
        ...state,
        content: {
          ...state.content,
          loading: true,
        },
      };

    case 'GET_CONTENT_SUCCESS':
      return {
        ...state,
        content: {
          data: data,
          loading: false,
        },
      };

    case 'GET_CONTENT_FAIL':
      return {
        ...state,
        content: {
          ...state.content,
          error,
          loading: true,
        },
      };
    default:
      return state;
  }
};
