import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

const Component = ({ loading, data, title }) => (
  <div>
    {title && (
      <div
        style={{
          background: '#036ca6',
          fontSize: '115%',
          padding: '3%',
          color: 'white',
          fontWeight: 700,
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
        }}
      >
        {title}
      </div>
    )}
    <div style={{ padding: '5%' }}>
      {loading
        ? Array.from({ length: 20 }).map((v, i) => (
            <div
              key={`list_loading_${i}`}
              style={{
                borderTopStyle: 'solid',
                borderWidth: 1,
                borderBottomStyle: 'solid',
                borderColor: '#F5F5F5',
              }}
            >
              <div style={{ padding: 5 }}>
                <h5>
                  <Skeleton />
                </h5>
                <h6>
                  <Skeleton />
                </h6>
                <h6>
                  <Skeleton />
                </h6>
              </div>
            </div>
          ))
        : data.map((item, index) => (
            <div
              key={`list_item_${index}_${item.id}`}
              style={{
                borderTopStyle: 'solid',
                borderWidth: 1,
                borderBottomStyle: 'solid',
                borderColor: '#F5F5F5',
              }}
            >
              <div style={{ padding: '0px 5px' }}>
                {item.aside && <span className="small pull-right">{item.aside}</span>}
                <h5>
                  {item.titleLink && item.title !== 'string' ? (
                    <Link to={item.titleLink}>{item.title}</Link>
                  ) : (
                    item.title
                  )}
                </h5>
                <h6>
                  {item.subtitleLink ? (
                    <Link to={item.subtitleLink}>{item.subtitle}</Link>
                  ) : (
                    item.subtitle
                  )}
                </h6>
                {item.description && <h6>{item.description}</h6>}
              </div>
            </div>
          ))}
    </div>
  </div>
);

Component.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

Component.defaultProps = {
  loading: false,
  data: [],
  title: null,
};

export default Component;
