import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Grid, Tab, Col, Row, NavItem, Nav } from 'react-bootstrap';
import Box from '../../components/Box';
import ApproveTable from './components/approveTable';

import * as configAction from '../../actionCreators/Config';

const AletheiaConnectReg = ({ getApprovalEvents, approvalEvents }) => {
  const [activeKey, setActiveKey] = React.useState();
  const [activeCmpg, setActiveCmpg] = React.useState();

  React.useEffect(() => {
    getApprovalEvents();
  }, []);

  const generateLeftPanel = () => {
    const rtnArr = [];
    if (approvalEvents.data.length > 0) {
      approvalEvents.data.forEach(o => {
        rtnArr.push(
          <>
            <Nav bsStyle="pills" stacked>
              <h4 style={{ fontSize: 16 }}>{o.title}</h4>
              {o.evts ? (
                o.evts.map(e => (
                  <NavItem
                    onClick={() => {
                      setActiveKey(e.id);
                      setActiveCmpg(o.id);
                    }}
                    eventKey={e.id}
                  >
                    {e.title}
                  </NavItem>
                ))
              ) : (
                <></>
              )}
            </Nav>
          </>
        );
      });
      return rtnArr;
    }
  };

  return (
    <>
      <Helmet>
        <title>CONNECT Approval</title>
      </Helmet>
      {/* LEFT PANEL */}
      <Row className="box-favourites-settings" style={{ marginTop: '3%' }}>
        <Col lg={10} mdOffset={1} md={10}>
          <Box className="box-upload-image">
            <Tab.Container id="left-tabs-example" activeKey={activeKey}>
              <Row>
                {/* LEFT PANEL */}
                <Col sm={3}>
                  <h4 style={{ paddingBottom: '2%', fontWeight: 'bold' }}> CONNECT Approval </h4>
                  <div style={{ height: '65vh', overflowX: 'hidden', overflowY: 'auto' }}>
                    {generateLeftPanel()}
                  </div>
                </Col>
                {/* RIGHT PANEL */}
                <Col sm={9}>
                  {activeKey && <ApproveTable evtId={activeKey} cmpId={activeCmpg} />}
                </Col>
              </Row>
            </Tab.Container>
          </Box>
        </Col>
      </Row>
    </>
  );
};

function mapStateToProps(state) {
  const approvalEvents = state.Config.events;
  const { loading } = state.Config.events;
  return { approvalEvents, loading };
}

const mapDispatchToProps = dispatch => ({
  getApprovalEvents: bindActionCreators(configAction.getEventsApprovalEvents, dispatch),
  // approveEvts: bindActionCreators(configAction.approveEvents, dispatch),
});

AletheiaConnectReg.propTypes = {
  getApprovalEvents: PropTypes.func.isRequired,
  approvalEvents: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AletheiaConnectReg));
