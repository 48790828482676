import React from 'react';
import { Row, Image, Col, NavDropdown, MenuItem } from 'react-bootstrap';

const CopyLinkDropDown = ({ title, data }) => {
  const copyLinkClickHandler = link => {
    navigator.clipboard.writeText(link);
  };
  return (
    <>
      <NavDropdown
        open={true}
        title={title}
        className={'admin-dropdown btn btn-primary'}
        style={{
          position: 'relative',
          zIndex: 999,
        }}
      >
        {/* {adminMenuItem()} */}
        {/* <MenuItem>Tech presentation</MenuItem> */}
        {Object.keys(data).map(item => (
          <MenuItem onClick={() => copyLinkClickHandler(data[item])}>{item}</MenuItem>
        ))}
      </NavDropdown>
    </>
  );
};

export default CopyLinkDropDown;
