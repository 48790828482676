import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import HeaderConnect from '../../components/PageHeader';
import { Helmet } from 'react-helmet';
import CompaniesList from './components/companiesList'
import { Col } from 'react-bootstrap'
import CompanyFilter from './components/companiesFilter'

import * as companyActions from '../../actionCreators/Company';

const CompaniesPage = ({
    loading,
    companies,
    getCompanies,
}) => {

    const [filteredCompaines, setFilteredCompaines] = React.useState([]);

    React.useEffect(() => {
        getCompanies()
    }, []);

    const loopCharSelect = () => {
            var alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
            var rtnEles = [];
            for (var i = 0; i < alphabet.length; i++) {
                rtnEles.push(<a style={{cursor:'pointer'}} onClick={(a)=>filterCompany(a)}>{alphabet[i]}</a>)
                if (i+1!=alphabet.length) {
                    rtnEles.push(<span>   |   </span>)
                }
            }
            return rtnEles
    }

    const filterCompany = (a) => {
        var filterdCompaines = []
        companies.forEach(company => {
            if(company.sName.toUpperCase().startsWith(a.currentTarget.textContent)) {
                filterdCompaines.push(company);
            }
        });
        setFilteredCompaines(filterdCompaines);
    }

    React.useEffect(()=>{
        if(!companies.length && !loading){
            getCompanies();
        } else {
            var filterdCompaines = []
            companies.forEach(company => {
                if(company.sName.toUpperCase().startsWith('A')) {
                    filterdCompaines.push(company);
                }
            });
            setFilteredCompaines(filterdCompaines)
        }
    },[companies])

    return (
        <>
            <Helmet>
                <title>COVERAGE UNIVERSE</title>
            </Helmet>
            <HeaderConnect pageName="COVERAGE UNIVERSE" pageDesc="" />
            <br />
            <CompanyFilter companies={companies} setFilteredCompaines={setFilteredCompaines}></CompanyFilter>
            <Col lg={12} className="text-center" style={{paddingTop:'1%',paddingBottom:'1%'}}>
                {loopCharSelect()}
            </Col>
            <CompaniesList loading={loading} data={filteredCompaines}></CompaniesList>
        </>
    );
};

const mapStateToProps = ({ 
    company: {
        company: {
            loading: loading,
            data: companies
        }
    }
}) => ({ companies, loading });

const mapDispatchToProps = dispatch => ({
  getCompanies: bindActionCreators(companyActions.getCompany, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompaniesPage));
