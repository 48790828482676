import React from 'react';
import Aletheia360 from '../../../assets/images/Access360.png';
import {
  Grid,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const Section3 = () => {
  return (
    <div className="section3-container">
      <Stack justifyContent="center" alignItems="center">
        <div style={{ width: '65%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Stack justifyContent="center" alignItems="center">
                <img src={Aletheia360} style={{ height: '500px' }} />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    fontSize={19}
                    color="#036ca6"
                    style={{ fontFamily: 'open_sanssemibold' }}
                  >
                    Private Equity / VC Funds
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    fontSize={16}
                    color="#7e8387"
                    style={{ fontFamily: 'open_sansregular' }}
                  >
                    <ul>
                      <li>Valuation of existing private investments</li>
                      <li>Independent valuation of target investments</li>
                      <li>Preparation for future rounds.</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography
                    fontSize={19}
                    color="#036ca6"
                    style={{ fontFamily: 'open_sanssemibold' }}
                  >
                    Public / Private Markets
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    fontSize={16}
                    color="#7e8387"
                    style={{ fontFamily: 'open_sansregular' }}
                  >
                    <ul>
                      <li>Public / Private Markets</li>
                      <li>Valuation estimates</li>
                      <li>Investor views and feedback.</li>
                      <li>IPO comparisons</li>
                      <li>Investor marketing</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <Typography
                    fontSize={19}
                    color="#036ca6"
                    style={{ fontFamily: 'open_sanssemibold' }}
                  >
                    Founders / Corporates
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    fontSize={16}
                    color="#7e8387"
                    style={{ fontFamily: 'open_sansregular' }}
                  >
                    <ul>
                      <li>Validation of valuation and strategy</li>
                      <li>Access to investor base and their views</li>
                      <li>Independent advisory</li>
                      <li>Better stock discovery</li>
                      <li>Pre-IPO / IPO comparisons</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* <Stack className="section3-item">
                <div className="section3-titleContainer">
                  <Typography fontSize={19}>Private Equity / VC Funds</Typography>
                </div>
                <Typography fontSize={16} color="#2d4471">
                  <ul>
                    <li>Valuation of existing private investments</li>
                    <li>Independent valuation of target investments</li>
                    <li>Preparation for future rounds.</li>
                  </ul>
                </Typography>
              </Stack>
              <Stack className="section3-item">
                <div className="section3-titleContainer">
                  <Typography fontSize={19}>Public / Private Markets</Typography>
                </div>
                <Typography fontSize={16} color="#2d4471">
                  <ul>
                    <li>Public / Private Markets</li>
                    <li>Valuation estimates</li>
                    <li>Investor views and feedback.</li>
                    <li>IPO comparisons</li>
                    <li>Investor marketing</li>
                  </ul>
                </Typography>
              </Stack>
              <Stack className="section3-item">
                <div className="section3-titleContainer">
                  <Typography fontSize={19}>Founders / Corporates</Typography>
                </div>
                <Typography fontSize={16} color="#2d4471">
                  <ul>
                    <li>Validation of valuation and strategy</li>
                    <li>Access to investor base and their views</li>
                    <li>Independent advisory</li>
                    <li>Better stock discovery</li>
                    <li>Pre-IPO / IPO comparisons</li>
                  </ul>
                </Typography>
              </Stack> */}
            </Grid>
          </Grid>
        </div>
      </Stack>
    </div>
  );
};

export default Section3;
