import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from 'react-bootstrap';
import CookieConsent from 'react-cookie-consent';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Layout = ({ children, fullScreenMode }) => {
  const [menu, setMenu] = React.useState(false);
  const [headerClass, setHeaderClass] = React.useState('header-stickky');
  const [hoverDiv, setHoverDiv] = React.useState(true);

  const showHeader = val => {
    setMenu(val);
    setHeaderClass('show-header');
    const pdfBox = document.getElementById('pdf-viewer-box');
    pdfBox.style.zIndex = -1;
  };
  const hideHeader = () => {
    const pdfBox = document.getElementById('pdf-viewer-box');
    pdfBox.style.zIndex = 1;
  };
  return (
    <>
      {fullScreenMode ? (
        <>
          {hoverDiv && (
            <div
              className="invisible-div"
              onMouseOver={() => {
                showHeader(true);
                setHoverDiv(false);
              }}
            />
          )}
          <header
            id="header-expanded-view"
            className={headerClass}
            onMouseLeave={() => {
              showHeader(false);
              setHoverDiv(true);
              setHeaderClass('header-stickky');
              hideHeader();
            }}
            onClick={() => {
              showHeader(false);
              setHoverDiv(true);
              setHeaderClass('header-stickky');
              hideHeader();
            }}
          >
            <Header />
          </header>
        </>
      ) : (
        <>
          <header>
            <Header />
          </header>
        </>
      )}
      <main id="main-body">
        <Grid fluid>{children}</Grid>
      </main>
      <footer>
        <Footer />
      </footer>
      <CookieConsent
        cookieName="cookie_consent"
        location="bottom"
        style={{ background: 'white', color: '#036CA6', fontSize: '12px', fontWeight: 'bold' }}
        buttonStyle={{
          background: '#337ab7',
          color: 'white',
          borderColor: '#2e6da4',
          padding: '10px 25px',
          textTransform: 'uppercase',
          borderRadius: '5px',
        }}
      >
        {
        "This website uses cookies to improve your browsing experience. You give consent for cookies to be used if you continue to browse this website. To learn more about how our website uses cookies, please read our "}
        <a href="/privacy">Privacy Policy Statement</a>.
      </CookieConsent>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  fullScreenMode: PropTypes.bool,
};

const mapStateToProps = ({ Config: { fullScreenMode } }) => ({
  fullScreenMode,
});

export default connect(mapStateToProps)(Layout);
