import { all } from 'redux-saga/effects';

import analystWatcher from './Analyst';
import companyWatcher from './Company';
import countryWatcher from './Country';
import researchWatcher from './Research';
import sectorWatcher from './Sector';
import userWatcher from './User';
import configWatcher from './Config';
import videosWatcher from './Videos';
import ourfirmwatcher from './OurFirm';
import blogWatcher from './Blog';
import connectWatcher from './Connect';
import adminWatcher from './Admin'

export default function* rootWatchers() {
  yield all([
    analystWatcher(),
    companyWatcher(),
    countryWatcher(),
    researchWatcher(),
    sectorWatcher(),
    userWatcher(),
    configWatcher(),
    videosWatcher(),
    ourfirmwatcher(),
    blogWatcher(),
    connectWatcher(),
    adminWatcher()
  ]);
}
