import React from 'react';
import { Row, Image, Col, NavDropdown, MenuItem } from 'react-bootstrap';
import { adminMenuConfig } from './adminMenuConfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const AdminMenu = ({history}) => {

    const navigate = path => history.push(path);
    const adminMenuItem = () => {
        var rtnElements = []
        var adminMenuItems = adminMenuConfig();
        adminMenuItems.forEach(item=>{
            rtnElements.push(<MenuItem onClick={()=>{navigate(item.path)}}>{item.label}</MenuItem>)
        })
        return rtnElements;
    }

    return (
        <NavDropdown
            open={true}
            title={"Admin Tools"}
            className={"admin-dropdown btn btn-primary"}
            style={{
                position: 'relative',
                zIndex: 999,
            }}
        >
            {adminMenuItem()}
        </NavDropdown>
    )
}   

export default withRouter(connect()(AdminMenu));
