/*
**************************************************************************************************************
            ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
            Amendments
========================================================================
  M001 : 07 September 2020 : Aman
    * M-0003013: Social media icons disappear after login
  M002 : 8 February 2021 : Aman
    * M-0003219: Account page for user to view their details and update their password
  M003 : 13 June 2023 : Zeeshan Siddique
    * M-4367: Add a link to the Spyder marketing website on header that reads "Powered by Spyder CRM"
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Nav, NavItem, MenuItem, NavDropdown, Image } from 'react-bootstrap';
//====================M001=====[====================
import TwitterIcon from '../../../assets/images/twitter.svg';
import FacebookIcon from '../../../assets/images/facebook.svg';
import LinkedInIcon from '../../../assets/images/linkedin.svg';
//====================M001=====[====================
import Confirm from '../../Confirm';

import * as userActions from '../../../actionCreators/User';

import { getUsername } from '../config';
import PoweredBy from '../../../assets/images/powered-by-spyder.png';

const Component = ({
  history,
  user,
  logOut,
  className,
  currentUser,
}) => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const userInfo = getUsername(currentUser.data);
  const handleLogOut = () => {
    history.push('/');
    logOut(history);
    setShowConfirm(false);
    //====================M001=====[====================
    sessionStorage.clear();
    //====================M001=====[====================
  };

  // Checks if user is on reports page
  const reportPage = history.location.pathname.includes('reports/');
  // checks the browser
  const userAgnt = window.navigator.userAgent;
  const isIE = /MSIE|Trident|Edge\//.test(userAgnt);

  return (
    <div className={className}>
      <Confirm
        show={showConfirm}
        loading={user.loading}
        message="Are you sure you want to sign out?"
        subHeading={null}
        onConfirm={handleLogOut}
        onCancel={() => setShowConfirm(false)}
      />
      {/* //====================M002=====[==================== */}
      {/* //====================M003=====[==================== */}
      <div className='power-by-header'>
        <a href='https://spyder-crm.com'
          target="_blank" >
          Powered by&nbsp;
          <Image src={PoweredBy} alt="Spyder" className="logo" style={{
            width: 50,
            marginBottom: '1px'
          }} />
        </a>
      </div>
      {/* //====================M003=====[==================== */}
      {userInfo ? (
        <div className="user-profile-icon" onClick={() => history.push('/settings')}>
          <h2 className="user-profile-name"> {getUsername(currentUser.data)} </h2>
        </div>) :
        <></>
      }
      {/* //====================M002=====[==================== */}

      <Nav className="custom-gear-menu">
        {userInfo ? (
          <>
            <NavItem eventKey={1} onClick={() => setShowConfirm(true)}>
              Sign Out
            </NavItem>
            {/* //====================M001=====[==================== */}
            <a
              href="https://www.facebook.com/AletheiaCapital"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image alt="FacebookIcon" src={FacebookIcon} className="LoginSocialButtons" />
            </a>
            <a href="https://twitter.com/AletheiaCapital" target="_blank" rel="noopener noreferrer">
              <Image alt="TwitterIcon" src={TwitterIcon} className="LoginSocialButtons" />
            </a>
            <a
              href="https://www.linkedin.com/company/aletheiacapital"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image alt="LinkedInIcon" src={LinkedInIcon} className="LoginSocialButtons" />
            </a>

            {/* //====================M001=====[==================== */}
          </>
        ) : (
          <NavItem eventKey={1} className="login-mobile-nav">
            <Link to="/login" href="/login" style={{ paddingRight: 10 }}>
              Login
            </Link>
            <Link to="/signup" href="/signup">
              Sign Up
            </Link>
          </NavItem>
        )}
      </Nav>
    </div>
  );
};

const mapStateToProps = ({
  user,
  user: { currentUser },
}) => ({ user, currentUser });

const mapDispatchToProps = dispatch => ({
  logOut: bindActionCreators(userActions.logOut, dispatch),
});

Component.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  currentUser: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
};

Component.defaultProps = {
  className: '',
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
