/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
  M002 : 14 August 2020 : Nicho
	    * M-0002968: Homepage - Our Research section redesign
	M001 : 20 July 2020 : Aman
		* M-0002937: Homepage failed to load if reports are missing sectors.
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
//====================M002==========================
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Image, Glyphicon } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import Swiper from 'react-id-swiper';
import 'react-id-swiper/src/styles/scss/swiper.scss';
//====================M002==========================

import * as reportActions from '../../../../actionCreators/Research';

import { getStandardDateTime } from '../../../../utils/dateTime';
import { sanitizeText } from '../../../../utils';
import { doGet } from '../../../../utils/request';
//====================M002==========================
import { StratIcons } from './StratIcon';
import { swiperOptions } from './config/Swiper';
import SMTImageMapping from '../../../ReportViewer/components/SocialMediaTilesSectorImageMapping.js'; 

//====================M002==========================
class Container extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onClickLink = this.onClickLink.bind(this);
    this.getReportInfo = this.getReportInfo.bind(this);
    //====================M002==========================
    this.swiper = null;
    //====================M002==========================
  }

  componentDidMount() {
    const { homePageLatestResearches, getHomePageLatest } = this.props;
    if (!homePageLatestResearches.loading) {
      getHomePageLatest();
    }
  }

  onClickLink(item) {
    const windowNew = window.open();
    this.getReportInfo(item.id)
      .then(path => {
        windowNew.location = path;
      })
      .catch(() => {
        windowNew.close();
        const history = this.props.history;
        history.push(`/reports/${item.id}_${sanitizeText(item.title)}`);
      });
  }

  async getReportInfo(id) {
    const response = await doGet(`/reports/${id}`);
    return response.data.report.path;
  }

  checkIfSafariOrFirefox = () => {
    // Detecting Browser by duck typing
    let isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
    if (isSafari) {
      if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
        isSafari = false;
      }
    }
    const isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox || isSafari) {
      return true;
    }
    return false;
  };

  renderBackgroundClass = () => {
    return `research-tile swiper-background`;
  };

  renderBackgroundImage = (id) => {
    var imgSrc;
    imgSrc = SMTImageMapping[id] ? SMTImageMapping[id].src : SMTImageMapping['default'].src ;
    return {
      background: `url(${imgSrc}) 0% 0% / cover rgba(0, 0, 0, 0.3)`,
      resizeMode: 'cover'
    }
  }

  renderStractIcon = sectors => {
    var returnEle = null;
    StratIcons.map(IconProps => {
      if (IconProps.alt === sectors[0].sName) {
        returnEle = (
          <Link to={IconProps.sector} href={IconProps.sector}>
            <Image alt={IconProps.alt} src={IconProps.src} className="swiper-icon" />
          </Link>
        );
      }
    });
    if (returnEle == null) {
      return (
        <Link
          to={`/sectors/${sectors[0] ? sectors[0].id : null}`}
          href={`/sectors/${sectors[0] ? sectors[0].id : null}`}
        >
          <Image alt={StratIcons[0].alt} src={StratIcons[0].src} className="swiper-icon" />
        </Link>
      );
    }
    return returnEle;
  };

  render() {
    const { homePageLatestResearches } = this.props;
    let reports = [];
    let isSafariOrFirefox = this.checkIfSafariOrFirefox();
    const { currentUser } = this.props;

    if (homePageLatestResearches.data.length) {
      reports = homePageLatestResearches.data.slice(0, 25);
    }

    if (homePageLatestResearches.data.length === 0) {
      return (
        <Row className="text-center">
          <div>
            <Col xs={12} sm={2} md={2} className="research-tile">
              <Skeleton />
            </Col>
          </div>
        </Row>
      );
    }

    return (
      //====================M002==========================
      <div className="research-container">
        <div className="our-research-section">
          <div className="our-research-bar">
            <Row>
              <Col xs={6} sm={4} lg={3} className="center">
                <Link to="/home" href="/home">
                  <h1 className="section-title section-title-research">Our Research</h1>
                </Link>
              </Col>
              <Col xs={3} sm={5} lg={7} className="center">
                <span className="title-bar"></span>
              </Col>
              <Col xs={1} sm={1} className="center icon-left">
                <Glyphicon
                  glyph="glyphicon glyphicon-menu-left"
                  className="slider-left"
                  onClick={() => {
                    if (this.swiper !== null) {
                      this.swiper.slidePrev();
                    }
                  }}
                />
              </Col>
              <Col xs={1} sm={1} className="center icon-right">
                <Glyphicon
                  glyph="glyphicon glyphicon-menu-right"
                  className="slider-right"
                  onClick={() => {
                    if (this.swiper !== null) {
                      this.swiper.slideNext();
                    }
                  }}
                />
              </Col>
            </Row>
          </div>
          <Swiper
            {...swiperOptions}
            ref={node => {
              if (node) {
                this.swiper = node.swiper;
              }
            }}
          >
            {reports
              //====================M001=====[====================
              .filter(reports => reports.sectors)
              //====================M001=====[====================
              .map((report, index) => {
                return (
                  <div className={this.renderBackgroundClass()} style={this.renderBackgroundImage(report.sectors[0].id)}>
                    {/* {this.renderStractIcon(report.sectors)} */}
                    <Link
                      to={`/sectors/${
                        report.sectors[0] ? report.sectors[0].id : null
                      }_${sanitizeText(
                        //====================M001=====[====================
                        report.sectors[0] ? report.sectors[0].sName && report.sectors[0].sName : null
                        //====================M001=====[====================
                      )}`}
                      href={`/sectors/${
                        report.sectors[0] ? report.sectors[0].id : null
                      }_${sanitizeText(
                        report.sectors[0] ? report.sectors[0].sName && report.sectors[0].sName : null
                      )}`}
                    >
                      <p className="research-tile-sector">
                        {report.sectors[0] && report.sectors[0].sName && report.sectors[0].sName}
                      </p>
                    </Link>
                    {!currentUser.data || !isSafariOrFirefox ? (
                      <Link
                        href={
                          report.video
                            ? `/connect/videos/${report.video}`
                            : `/reports/${report.id}_${sanitizeText(report.title)}`
                        }
                        to={
                          report.video
                            ? `/connect/videos/${report.video}`
                            : `/reports/${report.id}_${sanitizeText(report.title)}`
                        }
                      >
                        <h1 className="research-tile-title">{report.title}</h1>
                      </Link>
                    ) : (
                      <Link
                        href={
                          report.video
                            ? `/connect/videos/${report.video}`
                            : `/reports/${report.id}_${sanitizeText(report.title)}`
                        }
                        to={
                          report.video
                            ? `/connect/videos/${report.video}`
                            : `/reports/${report.id}_${sanitizeText(report.title)}`
                        }
                      >
                        <h1 className="research-tile-title">{report.title}</h1>
                      </Link>
                    )}

                    {report.companies[0] ? (
                      <p className="research-subtitle">
                        <Link
                          to={`/companies/${report.companies[0].id}`}
                          className="ticker-company"
                        >
                          {report.companies[0].display || report.companies[0].name}
                        </Link>
                      </p>
                    ) : (
                      <p className="research-subtitle">{report.subtitle}</p>
                    )}
                    {report.analysts[0] ? (
                      <Link
                        to={`/analysts/${
                          report.analysts[0] ? report.analysts[0].id : null
                        }_${sanitizeText(report.analysts[0].name)}`}
                        href={`/analysts/${report.analysts[0] ? report.analysts[0].id : null}`}
                      >
                        <p className="research-tile-analyst">
                          {report.analysts[0] && report.analysts[0].name}
                        </p>
                      </Link>
                    ) : (
                      <></>
                    )}
                    <p className="research-tile-date">
                      {report.published_date &&
                        getStandardDateTime(report.published_date, { part: 'date' })}
                    </p>
                  </div>
                );
              })}
          </Swiper>
          {/*====================M002==========================*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ research: { homePageLatestResearches }, user: { currentUser } }) => ({
  homePageLatestResearches,
  currentUser,
});

const mapDispatchToProps = dispatch => ({
  getHomePageLatest: bindActionCreators(reportActions.getHomePageLatest, dispatch),
});

Container.propTypes = {
  homePageLatestResearches: PropTypes.object.isRequired,
  getHomePageLatest: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
