import React from 'react';
import { Col,Image } from 'react-bootstrap';

import GlobalStratIcon from '../../../assets/images/1a-global-strat.svg';
import AsiaMacroIcon from '../../../assets/images/2a-asia-macro.svg';
import AsiaStratIcon from '../../../assets/images/3a-asia-strat.svg';
import AsiaEqIcon from '../../../assets/images/4a-asia-eq-strat.svg';
import TechIcon from '../../../assets/images/5a-tech-strat.svg';
import ChinaStratIcon from '../../../assets/images/6a-china-strat.svg';
import EnergyIcon from '../../../assets/images/7a-energy.svg';
import FinaIcon from '../../../assets/images/8a-financials.svg';
import InternetIcon from '../../../assets/images/9a-internet.svg';
import HardwareIcon from '../../../assets/images/10a-hardware.svg';
import EcomIcon from '../../../assets/images/11a-ecom-log.svg';
import RoundPieIcon from '../../../assets/images/12a-360.svg';
import { Link } from 'react-router-dom';

const StratIcons = [
    { src: GlobalStratIcon, alt: 'Global Strategy', sector: '/sectors/8_global_strategy'},
    { src: AsiaEqIcon, alt: 'Asia Equity Strategy', sector: '/sectors/6_asia_equity_strategy'},
    { src: AsiaMacroIcon, alt: 'Asianomics Macro', sector: '/sectors/1_asianomics_macro'},
    { src: AsiaStratIcon, alt: 'Asianomics Strategy', sector: '/sectors/5_asianomics_strategy'},
    { src: TechIcon, alt: 'Tech Thematic Strategy', sector: '/sectors/4_tech_thematic_strategy'},
    { src: EnergyIcon, alt: 'Energy', sector: '/sectors/7_energy'},
    { src: FinaIcon, alt: 'Financials', sector: '/sectors/2_financials'},
    { src: InternetIcon, alt: 'Internet', sector: '/sectors/10_internet'},
    { src: HardwareIcon, alt: 'Technology Hardware', sector: '/sectors/3_technology_hardware'},
    { src: EcomIcon, alt: 'Ecommerce Logistics', sector: '/sectors/11_ecommerce_logistics'},
    { src: RoundPieIcon, alt: 'Aletheia 360', sector: '/sectors/13_aletheia_360'},
    { src: ChinaStratIcon, alt: 'China Strategy', sector: '/sectors/17_china_strategy'}
];

const Container = () => (
    <Col xs={12} sm={12} md={12}>
        <div className="vmv-icons-section">
            {StratIcons.map(IconProps => (
              <Link to={IconProps.sector} href={IconProps.sector}>
                <div className="vmv-tooltip">
                    <Image
                        alt={IconProps.alt}
                        src={IconProps.src}
                        style={{ height: 100, marginTop: 10, marginLeft: 15, marginRight: 15, marginBottom: 10, zIndex: 2 }}
                    />
                    <span className="vmv-tooltip-text">{IconProps.alt}</span>
                </div>
              </Link>
            ))}
        </div>
        <div className="vision-bg">Vision</div>
        <div className="mission-bg">Mission</div>
        <div className="values-bg">Values</div>
    </Col>
);

export default Container;