import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import AccessServices from '../../../assets/images/Access-services.png';
import Picture1 from '../../../assets/images/Picture1.png';
import Picture2 from '../../../assets/images/Picture2.png';
import Picture3 from '../../../assets/images/Picture3.png';
import Picture4 from '../../../assets/images/Picture4.png';

const Section4 = () => {
  return (
    <div style={{ marginBottom: '50px' }}>
      <Stack justifyContent="center" alignItems="center">
        <div style={{ width: '65%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Stack className="section2-item" justifyContent="center" alignItems="center">
                <Typography fontSize={16} style={{ textAlign: 'center' }}>
                  Company Valuation Reports
                </Typography>
              </Stack>
              <Stack className="section2-item" justifyContent="center" alignItems="center">
                <Typography fontSize={16} style={{ textAlign: 'center' }}>
                  Company Investment Research Reports
                </Typography>
              </Stack>
              <Stack className="section2-item" justifyContent="center" alignItems="center">
                <Typography fontSize={16} style={{ textAlign: 'center' }}>
                  Sector Reports
                </Typography>
              </Stack>
              <Stack className="section2-item" justifyContent="center" alignItems="center">
                <Typography fontSize={16} style={{ textAlign: 'center' }}>
                  Comparative Valuation Reports
                </Typography>
              </Stack>
              <Stack className="section2-item" justifyContent="center" alignItems="center">
                <Typography fontSize={16} style={{ textAlign: 'center' }}>
                  Investor Marketing and Roadshows
                </Typography>
              </Stack>
              <Stack className="section2-item" justifyContent="center" alignItems="center">
                <Typography fontSize={16} style={{ textAlign: 'center' }}>
                  Investor Feedback and Views
                </Typography>
              </Stack>
              <Stack className="section2-item" justifyContent="center" alignItems="center">
                <Typography fontSize={16} style={{ textAlign: 'center' }}>
                  Independent Advisory on Capital Market actions
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Stack
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: '50px', marginBottom: '50px' }}
              >
                <Typography
                  fontSize={26}
                  color="#036ca6"
                  style={{ fontFamily: 'open_sanssemibold' }}
                >
                  Sample Reports
                </Typography>
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <a
                    href="https://www.aletheia-capital.com/reports/S-17705_proxy_for_indonesian_fintech_revolution"
                    target="_blank"
                  >
                    <img src={Picture1} style={{ maxWidth: '100%' }} alt="" />
                  </a>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <a
                    href="https://www.aletheia-capital.com/reports/S-15725_private_credit_sector_report"
                    target="_blank"
                  >
                    <img src={Picture2} style={{ maxWidth: '100%', marginTop: '100px' }} alt="" />
                  </a>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <a
                    href="https://www.aletheia-capital.com/reports/S-16016_revolutionizing_payments"
                    target="_blank"
                  >
                    <img src={Picture3} style={{ maxWidth: '100%' }} alt="" />
                  </a>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <a
                    href="https://www.aletheia-capital.com/reports/S-19159_powering_southeast_asia_s_personal_finance_boom"
                    target="_blank"
                  >
                    <img src={Picture4} style={{ maxWidth: '100%', marginTop: '100px' }} alt="" />
                  </a>
                </Grid>
              </Grid>
              {/* <Stack justifyContent="center" alignItems="center">
                <img src={AccessServices} style={{ maxWidth: '100%' }} alt="" />
              </Stack> */}
            </Grid>
          </Grid>
        </div>
      </Stack>
    </div>
  );
};

export default Section4;
