/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import CompanyCard from './companyCard'

const Component = ({ data, style, loading }) => {
  if (loading || !data.length) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  if (data) {
    return (
        <div>
            {
                data.map((item, i) => (
                    <CompanyCard data={item} ></CompanyCard>
                ))
            }
        </div>
    )
  }
};

Component.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  style: PropTypes.object,
};

Component.defaultProps = {
  data: {},
  loading: false,
  style: {},
};

export default Component;
