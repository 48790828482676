import React from 'react';
import { Modal, Row, Button } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { doPost } from '../utils/request';

const EditBiography = ({ showModal, setShowModal, onSave, description }) => {
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const onEditorStateChange = editorState => {
    setEditorState(editorState);
  };
  const uploadCallback = image => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append('file', image);
      doPost('/posts/uploadImage', data)
        .then(response => resolve({ data: { link: response.data && response.data.path } }))
        .catch(err => reject(err));
    });
  };

  React.useEffect(() => {
    if (description) {
      setEditorState(EditorState.createWithContent(stateFromHTML(description)));
    }
  }, [description]);

  const onSaveHandler = event => {
    event.preventDefault();
    const data = stateToHTML(editorState.getCurrentContent());
    onSave(data);
  };
  return (
    <>
      <Modal
        show={showModal}
        dialogClassName="social-media-modal"
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Biography</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ padding: '20px' }}>
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              stripPastedStyles={true}
              toolbar={{
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  previewImage: true,
                  uploadCallback: uploadCallback,
                  alignmentEnabled: true,
                  defaultSize: {
                    height: 'auto',
                    width: '400px',
                  },
                },
                colorPicker: {
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  colors: [
                    'rgb(19,108,167)',
                    'rgb(26,188,156)',
                    'rgb(84,172,210)',
                    'rgb(44,130,201)',
                    'rgb(147,101,184)',
                    'rgb(71,85,119)',
                    'rgb(204,204,204)',
                    'rgb(65,168,95)',
                    'rgb(0,168,133)',
                    'rgb(61,142,185)',
                    'rgb(41,105,176)',
                    'rgb(85,57,130)',
                    'rgb(40,50,78)',
                    'rgb(0,0,0)',
                    'rgb(247,218,100)',
                    'rgb(251,160,38)',
                    'rgb(235,107,86)',
                    'rgb(226,80,65)',
                    'rgb(163,143,132)',
                    'rgb(239,239,239)',
                    'rgb(255,255,255)',
                    'rgb(250,197,28)',
                    'rgb(243,121,52)',
                    'rgb(209,72,65)',
                    'rgb(184,49,47)',
                    'rgb(124,112,107)',
                    'rgb(209,213,216)',
                  ],
                },
                emoji: false,
              }}
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
            >
              <textarea
                disabled
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
              />
            </Editor>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="save-button">
            <Button bsStyle="primary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button bsStyle="primary" onClick={onSaveHandler}>
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditBiography;
