import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Button, Form } from 'react-bootstrap';
import RichTextEditor from 'react-rte';

import FormField from '../../../components/FormFields';
import { emailPolicy, fullNamePolicy, alphaSpecial, numSpecial } from '../../../config/validations';

const validate = values => {
  const errors = {};

  if (values.password && values.confirmPassword) {
    if (
      values.password.length !== values.confirmPassword.length ||
      values.password !== values.confirmPassword
    ) {
      errors.confirmPassword = 'Create password and confirm password do not match';
    }
  }
  return errors;
};

export const Component = ({
  className,
  loading,
  handleSubmit,
  handleCancel,
  disableSubmit,
  initialValues,
}) => {
  let { description } = initialValues;
  if (!description) {
    description = '<p><br></p>';
  }
  const [editorState, setEditorState] = React.useState(
    RichTextEditor.createValueFromString(description && description, 'html')
  );

  const onTextChange = value => {
    setEditorState(value);
  };
  return (
    <Form
      className={className}
      onSubmit={e => {
        e.preventDefault();

        const obj = {
          description: editorState.toString('html'),
        };
        const formData = new FormData(e.target);

        for (const [key, value] of formData.entries()) {
          obj[key] = value;
        }

        handleSubmit(obj);
      }}
    >
      <Field
        name="name"
        validate={fullNamePolicy}
        type="text"
        component={FormField}
        label="Name"
        normalize={alphaSpecial}
        disabled={loading}
        required
      />
      <Field
        name="designation"
        type="text"
        component={FormField}
        label="Designation"
        disabled={loading}
      />
      <Field
        name="email"
        type="text"
        validate={emailPolicy}
        component={FormField}
        label="Email"
        disabled={loading}
      />
      <Field
        name="phone"
        type="text"
        component={FormField}
        label="Phone"
        normalize={numSpecial}
        disabled={loading}
      />
      <Field name="country" type="text" component={FormField} label="Country" disabled={loading} />
      {/* <Field
        name="description"
        type="textarea"
        component={FormField}
        label="Description"
        disabled={loading}
      /> */}
      <RichTextEditor name="description" value={editorState} onChange={onTextChange} />
      <div className="edit-profile-buttons">
        <Button
          type="submit"
          bsStyle="primary"
          className="btn-block update"
          disabled={disableSubmit || loading}
        >
          Update
        </Button>
        <Button
          bsStyle="primary"
          className="btn-block"
          disabled={loading}
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};

Component.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
};

Component.defaultProps = {
  className: null,
  loading: false,
  disableSubmit: false,
};

export default reduxForm({
  form: 'TeamProfileForm', // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(Component);
