import PostBackgroundimage from '../../../assets/images/reports/background-full/PostBackground.png';

// Second Import
import AgroChemicals from '../../../assets/images/reports/background-full/Agrochemicals.jpg';
import Aletheia360 from '../../../assets/images/reports/background-full/Aletheia-360.jpg';
import AsiaEquityStrategy from '../../../assets/images/reports/background-full/Asia-Equity-Strategy.jpg';
import Asianomics from '../../../assets/images/reports/background-full/Asianomics.jpg';
import Asianomics2 from '../../../assets/images/reports/background-full/Asianomics-2.jpg';
import Automobiles from '../../../assets/images/reports/background-full/Automobiles.jpg';
import Banks from '../../../assets/images/reports/background-full/Banks.jpg';
import ChinaStrategy from '../../../assets/images/reports/background-full/China-Strategy.jpg';
import ChinaStrategy2 from '../../../assets/images/reports/background-full/China-Strategy-2.jpg';
import ConsumerDiscretionary from '../../../assets/images/reports/background-full/Consumer-Discretionary.jpg';
import ConsumerDiscretionary2 from '../../../assets/images/reports/background-full/Consumer-Discretionary.jpg';
import ConsumerStaples from '../../../assets/images/reports/background-full/Consumer-Staples.jpg';
import ConsumerStaples2 from '../../../assets/images/reports/background-full/Consumer-Staples-2.jpg';
import Energy from '../../../assets/images/reports/background-full/Energy.jpg';
import Energy2 from '../../../assets/images/reports/background-full/Energy-2.jpg';
import Financials from '../../../assets/images/reports/background-full/Financials.jpg';
import Financials2 from '../../../assets/images/reports/background-full/Financials-2.jpg';
import Fintech from '../../../assets/images/reports/background-full/Fintech.jpg';
import GlobalStrategy from '../../../assets/images/reports/background-full/Global-Strategy.jpg';
import GlobalStrategy2 from '../../../assets/images/reports/background-full/Global-Strategy-2.jpg';
import HealthCare from '../../../assets/images/reports/background-full/Health-Care.jpg';
import HealthCare2 from '../../../assets/images/reports/background-full/Health-Care-2.jpg';
import IndiaStrategy from '../../../assets/images/reports/background-full/India-Strategy.jpg';
import Industrials from '../../../assets/images/reports/background-full/Industrials.jpg';
import Industrials2 from '../../../assets/images/reports/background-full/Industrials-2.jpg';
import Internet from '../../../assets/images/reports/background-full/Internet.jpg';
import ItServices from '../../../assets/images/reports/background-full/IT-Services-&-Software.jpg';
import ItServices2 from '../../../assets/images/reports/background-full/IT-Services-&-Software-2.jpg';
import ItServices3 from '../../../assets/images/reports/background-full/IT-Services-&-Software-3.jpg';
import Materials from '../../../assets/images/reports/background-full/Materials.jpg';
import Media from '../../../assets/images/reports/background-full/Media.jpg';
import RealEstate from '../../../assets/images/reports/background-full/Real-Estate.jpg';
import TechThematicStrategy from '../../../assets/images/reports/background-full/Tech-Thematic-Strategy.jpg';
import TechThematicStrategy2 from '../../../assets/images/reports/background-full/Tech-Thematic-Strategy-2.jpg';
import TechnologyHardware from '../../../assets/images/reports/background-full/Technology-Hardware.jpg';
import TechnologyHardware2 from '../../../assets/images/reports/background-full/Technology-Hardware-2.jpg';
import Telecoms from '../../../assets/images/reports/background-full/Telecoms.jpg';
import Telecoms2 from '../../../assets/images/reports/background-full/Telecoms-2.jpg';
import ThailandStrategy from '../../../assets/images/reports/background-full/Thailand-Strategy.jpg';


export default 
{
  10000161: {
    src: AgroChemicals,
    title: 'AgroChemicals'
  },
  10000138: {
    src: Aletheia360,
    title: 'Aletheia 360'
  },
  10000047: {
    src: AsiaEquityStrategy,
    title: 'Asia Equity Strategy'
  },
  10000133: {
    src: Asianomics,
    title: 'Asianomics'
  },
  100001332: {
    src: Asianomics2,
    title: 'Asianomics-2'
  },
  10000168: {
    src: Automobiles,
    title: 'Automobiles'
  },
  10000171: {
    src: Banks,
    title: 'Banks'
  },
  10000142: {
    src: ChinaStrategy,
    title: 'China Strategy'
  },
  100001422: {
    src: ChinaStrategy2,
    title: 'China Strategy-2'
  },
  10000150:{
    src: ConsumerDiscretionary,
    title: 'Consumer Discretionary'
  },
  100001502:{
    src: ConsumerDiscretionary2,
    title: 'Consumer Discretionary-2'
  },
  10000152:{
    src: ConsumerStaples,
    title: 'Consumer Staples'
  },
  100001522:{
    src: ConsumerStaples2,
    title: 'Consumer Staples-2'
  },
  10000136:{
    src: Energy,
    title: 'Energy'
  },
  100001362:{
    src: Energy2,
    title: 'Energy-2'
  },
  10000137:{
    src: Financials,
    title: 'Financials'
  },
  100001372:{
    src: Financials2,
    title: 'Fintech'
  },
  10000181:{
    src: Fintech,
    title: 'Fintech'
  },
  10000131:{
    src: GlobalStrategy,
    title: 'Global Strategy'
  },
  100001312:{
    src: GlobalStrategy2,
    title: 'Global Strategy-2'
  },
  10000151:{
    src: HealthCare,
    title: 'Health Care'
  },
  100001512:{
    src: HealthCare2,
    title: 'Health Care-2'
  },
  100001512:{
    src: HealthCare2,
    title: 'Health Care-2'
  },
  10000147: {
    src: IndiaStrategy,
    title: 'India Strategy'
  },
  10000149: {
    src: Industrials,
    title: 'Industrials'
  },
  100001492: {
    src: Industrials2,
    title: 'Industrials-2'
  },
  10000132: {
    src: Internet,
    title: 'Internet'
  },
  10000153: {
    src: ItServices,
    title: 'IT Services'
  },
  100001532: {
    src: ItServices2,
    title: 'IT Services-2'
  },
  100001533: {
    src: ItServices3,
    title: 'IT Services-3'
  },
  10000148: {
    src: Materials,
    title: 'Materials'
  },
  10000154: {
    src: Media,
    title: 'Media'
  },
  10000156: {
    src: RealEstate,
    title: 'Real Estate'
  },
  10000087: {
    src: TechThematicStrategy,
    title: 'Tech Thematic Strategy'
  },
  100000872: {
    src: TechThematicStrategy2,
    title: 'Tech Thematic Strategy-2'
  },
  10000126: {
    src: TechnologyHardware,
    title: 'Technology Hardware'
  },
  100001262: {
    src: TechnologyHardware2,
    title: 'Technology Hardware-2'
  },
  10000140: {
    src: Telecoms,
    title: 'Telecoms'
  },
  100001402: {
    src: Telecoms2,
    title: 'Telecoms-2'
  },
  10000176: {
    src: ThailandStrategy,
    title: 'Thailand Strategy'
  },
  default: {
    src: PostBackgroundimage,
    title: 'Default'
  },
}
