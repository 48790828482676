/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 13 July 2021 : Aman
    * M-0003467: Fine-tuning of social media tiles from selected PDF text or Blog text
**************************************************************************************************************
*/

import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  Col,
  ControlLabel,
  Checkbox,
} from 'react-bootstrap';
import SMTImageMapping from './SocialMediaTilesSectorImageMapping.js';
import { Stage, Circle, Layer, Text, Image, Transformer, Rect } from 'react-konva';
import useImage from 'use-image';
import QRCode from 'qrcode.react';

const SocialMediaForm = ({ showModal, setShowModal, selectedText, research }) => {
  const [subtitle, setSubtitle] = React.useState('');
  const [socialMediaText, setSocialMediaText] = React.useState('');
  const [reportTitle, setReportTitle] = React.useState('');
  const [sectorText, setSectorText] = React.useState('');
  const [selectedTextFrame, setSelectedTextFrame] = React.useState(false);
  const [draggable, setDraggable] = React.useState(false);
  const [qrLink, setQRLink] = React.useState('');
  const [showQRCode, setShowQRCode] = React.useState(true);
  const [backgroundImageId, setBackgroundImageId] = React.useState(0);
  const qrRef = useRef();
  // ===============M001========[========

  const BackgroundImage = (obj) => {
    var imgSrc;
    imgSrc = SMTImageMapping[obj.backgroundImageId] ? SMTImageMapping[obj.backgroundImageId].src : SMTImageMapping['default'].src ;
    const [backgroundConst] = useImage(imgSrc, 'anonymous');
    return <Image image={backgroundConst} width={1024} height={708} />;
  };

  const AuthorImage = () => {
    const [authorConst] = useImage(research.data.analysts.length? research.data.analysts[0].image_path: '', 'anonymous');
    return (
      <Image
        image={authorConst}
        crop={'center-middle'}
        width={75}
        height={75}
        x={60}
        y={570}
        draggable={draggable}
      />
    );
  };

  const ReportQRCode = () => {
    const qrCanvas = qrRef.current.querySelectorAll('canvas')[0];
    const canvasDataUrl = qrCanvas.toDataURL('image/png');
    const [qrImage] = useImage(canvasDataUrl, 'anonymous');
    const reportLink = qrRef.current.querySelectorAll('canvas')[0].baseURI;
    setQRLink(reportLink);
    return (
      <Image
        image={qrImage}
        crop={'center-middle'}
        width={75}
        height={75}
        x={900}
        y={570}
        draggable={draggable}
      />
    );
  };

  const ResizeableText = ({
    shapeProps,
    isSelected,
    x,
    y,
    fontSize,
    fontFamily,
    text,
    defaultWidth,
  }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
      if (isSelected) {
        // we need to attach transformer manually
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer().batchDraw();
      }
    }, [isSelected]);

    return (
      <React.Fragment>
        <Text
          width={defaultWidth}
          x={x}
          y={y}
          fontSize={fontSize}
          fontFamily={fontFamily}
          text={text}
          fill={'white'}
          ref={shapeRef}
          {...shapeProps}
          draggable={draggable}
          onTransformEnd={e => {
            const node = shapeRef.current;
            node.width(Math.max(5, node.width() * node.scaleX()));
          }}
        ></Text>
        {isSelected && (
          <Transformer
            padding={5}
            ref={trRef}
            boundBoxFunc={(oldBox, newBox) => {
              if (newBox.width < 20) {
                return oldBox;
              }
              return newBox;
            }}
          />
        )}
      </React.Fragment>
    );
  };

  const changeSubtitle = event => {
    setSubtitle(event.target.value);
  };

  const renderImage = () => {
    var canvasArr = document.querySelectorAll('canvas');
    var ctx;
    for (var i = 0; i < canvasArr.length; i++) {
      if (canvasArr[i].className == '') {
        ctx = canvasArr[i];
      }
    }
    var image = ctx.toDataURL();
    var aDownloadLink = document.createElement('a');
    aDownloadLink.download = research.data.title.toUpperCase() + '.png';
    aDownloadLink.href = image;
    aDownloadLink.click();
  };

  React.useEffect(() => {
    setSocialMediaText(selectedText.replaceAll('  ', ' '));
    setReportTitle(research.data.title.toUpperCase());
    setSectorText(research.data.sectors[0].sName.toUpperCase());
    setBackgroundImageId(research.data.sectors[0].id)
  }, [selectedText]);

  return (
    <>
      <Modal
        show={showModal}
        dialogClassName="social-media-modal"
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Social Media Tiles</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '60px', textAlign: 'center' }}>
          <div>
            {/* FORM CONTROL */}
            <div style={{ paddingBottom: 70 }}>
              <Col xs={2} style={{ textAlign: 'center' }}>
                <ControlLabel style={{ paddingTop: 8 }}>Title: </ControlLabel>
              </Col>
              <Col xs={10}>
                <FormGroup>
                  <FormControl
                    defaultValue={reportTitle}
                    type="text"
                    onKeyUp={e => setReportTitle(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </div>
            <div style={{ paddingBottom: 70 }}>
              <Col xs={2} style={{ textAlign: 'center' }}>
                <ControlLabel style={{ paddingTop: 8 }}>Sector Name: </ControlLabel>
              </Col>
              <Col xs={10}>
                <FormGroup>
                  <FormControl
                    defaultValue={sectorText}
                    type="text"
                    onKeyUp={e => setSectorText(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </div>
            <div style={{ paddingBottom: 70 }}>
              <Col xs={2} style={{ textAlign: 'center' }}>
                <ControlLabel style={{ paddingTop: 8 }}>Sub-Title: </ControlLabel>
              </Col>
              <Col xs={10}>
                <FormGroup>
                  <FormControl
                    type="text"
                    placeholder={'Sub-Title'}
                    onKeyUp={e => changeSubtitle(e)}
                  />
                </FormGroup>
              </Col>
            </div>
            <div style={{ paddingBottom: 70 }}>
              <Col xs={2} style={{ textAlign: 'center' }}>
                <ControlLabel style={{ paddingTop: 8 }}>Selected Text: </ControlLabel>
              </Col>
              <Col xs={10}>
                <FormGroup>
                  <FormControl
                    componentClass="textarea"
                    type="textarea"
                    placeholder={'Email'}
                    defaultValue={socialMediaText}
                    onKeyUp={e => setSocialMediaText(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </div>
            <div style={{ paddingBottom: 70 }}>
              <Col xs={2} style={{ textAlign: 'center' }}>
                <ControlLabel style={{ paddingTop: 8 }}>Background Image: </ControlLabel>
              </Col>
              <Col xs={10}>
                <FormGroup>
                  <FormControl
                    componentClass="select"
                    onChange={(e)=>{
                      setBackgroundImageId(e.target.value);
                    }}
                  >
                     {Object.entries(SMTImageMapping).map(([key,value]) => <option value={key}>{value.title}</option>)}
                  </FormControl>
                </FormGroup>
              </Col>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}
            >
              <Checkbox
                defaultValue={draggable}
                onClick={() => {
                  setDraggable(!draggable);
                }}
                style={{ padding: '0 5px 0 5px' }}
              >
                Enable Text Reposistioning (Click and Drag)
              </Checkbox>
              <Checkbox
                defaultValue={showQRCode}
                checked={showQRCode}
                onClick={() => {
                  setShowQRCode(!showQRCode);
                }}
                style={{ padding: '0 5px 0 5px' }}
              >
                Add Report QR Code
              </Checkbox>
            </div>
            <div style={{ paddingBottom: 20 }}>
              <Button onClick={renderImage}>Save As Image</Button>
            </div>
            {/* FORM CONTROL */}
            <div style={{ paddingBottom: 20, display: 'none' }} ref={qrRef}>
              <QRCode value={qrLink} includeMargin={true} fgColor="#31446C" />
            </div>

            {/* SOCIAL MEDIA TILES CANVAS */}
            <div style={{ fontFamily: 'open_sanssemibold open_sansregular' }}>
              <Stage width={1024} height={708}>
                <Layer>
                  {/* Background Image */}
                  <BackgroundImage backgroundImageId={backgroundImageId}/>
                  {/* Background Image */}
                  {/* Sector Text */}
                  <Text
                    fontSize={research.data.sectors[0].sName.length > 16 ? 30 : 40}
                    draggable={draggable}
                    fontFamily={'open_sanssemibold'}
                    fill={'white'}
                    text={sectorText}
                    x={research.data.sectors[0].sName.length > 16 ? 620 : 720}
                    y={100}
                  />
                  {/* Sector Text */}
                  {/* Report Title */}
                  <ResizeableText
                    fontSize={40}
                    fontFamily={'open_sanssemibold'}
                    fill={'white'}
                    text={reportTitle}
                    x={60}
                    y={240}
                    isSelected={selectedTextFrame}
                    defaultWidth={800}
                  />
                  {/* Report Title */}
                  {/* Rect For Sub-Title */}
                  <Rect
                    draggable={draggable}
                    x={0}
                    y={360}
                    width={
                      subtitle.length < 10
                        ? subtitle.length * 35
                        : subtitle.length < 13
                        ? subtitle.length * 26
                        : subtitle.length * 23
                    }
                    height={50}
                    fillLinearGradientColorStops={[0, '#31446c', 1, '#c62332']}
                    fillLinearGradientStartPointX={0}
                    fillLinearGradientStartPointY={0}
                    fillLinearGradientEndPointX={
                      subtitle.length < 10
                        ? subtitle.length * 35
                        : subtitle.length < 13
                        ? subtitle.length * 26
                        : subtitle.length * 23
                    }
                    fillLinearGradientEndPointY={0}
                    shadowBlur={5}
                  />
                  {/* Rect For Sub-Title */}
                  {/* Sub-Title */}
                  <Text
                    fontSize={30}
                    draggable={draggable}
                    fontFamily={'open_sanssemibold'}
                    fill={'white'}
                    text={subtitle}
                    x={60}
                    y={370}
                  />
                  {/* Sub-Title */}
                  {/* Selected Text */}
                  <ResizeableText
                    fontSize={20}
                    fill={'white'}
                    text={socialMediaText}
                    x={60}
                    y={430}
                    isSelected={selectedTextFrame}
                    defaultWidth={900}
                  />
                  {/* Selected Text */}
                  {/* Author Details */}
                  <AuthorImage />
                  {/* Author Text */}
                  <Text
                    fontSize={20}
                    draggable={draggable}
                    fontFamily={'open_sanssemibold'}
                    fill={'white'}
                    text={research.data.analysts.length ? 'By: ' + research.data.analysts[0].name: ""}
                    x={140}
                    y={600}
                  />
                  {/* Author Text */}
                  {/* Author Details */}
                  {/* QR Code */}
                  {showQRCode ? <ReportQRCode /> : null}
                  {/* QR Code */}
                </Layer>
              </Stage>
            </div>
          </div>
          {/* SOCIAL MEDIA TILES CANVAS */}
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ research: { research } }) => ({
  research,
});

export default withRouter(connect(mapStateToProps)(SocialMediaForm));
