import React from 'react';
import PropTypes from 'prop-types';

import Throbber from './Throbber';

const Box = ({ children, className, loading, loadingText, ...props }) => (
  <div {...props} className={`${className ? `${className} ` : ''}box`}>
    {loading ? <Throbber text={loadingText} /> : children}
  </div>
);

Box.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
};

Box.defaultProps = {
  children: null,
  className: null,
  loading: false,
  loadingText: null,
};

export default Box;
