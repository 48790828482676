/*
**************************************************************************************************************
												R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author	:	Zeeshan
	Date	:	27 March 2023 
**************************************************************************************************************
												Usage Notes
**************************************************************************************************************

**************************************************************************************************************
												Amendments
**************************************************************************************************************
	
**************************************************************************************************************
*/

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoginForm from './components/ResetPassword';
import FormContainer from '../../components/FormContainer';
import * as userActions from '../../actionCreators/User';
import MainLayout from '../../layouts/Main';

const Container = ({ user, history, ResetPassword, location, initialValues, resetState }) => {

  useEffect(() => {
    resetState();
  }, []);

  const { search } = location;
  const urlParams = new URLSearchParams(search);
  let externalUrl = urlParams.get('redirectUrl');
  externalUrl = externalUrl ? `/rfs.htm${externalUrl}` : null;
  const redirectUrl ='/login'
    
  return (
    <div className="login-signup-page">
      <FormContainer>
        <div style={{ paddingTop: 30 }} />
        <LoginForm
          onSubmit={obj => ResetPassword(obj, history, redirectUrl)}
          message={user.loginError}       
          showSignUpMessage
        />
      </FormContainer>
    </div>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = dispatch => ({
  ResetPassword: bindActionCreators(userActions.ResetPassword, dispatch),
  resetState: bindActionCreators(userActions.resetState, dispatch),
});

Container.propTypes = {
  user: PropTypes.object.isRequired,
  initialValues: PropTypes.shape({
    full_name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  logIn: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  resetState: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      redirectUrl: PropTypes.string,
      search: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
