import { all, put, takeLatest } from 'redux-saga/effects';

import {
  getContentSuccess,
  getContentFail,
  updateContentSuccess,
  updateContentFail,
} from '../actionCreators/OurFirm';

import { doGet, doPost } from '../utils/request';

function* getContent({ obj }) {
  try {
    const response = yield doGet(`/content`);
    yield put(getContentSuccess(response.data.content, obj));
  } catch (err) {
    yield put(getContentFail(err.message));
  }
}

function* updateContent({ obj, content }) {
  try {
    const response = yield doPost('/content/update', obj);
    yield put(updateContentSuccess(response.data, obj));
  } catch (err) {
    yield put(updateContentFail(err.message));
  }
}

export default function* watcher() {
  yield all([takeLatest('GET_CONTENT', getContent), takeLatest('UPDATE_CONTENT', updateContent)]);
}
