import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { sanitizeText } from '../../../../../utils';

const Component = ({ report }) => {
  if (report.companies.length == 1) {
    return (
      <>
        <tr>
          <td style={{ padding: '5px 0px 0px 10px' }}>
            <div className="subtitle-box">
              <span style={{ display: 'flex' }}>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  overlay={
                    <Popover id="popover-trigger-hover-focus">
                      {report.companies[0] ? (
                        report.companies.map(company => company.display || company.sName).join(', ')
                      ) : (
                        <>
                          {report.countries[0] ? (
                            report.countries.map(val => val.name).join(', ')
                          ) : (
                            <span>No Geography</span>
                          )}
                        </>
                      )}
                    </Popover>
                  }
                  placement="top"
                >
                  <Link to={`/companies/${report.companies[0].id}`}>
                    <p className="subtitle-left">
                      {report.companies[0].display || report.companies[0].sName}
                    </p>
                  </Link>
                </OverlayTrigger>
                {report.sectors[0] && (
                  <Link
                    to={`/sectors/${report.sectors[0].id}_${sanitizeText(report.sectors[0].sName)}`}
                  >
                    <p className="subtitle-right">{report.sectors[0].sName}</p>
                  </Link>
                )}
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td style={{ padding: '0px 0px 0px 10px' }}>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              overlay={
                <Popover id="popover-trigger-hover-focus">
                  {report.companies[0] ? (
                    report.companies.map(company => company.display || company.sName).join(', ')
                  ) : (
                    <>
                      {report.countries[0] ? (
                        report.countries.map(val => val.name).join(', ')
                      ) : (
                        <span>No Geography</span>
                      )}
                    </>
                  )}
                </Popover>
              }
              placement="top"
            >
              {typeof report.title === 'string' ? (
                <Link
                  href={
                    report.video
                      ? `/connect/videos/${report.video}`
                      : `/reports/${report.id}_${sanitizeText(report.title)}`
                  }
                  to={
                    report.video
                      ? `/connect/videos/${report.video}`
                      : `/reports/${report.id}_${sanitizeText(report.title)}`
                  }
                  className="card-title"
                >
                  {report.title}
                </Link>
              ) : (
                report.title
              )}
            </OverlayTrigger>
          </td>
        </tr>
      </>
    );

  } else if (report.companies.length > 1) {
    return (
      <>
        <tr>
          <td style={{ padding: '5px 0px 0px 10px' }}>
            <div className="subtitle-box">
              <span style={{ display: 'flex' }}>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  overlay={
                    <Popover id="popover-trigger-hover-focus">
                      {report.companies[0] ? (
                        report.companies.map(company => company.display || company.sName).join(', ')
                      ) : (
                        <>
                          {report.countries[0] ? (
                            report.countries.map(val => val.name).join(', ')
                          ) : (
                            <span>No Geography</span>
                          )}
                        </>
                      )}
                    </Popover>
                  }
                  placement="top"
                >
                  <p className="subtitle-left">
                    {report.coverage || report.subtitle || report.publication}
                  </p>
                </OverlayTrigger>
                {report.sectors[0] && (
                  <Link
                    to={`/sectors/${report.sectors[0].id}_${sanitizeText(report.sectors[0].sName)}`}
                  >
                    <p className="subtitle-right">{report.sectors[0].sName}</p>
                  </Link>
                )}
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td style={{ padding: '0px 0px 0px 10px' }}>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              overlay={
                <Popover id="popover-trigger-hover-focus">
                  {report.companies[0] ? (
                    report.companies.map(company => company.display || company.sName).join(', ')
                  ) : (
                    <>
                      {report.countries[0] ? (
                        report.countries.map(val => val.name).join(', ')
                      ) : (
                        <span>No Geography</span>
                      )}
                    </>
                  )}
                </Popover>
              }
              placement="top"
            >
              {typeof report.title === 'string' ? (
                <Link
                  href={
                    report.video
                      ? `/connect/videos/${report.video}`
                      : `/reports/${report.id}_${sanitizeText(report.title)}`
                  }
                  to={
                    report.video
                      ? `/connect/videos/${report.video}`
                      : `/reports/${report.id}_${sanitizeText(report.title)}`
                  }
                  className="card-title"
                >
                  {report.title}
                </Link>
              ) : (
                report.title
              )}
            </OverlayTrigger>
          </td>
        </tr>
      </>
    );
  }

  if (report.publication && report.publication.trim().toLowerCase() === 'cha-am advisors') {
    return (
      <>
        <tr>
          <td style={{ padding: '5px 0px 0px 10px' }}>
            <div className="subtitle-box">
              <span style={{ display: 'flex' }}>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  overlay={
                    <Popover id="popover-trigger-hover-focus">
                      {report.companies[0] ? (
                        report.companies.map(company => company.display || company.sName).join(', ')
                      ) : (
                        <>
                          {report.countries[0] ? (
                            report.countries.map(val => val.name).join(', ')
                          ) : (
                            <span>No Geography</span>
                          )}
                        </>
                      )}
                    </Popover>
                  }
                  placement="top"
                >
                  <p
                    className="subtitle-left"
                    title={
                      report.companies[0] &&
                      report.companies.map(val => val.display || val.name).join(', ')
                    }
                  >
                    {report.subtitle || "A Strategist's Diary"}
                  </p>
                </OverlayTrigger>

                {report.sectors[0] && (
                  <Link
                    to={`/sectors/${report.sectors[0].id}_${sanitizeText(report.sectors[0].sName)}`}
                  >
                    <p className="subtitle-right">{report.sectors[0].sName}</p>
                  </Link>
                )}
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td style={{ padding: '0px 0px 0px 10px' }}>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              overlay={
                <Popover id="popover-trigger-hover-focus">
                  {report.companies[0] ? (
                    report.companies.map(company => company.display || company.sName).join(', ')
                  ) : (
                    <>
                      {report.countries[0] ? (
                        report.countries.map(val => val.name).join(', ')
                      ) : (
                        <span>No Geography</span>
                      )}
                    </>
                  )}
                </Popover>
              }
              placement="top"
            >
              {typeof report.title === 'string' ? (
                <Link
                  href={`/reports/${report.id}_${sanitizeText(report.title)}`}
                  to={`/reports/${report.id}_${sanitizeText(report.title)}`}
                  className="card-title"
                >
                  {report.title}
                </Link>
              ) : (
                report.title
              )}
            </OverlayTrigger>
          </td>
        </tr>
      </>
    );
  }

  if (report.publication && report.publication.trim().toLowerCase() === '5th column ideas') {
    return (
      <>
        <tr>
          <td style={{ padding: '5px 0px 0px 10px' }}>
            <div className="subtitle-box">
              <span style={{ display: 'flex' }}>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  overlay={
                    <Popover id="popover-trigger-hover-focus">
                      {report.companies[0] ? (
                        report.companies.map(company => company.display || company.sName).join(', ')
                      ) : (
                        <>
                          {report.countries[0] ? (
                            report.countries.map(val => val.name).join(', ')
                          ) : (
                            <span>No Geography</span>
                          )}
                        </>
                      )}
                    </Popover>
                  }
                  placement="top"
                >
                  <p className="subtitle-left">{report.subtitle || 'Tech Thematic Strategy'}</p>
                </OverlayTrigger>
                {report.sectors[0] && (
                  <Link
                    to={`/sectors/${report.sectors[0].id}_${sanitizeText(report.sectors[0].sName)}`}
                  >
                    <p className="subtitle-right">{report.sectors[0].sName}</p>
                  </Link>
                )}
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td style={{ padding: '0px 0px 0px 10px' }}>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              overlay={
                <Popover id="popover-trigger-hover-focus">
                  {report.companies[0] ? (
                    report.companies.map(company => (
                      <>
                        <strong>{company.display || company.sName}</strong>
                        <br />
                      </>
                    ))
                  ) : (
                    <>
                      {report.countries[0] ? (
                        report.countries.map(val => val.name).join(', ')
                      ) : (
                        <span>No Geography</span>
                      )}
                    </>
                  )}
                </Popover>
              }
              placement="top"
            >
              {typeof report.title === 'string' ? (
                <Link
                  href={`/reports/${report.id}_${sanitizeText(report.title)}`}
                  to={`/reports/${report.id}_${sanitizeText(report.title)}`}
                  className="card-title"
                >
                  {report.title}
                </Link>
              ) : (
                report.title
              )}
            </OverlayTrigger>
          </td>
        </tr>
      </>
    );
  }

  return (
    <>
      <tr>
        <td style={{ padding: '5px 0px 0px 10px' }}>
          <div className="subtitle-box">
            <span style={{ display: 'flex' }}>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                overlay={
                  <Popover id="popover-trigger-hover-focus">
                    {report.countries[0] ? (
                      report.countries.map(val => val.name).join(', ')
                    ) : (
                      <span>No Geography</span>
                    )}
                  </Popover>
                }
                placement="top"
              >
                <p className="subtitle-left">
                  {report.coverage ||
                    report.subtitle ||
                    //report.publication ||
                    (report.countries[0] && report.countries[0].name)}
                </p>
              </OverlayTrigger>
              {report.sectors[0] && (
                <Link
                  to={`/sectors/${report.sectors[0].id}_${sanitizeText(report.sectors[0].sName)}`}
                >
                  <p className="subtitle-right">{report.sectors[0].sName}</p>
                </Link>
              )}
            </span>
          </div>
        </td>
      </tr>
      <tr>
        <td style={{ padding: '0px 0px 0px 10px' }}>
          {typeof report.title === 'string' ? (
            <Link
              href={
                report.video
                  ? `/connect/videos/${report.video}`
                  : `/reports/${report.id}_${sanitizeText(report.title)}`
              }
              to={
                report.video
                  ? `/connect/videos/${report.video}`
                  : `/reports/${report.id}_${sanitizeText(report.title)}`
              }
              className="card-title"
            >
              {report.title}
            </Link>
          ) : (
            report.title
          )}
        </td>
      </tr>
    </>
  );
};

const mapStateToProps = ({ analyst }) => ({ analyst });

Component.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    subtitle: PropTypes.string,
    companies: PropTypes.array,
    countries: PropTypes.array,
    sectors: PropTypes.array,
    publication: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Component);
