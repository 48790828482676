import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Image, Col } from 'react-bootstrap';

import Logo from '../assets/images/logo.png';
import Box from './Box';

const Container = ({ children }) => (
  <Row className="container-row">
    <Col xs={12} smOffset={2} sm={12} mdOffset={2} md={8}>
      <Box className="box-signup">
        <Row className="form-container-logo">
          <Link href="/" to="/#">
            <Image src={Logo} className="box-signup-logo" />
          </Link>
        </Row>
        <div>{children}</div>
        <div className="box-signup-footer">
          Please reach out to us @&nbsp;
          <span className="text-danger">+852 3470 0011</span>
          &nbsp;or write an email to&nbsp;
          <a href="mailto:info@aletheia-capital.com">info@aletheia-capital.com</a>
          &nbsp;for any queries.
        </div>
      </Box>
    </Col>
  </Row>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
