import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Component = ({ backToHome }) => (
  <div className="box-signup-confirm">
    <h3 style={{textAlign:'center'}}>Thank you for registering with Aletheia.</h3>
    <p>
      Please complete the registration process via the link sent to your inbox.
    </p>
    <Link href="/" to="/" onClick={() => backToHome('hide')}>
      Back to home
    </Link>
  </div>
);

Component.propTypes = {
  backToHome: PropTypes.func.isRequired,
};

export default Component;
