export const getAllUsers = (usersType, q) => ({
  type: 'GET_USERS',
  usersType,
  q
});

export const getAllUsersuccess = (data, obj) => ({
  type: 'GET_USERS_SUCCESS',
  data,
  obj,
});

export const getAllUsersFail = error => ({
  type: 'GET_USERS_FAIL',
  error,
});

export const sendNewPassword = (usersType, id, email) => ({
  type: 'SEND_NEW_PASSWORD',
  usersType,
  id,
  email
});

export const sendNewPasswordSuccess = obj => ({
  type: 'SEND_NEW_PASSWORD_SUCCESS',
  obj,
});

export const sendNewPasswordFail = (obj, error) => ({
  type: 'SEND_NEW_PASSWORD_FAIL',
  obj,
  error,
});

export const sendVerifyEmail = (usersType, id, email, name) => ({
  type: 'SEND_VERIFY_EMAIL',
  usersType,
  id,
  email,
  name
});

export const sendVerifyEmailSuccess = obj => ({
  type: 'SEND_VERIFY_EMAIL_SUCCESS',
  obj,
});

export const sendVerifyEmailFail = (obj, error) => ({
  type: 'SEND_VERIFY_EMAIL_FAIL',
  obj,
  error,
});

export const changeUserStatus = (usersType, id, email, status) => ({
  type: 'CHANGE_USER_STATUS',
  usersType,
  id,
  email,
  status
});

export const changeUserStatusSuccess = obj => ({
  type: 'CHANGE_USER_STATUS_SUCCESS',
  obj,
});

export const changeUserStatusFail = (obj, error) => ({
  type: 'CHANGE_USER_STATUS_FAIL',
  obj,
  error,
});

export const searchSpyderFirms = (sMatch) => ({
  type: 'SEARCH_SPYDER_FIRMS',
  sMatch,
});

export const searchSpyderFirmsSuccess = (data, obj) => ({
  type: 'SEARCH_SPYDER_FIRMS_SUCCESS',
  data,
  obj,
});

export const searchSpyderFirmsFail = (obj, error) => ({
  type: 'SEARCH_SPYDER_FIRMS_FAIL',
  obj,
  error,
});

export const insertUserToSpyder = (userType, id, obj) => ({
  type: 'INSERT_USER_TO_SPYDER',
  userType,
  id,
  obj,
});

export const insertUserToSpyderSuccess = (obj) => ({
  type: 'INSERT_USER_TO_SPYDER_SUCCESS',
  obj,
});

export const insertUserToSpyderFail = (obj, error) => ({
  type: 'INSERT_USER_TO_SPYDER_FAIL',
  obj,
  error,
});
