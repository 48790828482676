/*
**************************************************************************************************************
                                            R&D TECH LIMITED
==============================================================================================================
The contents of this file ("Software") is the property of R&D Tech Limited ("R&D Tech") and is protected by
copyright and other intellectual property laws and treaties.

R&D Tech owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of copies of the
Software, redistribution of the Software and creation of derived works from the Software (and the
redistribution thereof) are subject to the terms of a license agreement issued by R&D Tech.

If the person in possession of the Software has not entered into a license agreement with R&D Tech, then any
use of the Software for any purpose whatsoever is strictly prohibited.
==============================================================================================================

**************************************************************************************************************
                                                Amendments
==============================================================================================================
  M006 : 06 July 2023 : Zeeshan Siddique
    * M-4445: Styling changes to Powered By Spyder
  M004 : 5 Aug 2022  : Nick Ng
    * M-4073: Add the No.1 in Asia badge to the header of the research portal
  M003 : 2 Dec 2020  : Nick Ng
    * M-3178: RFS proxy links are not recording the IP address of the recipient correctly
  M002 : 29 Jul 2020  : Nicho
    * M-2943: Homepage - Global header redesign
  M001 : 21 Jul 2020  : Aman
    * M-2935: User has to login in twice after clicking on a distribution RFS proxy link.
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import qs from 'querystring';
import { Navbar, Glyphicon, Image, FormGroup, Button } from 'react-bootstrap';
import Notifications, { notify } from 'react-notify-toast';

import * as userActions from '../../actionCreators/User';
import * as userModalActions from '../../actionCreators/UserModal';

import { OnMobile, OnTablet, OnAtLeastDesktop } from '../ReactBreak';
import UserInfo from './components/UserInfo';
import SearchBox from './components/Search';
import Navigation from './components/Navigation';

import Logo from '../../assets/images/aletheia-white-75x75-2.svg';
import MobileLogo from '../../assets/images/login-logo-small.png';
import HeaderBadge from '../../assets/images/logo-stamp-2022.png';
//====================M002==========================
import SocialButtons from './components/SocialButtons';

import { getUsername } from './config';
import PoweredBy from '../../assets/images/powered-by-spyder.png';

const Component = ({ history, logIn, user, currentUser, research, showHomePageModal }) => {
  React.useEffect(() => {
    const queryParams = qs.parse(history.location.search.replace('?', ''));
    if (Object.keys(queryParams).indexOf('confirmed') !== -1) {
      const popUpColor = { background: '#337ab7', text: '#FFFFFF' };
      notify.show('Thank you for completing the registration process', 'custom', 10000, popUpColor);
    }
  }, [history.location.search]);

  const [menuShow, setMenuShow] = React.useState(true);

  const queryParams = qs.parse(history.location.search);

  let externalPdfUrl = null;
  let signUpRoute = null;
  if (!currentUser.data) {
    externalPdfUrl = queryParams['?rfsUrl'];
    signUpRoute = '/signup';
    if (research.data) {
      const { spyderUser, userExists } = research.data;
      if (spyderUser) {
        const {
          email,
          name: { display },
        } = spyderUser;
        //====================M001=====[====================
        spyderUser
          ? (signUpRoute = `/login?email=${email}&redirectUrl=${history.location.search}`)
          : (signUpRoute = `/signup?name=${display}&email=${email}`);
        //====================M001=====[====================
      }
    }
  }

  return (
    <Navbar className="header-blue" collapseOnSelect fixedTop id="header-id">
      <OnMobile>
        <Notifications options={{ top: '10%' }} />
        <Navbar.Header>
          <Link href="/" to="/#">
            <Navbar.Brand>
              <Image className="logo-sm" src={MobileLogo} alt="Logo" responsive />
            </Navbar.Brand>
          </Link>
          {menuShow ? (
            <Navigation className="navigation-sm" responsive />
          ) : (
            <div className="menu-side">
              <UserInfo className="profile-section-small" />
            </div>
          )}
          <Glyphicon
            glyph="glyphicon glyphicon-option-vertical"
            className="aletheia-mobile-nav"
            onClick={() => setMenuShow(!menuShow)}
          />
        </Navbar.Header>
      </OnMobile>
      <OnTablet>
        <Notifications options={{ top: '10%' }} />
        <Navbar.Header>
          {externalPdfUrl ? (
            <a href={signUpRoute}>
              <Navbar.Brand>
                <Image className="logo" src={Logo} alt="Logo" />
              </Navbar.Brand>
            </a>
          ) : (
            <Link href="/" to="/#">
              <Navbar.Brand>
                <Image className="logo" src={Logo} alt="Logo" />
              </Navbar.Brand>
            </Link>
          )}
        </Navbar.Header>
        <Navigation className="custom-nav-Items" />
        {getUsername(currentUser.data) ? (
          <UserInfo className="profile-section" />
        ) : (
          <>
            <div className="header-login-signup">
              <FormGroup className="aletheia-msh-content active">
                {/*====================M002==========================*/}
                <Link href="/login" to="/login" style={{ color: '#FFF' }}>
                  Login
                </Link>
                <span class="header-divider" />
                <>
                  <Link href="/signup" to="/signup" style={{ color: '#FFF' }}>
                    Signup
                  </Link>
                  {/*====================M002==========================*/}
                  {/* <HomePageSignUpLoginModal /> */}
                </>
                {/*====================M002==========================*/}
              </FormGroup>
              <div className="alethiea-social-section">
                <SearchBox />
                <SocialButtons
                  externalPdfUrl={externalPdfUrl}
                  signUpRoute={signUpRoute}
                  style={{ padding: '0% 0% 30 0' }}
                />
                {/*====================M002==========================*/}
              </div>
            </div>
          </>
        )}
      </OnTablet>
      <OnAtLeastDesktop>
        <Notifications options={{ top: '10%' }} />
        <Navbar.Header>
          {externalPdfUrl ? (
            <a href={signUpRoute}>
              <Navbar.Brand>
                <Image className="logo" src={Logo} alt="Logo" />
              </Navbar.Brand>
            </a>
          ) : (
            <Link href="/" to="/#">
              <Navbar.Brand>
                <Image className="logo" src={Logo} alt="Logo" />
              </Navbar.Brand>
            </Link>
          )}
        </Navbar.Header>
        <Navigation className="custom-nav-Items" />
        {/* =================M004========[==============*/}
        <Image src={HeaderBadge} alt="Badge" width={55} className="header-2022-badge"/>
        {/* =================M004========]==============*/}
        {getUsername(currentUser.data) ? (
          <UserInfo className="profile-section" />
        ) : (
          <>
            <SearchBox />
            <div className="header-login-signup">
              {/* //====================M006=====[==================== */}
              <div className='power-by-header-before-login'>
                <a href='https://spyder-crm.com'
                  target="_blank">
                  Powered by&nbsp;
                  <Image src={PoweredBy} alt="Spyder" className="logo" style={{
                    width: 50,
                    marginBottom: '1px'
                  }} />
                </a>
              </div>
              {/* //====================M006=====[==================== */}
              <FormGroup className="aletheia-msh-content active">
                {/*====================M002==========================*/}

                <Link href="/login" to="/login" style={{ color: '#FFF' }}>
                  Login
                </Link>
                <span class="header-divider" />
                <>
                  <Link href="/signup" to="/signup" style={{ color: '#FFF' }}>
                    Signup
                  </Link>
                  {/*====================M002==========================*/}
                </>
              </FormGroup>
              <div className="social-section-header">
                {/*====================M002==========================*/}
                <div className="alethiea-social-section">
                  <SocialButtons
                    externalPdfUrl={externalPdfUrl}
                    signUpRoute={signUpRoute}
                    style={{ padding: '0% 0% 30 0' }}
                  />
                </div>
                {/*====================M002==========================*/}
              </div>
            </div>
          </>
        )}
      </OnAtLeastDesktop>
    </Navbar>
  );
};

const mapStateToProps = ({ user, user: { currentUser }, research: { research } }) => ({
  user,
  currentUser,
  research,
});

const mapDispatchToProps = dispatch => ({
  logIn: bindActionCreators(userActions.logIn, dispatch),
  showHomePageModal: bindActionCreators(userModalActions.showHomePageModal, dispatch),
});

Component.propTypes = {
  history: PropTypes.object.isRequired,
  research: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  logIn: PropTypes.func.isRequired,
  showHomePageModal: PropTypes.func,
  currentUser: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
