/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 5 August 2020 : Nicho
		* M-0002970: Homepage - Vision section redesign
**************************************************************************************************************
*/

import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Container = () => (
    //====================M001==========================
  <Col xs={12} sm={4} md={4}>
    <div className="our-values-section">
      <div className="values-block">
        <h1 className="section-title">Our Values</h1>
        <Row className="text-center">
          <Col xs={12} sm={12} md={12}>
            <div className="values-section">
              <h1>
                INDEPENDENCE
                <span className="values-dot" />
                INTEGRITY
                <span className="values-dot" />
                INSIGHTS
              </h1>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </Col>
    //====================M001==========================
);

export default Container;
