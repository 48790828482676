/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
    M001 : 4 May 2021 : Nick
		* M-0003383: Website UI Improve - Blog Landing Page
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import Avatar from '../assets/images/login-logo-small.png';
import Box from './Box';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Stack } from '@mui/material';

import { OnAtLeastTablet } from './ReactBreak';

const DESCRIPTION_LENGTH = 200;

const Component = ({
  style,
  aside,
  loading,
  title,
  titleBrand,
  titleLink,
  subtitle,
  imageTitle,
  imageSubtitle,
  imageTitleLink,
  imageLocation, //M001
  description,
  image,
  descriptionLength,
  descriptionFontSize,
  component,
  showReadMore,
  imageClass,
  readMoreLink, //M001
  Author,
  talkToAnalyst,
  AnalystId,
  parameters,
  data,
}) => {
  const [expand, setExpand] = React.useState(false);

  const readMore = (e, status) => {
    e.preventDefault();
    setExpand(status);
  };

  if (loading) {
    return (
      <Box style={style}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  return (
    <Box style={{ padding: '0px', ...style }}>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            {/* M001 */}
            <td rowSpan={imageLocation == 'left' ? 4 : null} className="card-avatar-section">
              <div style={{ padding: '0px 0px 5px 0px' }}>
                <div>
                  <Link href={imageTitleLink} to={imageTitleLink}>
                    <Image alt="card-avatar-section" className={imageClass} src={image || Avatar} />
                  </Link>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: '10px', paddingTop: '5px' }}>
              {titleLink ? (
                <Link href={titleLink} to={titleLink} className="card-title">
                  {title}
                </Link>
              ) : (
                title
              )}
              {titleBrand && (
                <div className="disc-subheading">
                  <p>{titleBrand}</p>
                </div>
              )}
              {component}
            </td>
          </tr>
          {Author ? (
            <tr>
              <td className="card-author"> By: {Author}</td>
            </tr>
          ) : null}
          <tr>
            <td
              colSpan="2"
              style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: descriptionFontSize }}
            >
              {subtitle && (
                <div className="disc-subheading">
                  <p>{subtitle}</p>
                </div>
              )}
              <Link href={imageTitleLink} to={imageTitleLink} className="card-title">
                <span className="sub-heading" style={{ fontSize: 15, paddingTop: 10 }}>
                  {imageTitle}
                </span>
              </Link>
              {talkToAnalyst &&
              parameters?.bEnableConnectBookings == '1' &&
              data.sJobCat == 'Analysts' ? (
                <td
                  colSpan="2"
                  style={{
                    paddingRight: '10px',
                    paddingBottom: 10,
                    float: 'right',
                    paddingTop: '10px',
                  }}
                >
                  <Link
                    href={`/connect/appointment/${AnalystId}`}
                    to={`/connect/appointment/${AnalystId}`}
                  >
                    <Stack direction="row" alignItems="center">
                      {parameters?.rw_CNTBkTalkToThe}
                      <ChatBubbleOutlineIcon style={{ marginLeft: '10px' }} />
                    </Stack>
                  </Link>
                </td>
              ) : null}

              {imageSubtitle && (
                <p className="sector-title" style={{ fontSize: 12.5, fontStyle: 'normal' }}>
                  {imageSubtitle}
                </p>
              )}
              {description ? (
                <div
                  style={{
                    fontFamily: 'open_sansregular',
                    paddingBottom: '5px',
                    paddingTop: '10px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      expand || String(description).length <= descriptionLength
                        ? description.replaceAll('\n', '<br/>')
                        : `${String(description.replaceAll('\n', '<br/>'))
                            .slice(0, descriptionLength)
                            .trim()}...`,
                  }}
                />
              ) : (
                <div className="card-description">No description</div>
              )}
            </td>

            <OnAtLeastTablet>
              {aside ? <td className="date-section">{aside}</td> : null}
            </OnAtLeastTablet>
          </tr>
          <tr style={{ display: showReadMore, float: 'right' }}>
            <td colSpan="2" style={{ paddingRight: '10px', paddingBottom: 10 }}>
              {
                String(description).length > DESCRIPTION_LENGTH &&
                  //M001
                  (readMoreLink ? (
                    <Link href={readMoreLink} to={readMoreLink} className="card-read-more">
                      Read more
                    </Link>
                  ) : (
                    <a className="card-read-more" href="/" onClick={e => readMore(e, !expand)}>
                      read {expand ? 'less' : 'more'}
                    </a>
                  ))
                //M001
              }
            </td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

Component.propTypes = {
  loading: PropTypes.bool,
  titleLink: PropTypes.string,
  aside: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  titleBrand: PropTypes.string,
  style: PropTypes.object,
  subtitle: PropTypes.string,
  imageTitle: PropTypes.string,
  imageSubtitle: PropTypes.string,
  imageTitleLink: PropTypes.string,
  description: PropTypes.string,
  descriptionLength: PropTypes.number,
  descriptionFontSize: PropTypes.string,
  component: PropTypes.object,
  showReadMore: PropTypes.string,
  imageClass: PropTypes.string,
  Author: PropTypes.string,
};

Component.defaultProps = {
  loading: false,
  image: null,
  title: null,
  titleBrand: null,
  style: {},
  subtitle: null,
  titleLink: null,
  aside: null,
  imageTitle: null,
  imageSubtitle: null,
  imageTitleLink: null,
  description: null,
  descriptionLength: DESCRIPTION_LENGTH,
  descriptionFontSize: '10.5px',
  component: null,
  showReadMore: null,
  imageClass: null,
  imageLocation: 'left',
  readMoreLink: null,
  Author: null,
};

export default Component;
