const initialState = {
  videos: {
    loading: false,
    error: null,
    data: [],
  },
  mostwatch: {
    loading: false,
    error: null,
    data: [],
  },
  savedvideos: {
    loading: false,
    error: null,
    data: [],
  },
  savevideo: {
    loading: false,
    error: null,
    data: [],
  },
  accessvideo: {
    loading: false,
    error: null,
    data: [],
  },
  currentVideo: {
    currentTime: null,
  }
};

export default (state = initialState, { type, error, data }) => {
  switch (type) {
    case 'GET_ALL_VIDEOS':
      return {
        ...state,
        videos: {
          ...state.videos,
          loading: true,
        },
      };

    case 'GET_ALL_VIDEOS_SUCCESS':
      return {
        ...state,
        videos: {
          ...state.videos,
          loading: false,
          data,
        },
      };

    case 'GET_ALL_VIDEOS_FAILURE':
      return {
        ...state,
        videos: {
          ...state.videos,
          loading: false,
          error,
        },
      };

    case 'GET_MOST_WATCHED':
      return {
        ...state,
        mostwatch: {
          ...state.mostwatch,
          loading: true,
        },
      };

    case 'GET_MOST_WATCHED_SUCCESS':
      return {
        ...state,
        mostwatch: {
          ...state.mostwatch,
          loading: false,
          data,
        },
      };

    case 'GET_MOST_WATCHED_FAILURE':
      return {
        ...state,
        mostwatch: {
          ...state.mostwatch,
          loading: false,
          error,
        },
      };

    case 'GET_SAVED_VIDEOS':
      return {
        ...state,
        savedvideos: {
          ...state.savedvideos,
          loading: true,
        },
      };

    case 'GET_SAVED_VIDEOS_SUCCESS':
      return {
        ...state,
        savedvideos: {
          ...state.savedvideos,
          loading: false,
          data,
        },
      };

    case 'GET_SAVED_VIDEOS_FAILURE':
      return {
        ...state,
        savedvideos: {
          ...state.savedvideos,
          loading: false,
          error,
        },
      };

    case 'SAVE_VIDEO':
      return {
        ...state,
        savevideo: {
          ...state.savevideo,
          loading: true,
        },
      };

    case 'SAVE_VIDEO_SUCCESS':
      return {
        ...state,
        savevideo: {
          ...state.savevideo,
          loading: false,
          data,
        },
      };

    case 'SAVE_VIDEO_FAILURE':
      return {
        ...state,
        savevideo: {
          ...state.savevideo,
          loading: false,
          error,
        },
      };

    case 'ACCESS_VIDEO':
      return {
        ...initialState,
        accessvideo: {
          ...initialState.accessvideo,
          loading: true,
        },
      };

    case 'ACCESS_VIDEO_SUCCESS':
      return {
        ...state,
        accessvideo: {
          ...state.accessvideo,
          loading: false,
          data,
        },
      };

    case 'ACCESS_VIDEO_FAILURE':
      return {
        ...state,
        accessvideo: {
          ...state.accessvideo,
          loading: false,
          error,
        },
      };
    case 'SET_CURRENT_VIDEO_TIME_SUCCESS':
      return {
        ...state,
        currentVideo: {
          currentTime: data
        }
      }
    default:
      return state;
  }
};
