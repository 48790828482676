/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 4 August 2020 : Nicho
		* M-0002969: Homepage - Our People section redesign
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Image, Glyphicon } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import 'react-id-swiper/src/styles/scss/swiper.scss';
import Avatar from '../../../assets/images/login-logo-small.png';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Stack } from '@mui/material';
import * as analystActions from '../../../actionCreators/Analyst';
import * as configActions from '../../../actionCreators/Config';

const Container = ({ ourPeople, getOurPeople, getParameter, parameters }) => {
  const [swiper, setSwiper] = React.useState(null);
  {
    /*====================M001==========================*/
  }
  const swiperOptions = {
    speed: 650,
    slidesPerView: 'auto',
    spaceBetween: 0,
    loop: true,
    // loopedSlides: 10,
    loopFillGroupWithBlank: false,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
    breakpoints: {
      2560: {
        slidesPerView: 9,
        spaceBetween: 20,
      },
      2160: {
        slidesPerView: 8,
        spaceBetween: 20,
      },
      1920: {
        slidesPerView: 7,
        spaceBetween: 20,
      },
      1440: {
        slidesPerView: 6,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 5,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      960: {
        slidesPerView: 3,
        spaceBetween: 25,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      468: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  };

  React.useEffect(() => {
    getParameter('rw_CNTBkTalkToThe,bEnableConnectBookings');
    if (!ourPeople.data.length) {
      getOurPeople();
    }
  }, []);

  return (
    <div className="our-people-section">
      {/*====================M001==========================*/}
      <div className="our-people-bar">
        <Row>
          <Col xs={5} sm={3} lg={2} className="center">
            <Link to={`/people`} href={`/people`}>
              <h1 className="section-title section-title-people">Our People</h1>
            </Link>
          </Col>
          <Col xs={5} sm={7} lg={8} className="center">
            <span className="title-bar"></span>
          </Col>
          <Col xs={1} sm={1} className="center icon-left">
            <Glyphicon
              glyph="glyphicon glyphicon-menu-left"
              className="slider-left"
              onClick={() => {
                if (swiper !== null) {
                  swiper.slidePrev();
                }
              }}
            />
          </Col>
          <Col xs={1} sm={1} className="center icon-right">
            <Glyphicon
              glyph="glyphicon glyphicon-menu-right"
              className="slider-right"
              onClick={() => {
                if (swiper !== null) {
                  swiper.slideNext();
                }
              }}
            />
          </Col>
        </Row>
      </div>
      {/*====================M001==========================*/}
      {ourPeople.data.length && (
        <Swiper
          {...swiperOptions}
          ref={node => {
            if (node) {
              setSwiper(node.swiper);
            }
          }}
        >
          {ourPeople.data
            // .sort((a, b) => a.order - b.order)
            .sort((a, b) => {
              return (
                (a.order === null) - (b.order === null) ||
                +(a.order > b.order) ||
                -(a.order < b.order)
              );
            })
            .filter(i => i.is_our_people)
            .map((item, index) => (
              <div className="our-people-crousel" key={`ourpeople_${index}`}>
                {/*====================M001==========================*/}
                <Image
                  alt="team-member-image"
                  className="team-member-image"
                  src={item.image_path || Avatar}
                  circle
                />
                <Link to={`/analysts/${item.id}`} href={`/analysts/${item.id}`}>
                  <h1>{item.name}</h1>
                </Link>
                {parameters?.bEnableConnectBookings == '1' && item.sJobCat == 'Analysts' ? (
                  <Link
                    to={`/connect/appointment/${item.id}`}
                    href={`/connect/appointment/${item.id}`}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      style={{
                        fontSize: '12px',
                        color: '#00b0f0',
                        textTransform: 'capitalize',
                        marginBottom: '10px',
                      }}
                    >
                      {parameters?.rw_CNTBkTalkToThe}{' '}
                      <ChatBubbleOutlineIcon style={{ marginLeft: '10px' }} />
                    </Stack>
                    {/* <p
                      style={{
                        fontSize: '12px',
                        color: '#00b0f0',
                        textTransform: 'capitalize',
                        
                      }}
                    >
                      {parameters?.rw_CNTBkTalkToThe}{' '}
                      <ChatBubbleOutlineIcon style={{ marginLeft: '10px' }} />
                    </p> */}
                  </Link>
                ) : null}

                <p>{item.designation}</p>
              </div>
            ))}
        </Swiper>
      )}
    </div>
  );
};

const mapStateToProps = ({ analyst: { ourPeople }, Config: { parameters } }) => ({
  ourPeople,
  parameters,
});

const mapDispatchToProps = dispatch => ({
  getOurPeople: bindActionCreators(analystActions.getOurPeople, dispatch),
  getParameter: bindActionCreators(configActions.getParameter, dispatch),
});

Container.propTypes = {
  ourPeople: PropTypes.object.isRequired,
  getOurPeople: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
