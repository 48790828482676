import React from 'react';
import { Grid } from 'react-bootstrap';

import Box from '../../components/Box';
import privacyContent from './privacy.json';

const Container = () => {
  React.useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <Grid>
      <Box
        style={{ borderRadius: 5, textAlign: 'center' }}
        dangerouslySetInnerHTML={{
          __html: privacyContent.content,
        }}
      />
    </Grid>
  );
};

export default Container;
