import { doGet, doPost } from '../utils/request';
import { call, all, put, select, takeLatest, takeEvery } from 'redux-saga/effects';

import {
  registerEventsSuccess,
  registerEventsFail,
  getEventsSuccess,
  getEventsFail,
  getEventSuccess,
  getEventFail,
  getOpenSlotDetailsSuccess,
  getOpenSlotDetailsFail,
  bookConnectOpenSlotSuccess,
  bookConnectOpenSlotFail,
  cancelConnectEventBookingSuccess,
  cancelConnectEventBookingFail,
} from '../actionCreators/Connect';

function* getEvents() {
  try {
    const response = yield doGet('/connect/events');
    yield put(getEventsSuccess(response.data));
  } catch (err) {
    const delay = time => new Promise(resolve => setTimeout(resolve, time));
    yield call(delay, 2000);
    yield put(getEventsFail());
  }
}

function* getEvent({ id }) {
  try {
    const response = yield doGet('/connect/event/' + id);
    yield put(getEventSuccess(response.data));
  } catch (err) {
    const delay = time => new Promise(resolve => setTimeout(resolve, time));
    yield call(delay, 2000);
    yield put(getEventFail());
  }
}

function* registerEvents({ data }) {
  try {
    const response = yield doPost('/connect/events', data);
    const delay = time => new Promise(resolve => setTimeout(resolve, time));
    yield call(delay, 2000);
    yield put(registerEventsSuccess(response.data));
  } catch (err) {
    const delay = time => new Promise(resolve => setTimeout(resolve, time));
    yield call(delay, 2000);
    yield put(registerEventsFail());
  }
}

function* getOpenSlotDetails({ iPSN_Id }) {
  try {
    const response = yield doGet(`/connect/getOpenSlotDetails/${iPSN_Id}`);
    yield put(getOpenSlotDetailsSuccess(response.data));
  } catch (err) {
    yield put(getOpenSlotDetailsFail(err.message));
  }
}

function* bookConnectOpenSlot({ iLng, iPSN_Id, iCAE_Id, iAnalystId }) {
  try {
    const response = yield doGet(`connect/book/${iLng}/${iPSN_Id}/${iCAE_Id}/${iAnalystId}`);
    yield put(bookConnectOpenSlotSuccess(response.data));
  } catch (err) {
    yield put(bookConnectOpenSlotFail(err.message));
  }
}

function* cancelConnectEventBooking({ iLng, iPSN_Id, iCAE_Id, iAnalystId }) {
  try {
    const response = yield doGet(`connect/cancel/${iLng}/${iPSN_Id}/${iCAE_Id}/${iAnalystId}`);
    yield put(cancelConnectEventBookingSuccess(response.data));
  } catch (err) {
    yield put(cancelConnectEventBookingFail(err.message));
  }
}

export default function* watcher() {
  yield all([takeLatest('GET_EVENTS', getEvents)]);
  yield all([takeLatest('GET_EVENT', getEvent)]);
  yield all([takeLatest('REGISTER_EVENTS', registerEvents)]);
  yield all([takeLatest('GET_OPEN_SLOT_DETAILS', getOpenSlotDetails)]);
  yield all([takeLatest('BOOK_CONNECT_OPEN_SLOT', bookConnectOpenSlot)]);
  yield all([takeLatest('CANCEL_CONNECT_EVENT_BOOKING', cancelConnectEventBooking)]);
}
