import React from 'react';
import PropTypes from 'prop-types';
import { signupTableConfig } from './signupTableConfig'
import Table from '../../../components/Table';

const SignUpForm = ({title, data, showButton}) => {
  return (
      <>
        <Table
            title={title}
            searchAble={false}
            labels={signupTableConfig()}
            data={data}
            loading={false}
            defaultText={"No Upcoming Events"}
        />
      </>
  )
};

SignUpForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default SignUpForm;