import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Tab, Col, Row, NavItem, Nav } from 'react-bootstrap';
import Box from '../../components/Box';
import '../../assets/sass/admin-panel.scss';
import WebsiteUser from './components/websiteUsers'
import SpyderUser from './components/spyderUsers'

const Container = () => {
    const [activeKey, setActiveKey] = React.useState('Website');

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Users Management</title>
            </Helmet>
            <Row className="box-favourites-settings" style={{ marginTop: '3%', marginBottom: '3%' }}>
                <Col lg={10} mdOffset={1} md={10}>
                    <Box className="box-upload-image">
                        <h4 style={{ paddingBottom: '2%' }}> Users Management </h4>
                        <Tab.Container id="left-tabs-example" defaultActiveKey={activeKey}>
                            <Row>
                                {/* LEFT PANEL */}
                                <Col sm={2}>
                                    <Nav bsStyle="pills" stacked>
                                        <NavItem 
                                            onClick={() => {
                                                setActiveKey('Website');
                                            }} 
                                            eventKey={'Website'}>
                                            Website User
                                        </NavItem>
                                        <NavItem 
                                            onClick={() => {
                                                setActiveKey('Spyder');
                                            }} 
                                            eventKey={'Spyder'}>
                                            Spyder User
                                        </NavItem>
                                    </Nav>
                                </Col>
                                {/* RIGHT PANEL */}
                                <Col sm={10}>
                                    {activeKey=="Website"?
                                        (<WebsiteUser/>)
                                        :
                                        (<SpyderUser/>)
                                    }
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Box>
                </Col>
            </Row>
        </>
    );
};

export default Container;