import React from 'react';
import '../../../assets/sass/aletheia-access.scss';
import { Stack, Typography } from '@mui/material';

const Section1 = () => {
  return (
    <div className="section1-container">
      {/* <Stack justifyContent="center" alignItems="center">
        <Stack className="section1-item" justifyContent="center" alignItems="center">
          <Typography fontSize={16}>
            #1 Investment Advisory firm with 200+ institutional clients, over 8,000 touch points,
            high quality product and global distribution
          </Typography>
        </Stack>
      </Stack>
      <Stack justifyContent="center" alignItems="center">
        <Stack className="section1-item" justifyContent="center" alignItems="center">
          <Typography fontSize={16}>
            Aletheia specialises in Global Macro products along with Global ‘New Economy’
            sector/stock coverage
          </Typography>
        </Stack>
      </Stack> */}
      <Stack justifyContent="center" alignItems="center">
        <Stack className="section1-item" justifyContent="center" alignItems="center">
          <Typography fontSize={16}>
            ACCESS is focused on providing advisory and consultancy services in Private and Illiquid
            markets across asset classes
          </Typography>
        </Stack>
      </Stack>
      <Stack justifyContent="center" alignItems="center" style={{ marginTop: '30px' }}>
        <Stack justifyContent="center" alignItems="center">
          <Typography fontSize={16} style={{ fontFamily: 'open_sanssemibold' }}>
            If you are interested in partnering, please{' '}
            <a href="mailto:info@aletheia-capital.com?subject=Aletheia ACCESS">contact us</a>
          </Typography>
        </Stack>
      </Stack>
      {/* <Stack justifyContent="center" alignItems="center">
        <Stack className="section1-item" justifyContent="center" alignItems="center">
          <Typography fontSize={16}>
            Strong track record in advising companies from start-up phase all the way to public
            markets, and from the US to Africa to Australia.
          </Typography>
        </Stack>
      </Stack> */}
    </div>
  );
};

export default Section1;
