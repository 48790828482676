import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  Row,
  FormGroup,
  Form,
  Col,
  ControlLabel,
  FormControl,
  Tab,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import * as configActions from '../actionCreators/Config';
import * as reportActions from '../actionCreators/Research';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { doPost } from '../utils/request';
import Box from '../../src/components/Box';
import ReportCard from '../containers/Reports/components/ReportCard';
import { getStandardDateTime } from '../utils/dateTime';

import '../assets/sass/blogs.scss'

const ReportsContainer = React.lazy(() => import('../containers/Reports'));
const Component = ({
  show,
  html,
  onCancel,
  update,
  postId,
  saveBlogResearch,
  GetBlogReports,
  BlogReports,
  oldTitle
}) => {
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [tab, setTab] = React.useState('b');
  const [subTab, setSubTab] = React.useState('select');
  const [SavedNew, setSavedNew] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const onEditorStateChange = editorState => {
    setEditorState(editorState);
  };
  React.useEffect(() => {
    if (postId != undefined && postId != null) {
      GetBlogReports({ id: postId });
    }
  }, [postId]);
  React.useEffect(() => {
    let savedarr = [];
    if (BlogReports.data.length != 0) {
      BlogReports.data.researches.map(item => savedarr.push(item.id));
      setSavedNew(savedarr);
    }
  }, [BlogReports]);
  React.useEffect(() => {
    if (html) {
      const contentState = EditorState.createWithContent(convertFromRaw(JSON.parse(html)));
      setEditorState(contentState);
    }
  }, [html]);

  React.useEffect(()=> {
    setTitle(oldTitle)
  }, [oldTitle])

  const uploadCallback = image => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append('file', image);
      doPost('/posts/uploadImage', data)
        .then(response => resolve({ data: { link: response.data && response.data.path } }))
        .catch(err => reject(err));
    });
  };

  const AttachReportToBlog = reportid => {
    saveBlogResearch({ iPostID: postId, sResearches: reportid });
    setTimeout(()=>{
      GetBlogReports({ id: postId });
    },3000);
  };
  return (
    <div>
      <Modal show={show} className="container-fake post-modal">
        <Modal.Header closeButton>
          <Modal.Title>Edit Post</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '2%' }}>
          <Box>
            <Tab.Container id="left-tabs-example" defaultActiveKey={tab}>
              <Tab.Content animation>
                <Tab.Pane eventKey="b">
                  <div>
                    <div className="button-group-box favourites">
                      <ButtonToolbar>
                        <ToggleButtonGroup type="radio" name="options" defaultValue={tab}>
                          <ToggleButton value="b" onClick={() => setTab('b')}>
                            Blog Post
                          </ToggleButton>
                          <ToggleButton value="a" onClick={() => setTab('a')}>
                            Associated Research
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </ButtonToolbar>
                    </div>

                    {tab === 'a' && (
                      <>
                        <div className="button-group-box favourites">
                          <ButtonToolbar>
                            <ToggleButtonGroup type="radio" name="options" defaultValue={subTab}>
                              <ToggleButton value="select" onClick={() => setSubTab('select')}>
                                Select Reports
                              </ToggleButton>
                              <ToggleButton value="attach" onClick={() => setSubTab('attach')}>
                                Attached Reports
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </ButtonToolbar>
                        </div>
                      </>
                    )}
                    {subTab === 'select' && tab === 'a' && (
                      <Row>
                        <React.Suspense fallback={<></>}>
                          <ReportsContainer
                            BlogSectionReports
                            AttachClick={AttachReportToBlog}
                            ReportsBlog={SavedNew}
                          />
                        </React.Suspense>
                      </Row>
                    )}
                    {subTab === 'attach' &&
                      tab === 'a' &&
                      BlogReports.data.researches.map((item, i) => (
                        <ReportCard
                          ReportsBlog={SavedNew}
                          OnAttachClick={() => AttachReportToBlog(item.id)}
                          BlogSectionReports
                          showAvatar={false}
                          reportid={item.id}
                          key={`report_card_${item.id}_${i}`}
                          research={{
                            ...item,
                            published_date: getStandardDateTime(item.published_date),
                          }}
                          style={{ margin: '4px 0px', borderRadius: '5px' }}
                        />
                      ))}
                    {tab === 'b' && (
                      <Form
                        onSubmit={event => {
                          event.preventDefault();
                          const rawDraftContentState = JSON.stringify(
                            convertToRaw(editorState.getCurrentContent())
                          );
                          update(
                            draftToHtml(convertToRaw(editorState.getCurrentContent())),
                            rawDraftContentState,
                            title
                          );
                        }}
                      >
                        <FormGroup controlId="formInlineName">
                          <Row>
                            <div>
                              <Row>
                                <Col xs={4} componentClass={ControlLabel}  style={{textAlign:'center'}} className="topic-label">
                                  Post Title:
                                </Col>
                                <Col xs={7}>
                                  <FormControl
                                    defaultValue={title}
                                    type="text"
                                    placeholder={"Post Title"}
                                    required={true}
                                    onKeyUp={(e)=>setTitle(e.target.value)}
                                  />
                                </Col>
                              </Row>
                              <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="post-content"
                                toolbar={{
                                  image: {
                                    urlEnabled: true,
                                    uploadEnabled: true,
                                    previewImage: true,
                                    uploadCallback: uploadCallback,
                                    alignmentEnabled: true,
                                    defaultSize: {
                                      height: 'auto',
                                      width: '400px',
                                    },
                                  },
                                  emoji: false,
                                }}
                                editorClassName="post-content"
                                onEditorStateChange={onEditorStateChange}
                              />
                            </div>
                          </Row>
                          <Row className="save-button-row">
                            <div className="save-button">
                              <Button style={{ marginRight: '2%' }} onClick={() => onCancel(false)}>
                                Cancel
                              </Button>
                              <Button
                                type="submit"
                                bsStyle="primary"
                                disabled={!editorState.getCurrentContent().hasText()}
                              >
                                Save
                              </Button>
                            </div>
                          </Row>
                        </FormGroup>
                      </Form>
                    )}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Box>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({
  research: { BlogReports },
  Config: {
    favourites: {
      loading,
      data: { favourites },
    },
  },
}) => ({ loading, favourites, BlogReports });

const mapDispatchToProps = dispatch => ({
  saveBlogResearch: bindActionCreators(configActions.saveBlogResearch, dispatch),
  GetBlogReports: bindActionCreators(reportActions.GetBlogReports, dispatch),
});

Component.propTypes = {
  html: PropTypes.node,
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  update: PropTypes.func,
};

Component.defaultProps = {
  show: false,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
