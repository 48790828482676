/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
  M001 : 24 July 2020 : Aman
		* M-0002946: Homepage - Vital Information redesign.
**************************************************************************************************************
*/

//====================M001=====[====================
import React from 'react';
import { Col, Row } from 'react-bootstrap';

const VitalDescription = "Asia's First Full Scale Independent Investment Advisory Firm";

const VitalInfo = () => {
  return (
    <div className="vital-div">
      <div className="desContainer">
        <span className="vital-desc">{VitalDescription}</span>
      </div>
      <div className="listContainer">
        <ul className="vital-list">
          <li>Investments</li>
          <li>Ideas</li>
          <li>Advisory</li>
          <li>Fintech</li>
        </ul>
      </div>
    </div>
  );
};

export default VitalInfo;
//====================M001=====[====================
