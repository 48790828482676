import { layoutGenerator } from 'react-break';

const layout = layoutGenerator({
  mobile: 0,
  tablet: 768,
  desktop: 992,
  largeDesktop: 1200,
});

export const OnMobile = layout.is('mobile');
export const OnTablet = layout.is('tablet');
export const OnDesktop = layout.is('desktop');
export const OnLargeDesktop = layout.is('largeDesktop');

export const OnAtLeastMobile = layout.isAtLeast('mobile');
export const OnAtLeastTablet = layout.isAtLeast('tablet');
export const OnAtLeastDesktop = layout.isAtLeast('desktop');
export const OnAtLeastLargeDesktop = layout.isAtLeast('largeDesktop');

export const OnAtMostMobile = layout.isAtMost('mobile');
export const OnAtMostTablet = layout.isAtMost('tablet');
export const OnAtMostDesktop = layout.isAtMost('desktop');
export const OnAtMostLargeDesktop = layout.isAtMost('largeDesktop');
