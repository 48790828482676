import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Grid } from 'react-bootstrap'
import PageHeader from '../../components/PageHeader';

const ReportsContainer = React.lazy(() => import('../../containers/Reports'));

const Container = ({currentUser}) => {
  const user = currentUser && currentUser.data;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Research Page</title>
        <meta name="description" content="All researches of Aletheia Capital" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      { user.type=="npi" && 
        <>
          <PageHeader
            pageName="Aletheia Select"
            pageDesc="Asia's First Full Scale Independent Investment Advisory Firm"
          />
          <br/>
        </>
      }
      <React.Suspense fallback={<></>}>
        {
          user.type=="npi" ? 
          <Grid style={{ paddingLeft: '5%', paddingRight: '5%' }}>
            <ReportsContainer 
              showHighlighted={false}
              showTypeBar={false}
            />
          </Grid>:
          <ReportsContainer 
            showHighlighted
          />
        }
      </React.Suspense>
    </>
  );
} 

const mapStateToProps = ({
  user: { currentUser },
}) => ({
  currentUser
});

// export default Container;
export default withRouter(connect(mapStateToProps)(Container));
