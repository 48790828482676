/*
**************************************************************************************************************
											R&D TECH LIMITED
==============================================================================================================
The contents of this file ("Software") is the property of R&D Tech Limited ("R&D Tech") and is protected by
copyright and other intellectual property laws and treaties.

R&D Tech owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of copies of the
Software, redistribution of the Software and creation of derived works from the Software (and the
redistribution thereof) are subject to the terms of a license agreement issued by R&D Tech.

If the person in possession of the Software has not entered into a license agreement with R&D Tech, then any
use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
											Amendments
==============================================================================================================
	M004 : 12 Dec 2023 : Dom Giles
		 * D-Copy Link to Tech Presentation button should only be visible to admin users
	M003 : 17 Aug 2023 : Zeeshan
		 * M-4476: Add website version number to Account page for admin view only
	M002 : 31 Jul 2023 : Zeeshan
		 * M-4484: Button to copy link to tech hardware presentation under the profile page for internal staff only
  	M001 : 08 Feb 2021 : Aman
		 * M-3219: Account page for user to view their details and update their password
**************************************************************************************************************
*/
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';

import Logo from '../../assets/images/logo.png';
import ChangePasswordForm from './components/ChangePasswordForm';
import Box from '../../components/Box';
import AdminMenu from './components/AdminMenu';
import CopyLinkDropDown from './components/CopyLinkDropDown';
import { Link } from 'react-router-dom';
import * as userActions from '../../actionCreators/User';
import * as configActions from '../../actionCreators/Config';
import { getUsername } from '../../components/Header/config';

const Container = ({ user, changePassword, currentUser, getParameter, parameters }) => {
  const [copied, setCopied] = React.useState(false);
  const [copiedTechLink, setCopiedTechLink] = React.useState(false);

  useEffect(() => {
    getParameter('sWebCustomLinks,rw_CopyLinkTo,sRWClientSubTitle');
  }, []);

  const copyMarketingLink = () => {
    const marketingLink = JSON.parse(parameters?.sWebCustomLinks).marketingPresentation
      .COPY_LINK_TO_MARKETING_PRESENTATION;

    navigator.clipboard.writeText(marketingLink);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const copyTechLink = () => {
    const techLink = JSON.parse(parameters?.sWebCustomLinks).techPresentation
      .COPY_LINK_TO_TECH_PRESENTATION;
    navigator.clipboard.writeText(techLink);
    setCopiedTechLink(true);

    setTimeout(() => {
      setCopiedTechLink(false);
    }, 2000);
  };

  return (
    <Row className="change-password-box">
      {/* //====================M001=====[==================== */}

      <Col xs={12} smOffset={1} sm={10} mdOffset={2} md={8}>
        <h2>Account</h2>
        <Box className="box-signup">
          <Row>
            <Col xs={4} sm={2}>
              <div className="settings-user-profile-icon">
                <h2 className="settings-user-profile-name"> {getUsername(currentUser.data)} </h2>
              </div>
            </Col>
            <Col xs={4} sm={5}>
              <div className="setting-user-details">
                <span className="setting-user-fullname">{currentUser.data.full_name}</span>
                <br />
                <span>{currentUser.data.email}</span>
                <br />
                {currentUser.data.type == 'admin' ? <span>Admin</span> : <span>Regular User</span>}
              </div>
              <br />
            </Col>
            <Col xs={12} sm={2}>
              {currentUser.data.type == 'admin' ? (
                <CopyLinkDropDown
                  title={parameters?.rw_CopyLinkTo ? parameters?.rw_CopyLinkTo : ''}
                  data={parameters?.sWebCustomLinks ? JSON.parse(parameters?.sWebCustomLinks) : {}}
                />
              ) : null}
            </Col>
            <Col xs={12} sm={3} style={{ textAlign: 'right' }}>
              {currentUser.data.type == 'admin' ? <AdminMenu /> : null}
              {currentUser.data.type == 'admin' ? (
                <div style={{ padding: '12px' }}>Version 11.7.2</div>
              ) : null}{' '}
              {/*M003*/}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={5}>
              {/* <Link href="/subscriptionPreference/settings" to="/subscriptionPreference/settings"> */}
              <a href="/subscriptionPreference/settings">
                <Button
                  // type="submit"
                  bsStyle="primary"
                  style={{ width: '100%', marginTop: '15px' }}
                  // disabled={loading}
                >
                  {parameters?.sRWClientSubTitle}
                </Button>
              </a>
              {/* </Link> */}
            </Col>
          </Row>
          {/* <Row>
            <Col sm={0} md={6}></Col> */}
          {/* <Col sm={12} md={6}>
              <div className="market-btn-wrapper">
                {currentUser.data.type == 'admin' ? (
                  <Button
                    bsStyle="primary"
                    className="marketing-btn"
                    onClick={copyMarketingLink}
                    disabled={copied}
                  >
                    {!copied ? 'Copy Link to marketing Presentation' : 'Copied'}
                  </Button>
                ) : null}
              </div>
            </Col> */}

          {/* //====================M002,M004=====[==================== */}
          {/* <hr />
            <Col sm={0} md={6}></Col>
            <Col sm={12} md={6}>
              <div className="market-btn-wrapper">
                {currentUser.data.type == 'admin' ? (
                  <Button
                    bsStyle="primary"
                    className="marketing-btn"
                    onClick={copyTechLink}
                    disabled={copied}
                  >
                    {!copiedTechLink ? 'COPY LINK TO TECH PRESENTATION' : 'Copied'}
                  </Button>
                ) : null}
              </div>
            </Col> */}
          {/* //====================M002,M004=====]==================== */}
          {/* </Row> */}
          <hr className="settings-hr" />
          <Row>
            <Col sm={12} md={12}>
              <h3 className="settings-password-heading"> Change Password</h3>
              <ChangePasswordForm onSubmit={changePassword} message={user} />
            </Col>
          </Row>
          {/* //====================M001=====[==================== */}

          <Row className="box-signup-footer">
            Please reach out to us @&nbsp;
            <span className="text-danger">+852 3470 0011</span>
            &nbsp;or write an email to&nbsp;
            <a href="mailto:info@aletheia-capital.com">info@aletheia-capital.com</a>
            &nbsp;for any queries.
          </Row>
        </Box>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ user, user: { currentUser }, Config: { parameters } }) => ({
  user,
  currentUser,
  parameters,
});

const mapDispatchToProps = dispatch => ({
  changePassword: bindActionCreators(userActions.changePassword, dispatch),
  getParameter: bindActionCreators(configActions.getParameter, dispatch),
});

Container.propTypes = {
  user: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
