import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Component = ({
    show,
    setReportType,
    reportType
}) => {

    if (show==false) {
        return (<></>)
    }

    return (
        <Row className={'type-tab-bar-div'}>
            <Col xs={6} style={{ margin: '0px', padding: '0px', paddingLeft: '15px' }}>
                <div className={!reportType?'type-tab-highlighted':'type-tab-normal'} onClick={()=>{setReportType()}}>
                    All Reports
                </div>
            </Col>
            <Col xs={6} style={{ margin: '0px', padding: '0 15px 0 0'}}>
                <div className={reportType==2105400019?'type-tab-highlighted':'type-tab-normal'} onClick={()=>{setReportType(2105400019)}}>
                    Company Results
                </div>
            </Col>
        </Row> 
    )
}

export default Component;