/*
**************************************************************************************************************
											                      R&D TECH LIMITED
==============================================================================================================
The contents of this file ("Software") is the property of R&D Tech Limited ("R&D Tech") and is protected by
copyright and other intellectual property laws and treaties.

R&D Tech owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of copies of the
Software, redistribution of the Software and creation of derived works from the Software (and the
redistribution thereof) are subject to the terms of a license agreement issued by R&D Tech.

If the person in possession of the Software has not entered into a license agreement with R&D Tech, then any
use of the Software for any purpose whatsoever is strictly prohibited.
==============================================================================================================

**************************************************************************************************************
					                                  	Amendments
==============================================================================================================
  M010 : 18 Oct 2022 : Nick
    * M-4172: Fix Must Read and Free Access buttons
  M009 : 17 Oct 2022 : Nick
    * M-4165: 4180: Mirgrate research portal to use public module api and deprecate Node.js
  M008 : 21 Sep 2022 : Nick
    * M-4165: Pressing back arrow when viewing a blog post does not do anything
  M007 : 4 Aug 2022 : Nick
    * M-4070: Access report that is not marked as open to public should redirect user to login page
  M006 : 8 July 2021 : Nick
    * M-3483: Free access finished if clicking back and forth will continue to show free access message
  M005 : 1 Mar 2021 : Nick
    * M-3292: Periodic free access to reports - Website
  M004 : 14 January 2021 : Nick
    * M-3200: Report on who was asked for email verification but did not complete the process
  M003 : 24 November 2020 : Nick
    * M-3042: Email verification and cookie to authenticate users for email based report links
	M002 : 23 November 2020 : Nick
    * M-3152: Clicking related report doesn't appear
	M001 : 15 July 2020 : Aman
    * M-2936: Clicking on RFS proxy link should check to see if recipient has rights to access document.
**************************************************************************************************************
*/
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Tooltip, OverlayTrigger, Button, Glyphicon, Row, Col, Image } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import qs from 'querystring';
import Notifications, { notify } from 'react-notify-toast'; //M003
import { Helmet } from 'react-helmet';
import Box from '../../components/Box';
import ReportCard from '../../containers/Reports/components/ReportCard';
import exitFullScreenIcon from '../../../src/assets/images/exit_fullscreen_icon.png';
import fullScreenIcon from '../../../src/assets/images/fullscreen_icon.png';
import { sanitizeText, timeout } from '../../utils';
import { getStandardDateTime } from '../../utils/dateTime';

import * as researchActions from '../../actionCreators/Research';
import * as userModalActions from '../../actionCreators/UserModal';
import * as configActions from '../../actionCreators/Config';
import * as userActions from '../../actionCreators/User'; //M003
import starFilled from '../../assets/images/bookmark-4-yellow-filled.svg';
import Viewer from './components/Viewer';
import CookieForm from './components/EmailCookieForm';

// checks the browser
const userAgnt = window.navigator.userAgent;
const isIE = /MSIE|Trident|Edge\//.test(userAgnt);

const copyLinkToClipboard = (link, event, options = {}) => {
  const { notification = true } = options;

  if (event) {
    event.preventDefault();
  }

  const dummy = document.createElement('input');
  dummy.value = link;
  document.body.appendChild(dummy);
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);

  if (notification === true) {
    try {
      notify.show('Link Copied to Clipboard!', 'custom', 1000, {
        background: '#327ab7',
        text: '#fff',
      });
    } catch (err) {
      console.log(err);
    }
  }
};

const getIEVersion = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }
  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }
  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }
  // other browser
  return false;
};

const Container = ({
  research,
  getResearch,
  getRelatedResearch,
  location: { pathname, search },
  relatedResearches,
  history,
  user,
  setComponentLayout,
  fullScreenMode,
  getViewReportCookie,
  viewCookie, //M003
  logIn, //M003
  user_error, //M003
  SaveReport,
  GetSavedReports,
  savedReports,
  getHighlightedResearches,
  highlightedResearches,
  highlightReport,
  getParameter,
  parameters,
}) => {
  const queryParams = qs.parse(search);
  // const [progress, setProgress] = React.useState(0);
  const [relatedSection, setRelatedSection] = React.useState(true);

  // const [initialized, setInitialized] = React.useState(false);
  const url = React.useRef(queryParams['?rfsUrl']);
  const EPDF = history.location.pathname.includes('rfs.htm');
  //====================M001=====[====================
  const userSpyderId = React.useRef(queryParams.rcptId);
  const docSpyderId = React.useRef(queryParams.docId);
  //====================M001=====[====================
  const [saved, setSaved] = React.useState(false);
  const [isHighlighted, setIsHighlighted] = React.useState(false);
  //Access Key
  const accessKey = React.useRef(queryParams['?a']);
  //Access Key
  const id = pathname.split('/')[2] && pathname.split('/')[2].split('_')[0];
  const { title, analysts, filetype, path } = research.data || {};
  let showHighlighted = true;
  const [divHieght, setDivHieght] = React.useState(window.innerHeight - 140);
  const [externalPdfFullScreen, setExternalPdfFullScreen] = React.useState(false);
  const [logInMsg, setLogInMsg] = React.useState(''); //M003
  const [SocialMediaTiles, setSocialMediaTiles] = useState(false);

  if (!user) {
    showHighlighted = false;
    if (relatedSection) {
      setRelatedSection(false);
    }
  }

  if (url.current || accessKey.current) {
    showHighlighted = false;
    if (relatedSection) {
      setRelatedSection(false);
    }
  }

  const handleResize = () => {
    let screenHieght = window.innerHeight - 140;
    if (divHieght !== screenHieght) {
      setDivHieght(screenHieght);
    }
  };

  const SaveReports = () => {
    const report = id.substring(2);
    SaveReport({ id: report, status: saved ? 0 : 1 });
    setSaved(!saved);
  };

  const highlightReportHandler = () => {
    //M010
    highlightReport({
      id: id,
    });
    setIsHighlighted(!isHighlighted);
  };
  //====================M003=====[====================

  const deviceVerify = obj => {
    if (obj.type == 'login') {
      logIn(obj, history, history.location, true);
    } else {
      getViewReportCookie(obj, window.location.href);
    }
  };

  //=======================M007=========[================
  const errorWebPortalAccess = msg => {
    if (msg === 'requiredLogin') {
      research.error = null;
      history.push('/login');
    } else {
      return (
        <Box style={{ borderRadius: 5, textAlign: 'center', marginTop: 10 }}>
          <div
            dangerouslySetInnerHTML={{
              __html: msg,
            }}
          />
          <div>
            {/* <Link href="/subscriptionPreference/home" to="/subscriptionPreference/home"> */}
            <a href="/subscriptionPreference/home">
              <Button
                // type="submit"
                bsStyle="primary"
                style={{ marginTop: '15px' }}
                // disabled={loading}
              >
                Signup Preferences
              </Button>
            </a>
          </div>
        </Box>
      );
    }
  };
  //=======================M007=========]================

  React.useEffect(() => {
    const queryParams = qs.parse(history.location.search.replace('?', ''));
    if (Object.keys(queryParams).indexOf('confirmDevice') !== -1) {
      const popUpColor = { background: '#337ab7', text: '#FFFFFF' };
      notify.show('Thank you! Your device has been verified.', 'custom', 5000, popUpColor);
    }
  }, [history.location.search]);
  //====================M003=====[====================

  //====================M004=====[====================
  React.useEffect(() => {
    const queryParams = qs.parse(history.location.search.replace('?', ''));
    if (Object.keys(queryParams).indexOf('linkExpired') !== -1) {
      const popUpColor = { background: '#337ab7', text: '#FFFFFF' };
      notify.show(
        'Your authentication link is expired. Please verify your device again.',
        'custom',
        5000,
        popUpColor
      );
    }
  }, [history.location.search]);
  //====================M004=====[====================

  setInterval(function () {
    handleResize();
  }, 1000);

  React.useEffect(() => {
    getParameter('rw_CNTBkTalkToThe,bEnableConnectBookings');
    window.addEventListener('resize', handleResize);
  }, []);

  //FIRST ON LOAD REPORT
  //====================M001=====[====================
  React.useEffect(() => {
    if (!research.loading) {
      getResearch({
        id,
        url: url.current,
        userSpyderId: userSpyderId.current,
        docSpyderId: docSpyderId.current,
        accessKey: accessKey.current,
      });
    }
    //====================M001=====[====================
    getRelatedResearch({ relatedReportID: id }); //M009
  }, [id]);

  React.useEffect(() => {
    if (research.data) {
      setSaved(research.data.saved);
      if (research.data.type == 'highlighted') {
        setIsHighlighted(true);
      }
    }
  }, [research.data, id]);

  React.useEffect(() => {
    if (user_error) {
      setLogInMsg(user_error);
    }
  });

  if (id === '101010') {
    history.push('/');
    window.open(
      'https://aletheiacap.sharepoint.com/:v:/s/Webcasts/Eftax9kE0C9Krt8VE6K6rJUBgQq0AGzJzaK0losFpqr6_g'
    );
    return null;
  }
  if (research.loading) {
    return <Box loading />;
  }

  //=================M005,M006===========[===============
  if (research.data && id != null) {
    if (research.message && !research.error && id.indexOf(research.data.id) > 0) {
      var div = document.createElement('div');
      div.innerHTML = research.message;
      const popUpColor = { background: '#337ab7', text: '#FFFFFF' };
      notify.show(
        <div
          className="notify-box-msg"
          dangerouslySetInnerHTML={{
            __html: research.message,
          }}
        />,
        'custom',
        10000,
        popUpColor
      );
    }
  }
  //=================M005,M006===========[===============
  // const iEVersion = getIEVersion();
  const iEVersion = false;

  return (
    <>
      <Notifications options={{ top: '10%' }} /> {/* //M003*/}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{research.data && research.data.title}</title>
        <meta name="description" content={research.data && research.data.description} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {/* //====================M001=====[==================== */}
      {/* Error Message */}
      {research.error ? (
        EPDF ? (
          // External PDF
          //====================M003=====[====================
          research.error.message === 'createNewCookie' ? (
            <CookieForm
              onSubmit={obj => deviceVerify(obj)}
              initialValues={{ email: research.error.user.sEmail }}
              message={viewCookie.message}
              details={research.error}
              loading={viewCookie.loading}
              userMessage={logInMsg}
            />
          ) : (
            //====================M003=====[====================
            <Box style={{ borderRadius: 5, textAlign: 'center', marginTop: 10 }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: research.error,
                }}
              />
              <div>
                {/* <Link href="/subscriptionPreference/home" to="/subscriptionPreference/home"> */}
                <a href="/subscriptionPreference/home">
                  <Button
                    // type="submit"
                    bsStyle="primary"
                    style={{ marginTop: '15px' }}
                    // disabled={loading}
                  >
                    Signup Preferences
                  </Button>
                </a>
              </div>
            </Box>
          )
        ) : // Normal Access From Website
        //====================M001=====[====================
        research.data && research.data.id ? (
          <>
            <div className="user-no-access-report-card">
              <ReportCard
                key="macro_highlighted_research_er"
                research={{
                  ...research.data,
                  title: (
                    <>
                      <Link
                        to={
                          research.data.video
                            ? `/connect/videos/${research.data.video}`
                            : `/reports/${
                                research.data.id.indexOf('H-') >= 0
                                  ? research.data.id
                                  : 'S-' + research.data.id
                              }_${sanitizeText(research.data.title)}`
                        }
                        className="card-title"
                      >
                        {research.data.title}
                      </Link>
                      {research.data.analysts[0] && (
                        <small>
                          {` by `}
                          <Link
                            to={`/analysts/${research.data.analysts[0].id}`}
                            className="card-title"
                          >
                            {research.data.analysts[0].name}
                          </Link>
                        </small>
                      )}
                    </>
                  ),
                  published_date: getStandardDateTime(research.data.published_date),
                }}
                style={{ margin: '4px 0px', borderRadius: '5px' }}
                showAvatar
              />
            </div>
            <Box style={{ borderRadius: 5, textAlign: 'center', marginTop: 10 }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: research.error,
                }}
              />
              <div>
                {/* <Link href="/subscriptionPreference/home" to="/subscriptionPreference/home"> */}
                <a href="/subscriptionPreference/home">
                  <Button
                    // type="submit"
                    bsStyle="primary"
                    style={{ marginTop: '15px' }}
                    // disabled={loading}
                  >
                    Signup Preferences
                  </Button>
                </a>
              </div>
            </Box>
          </>
        ) : (
          //M007
          errorWebPortalAccess(research.error)
        )
      ) : (
        // Normal Report Handling
        <Row>
          <Col className="pdf-col" md={12} lg={relatedSection ? 9 : 12} id="pdf-viewer-box">
            <Box className="pdf-viewer-section">
              {url.current ? (
                <>
                  <div className="section-header-expanded" style={{ justifyContent: 'flex-end' }}>
                    <div style={{ display: 'flex' }}>
                      {iEVersion && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip style={{ zIndex: 60000 }} id="new-tab-tooltip">
                              Click this button to view this report in a separate tab
                            </Tooltip>
                          }
                        >
                          <div className="open-report-button-section-external-pdf">
                            <Button bsStyle="primary open-report" onClick={() => window.open(path)}>
                              Open Report
                            </Button>
                          </div>
                        </OverlayTrigger>
                      )}
                      <span className="pull-right" style={{ marginLeft: 10, marginRight: 5 }}>
                        {externalPdfFullScreen ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="copy-tooltip" style={{ zIndex: 60000 }}>
                                Exit Full Screen
                              </Tooltip>
                            }
                          >
                            <Button
                              bsSize="xs"
                              bsStyle="primary"
                              className="pull-right expand"
                              onClick={() => {
                                setExternalPdfFullScreen(false);
                                setComponentLayout(false);
                                const mainBody = document.getElementById('main-body');
                                if (mainBody) {
                                  mainBody.style.margin = '80px 0px';
                                }
                              }}
                            >
                              <Image
                                alt="exit full screen icon"
                                style={{ width: 22 }}
                                src={exitFullScreenIcon}
                              />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip style={{ zIndex: 60000 }} id="copy-tooltip">
                                Full Screen
                              </Tooltip>
                            }
                          >
                            <Button
                              bsSize="xs"
                              bsStyle="primary"
                              className="pull-right expand"
                              onClick={() => {
                                setComponentLayout(true);
                                setExternalPdfFullScreen(true);
                                const mainBody = document.getElementById('main-body');
                                if (mainBody) {
                                  mainBody.style.margin = isIE ? '20px 0px' : '15px 0px';
                                }
                              }}
                            >
                              <Image
                                alt="full screen icon"
                                style={{ width: 22 }}
                                src={fullScreenIcon}
                              />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <div className={relatedSection ? 'section-header' : 'section-header-expanded'}>
                  {/* LINK AND AUTHOR INFO */}
                  <div style={{ display: 'flex' }}>
                    {/* <Link href="/home" to="/home" className="back-button" title="Back"> */}
                    <Link className="back-button" title="Back" onClick={() => history.go(-1)}>
                      {' '}
                      {/*M008*/}
                      <Glyphicon glyph="circle-arrow-left" />
                    </Link>
                    <div className="title-section" style={{ maxWidth: isIE ? 'none' : '80%' }}>
                      <h1>{title}</h1>
                    </div>
                    <>
                      {analysts && analysts[0] ? (
                        <div className="analyst-name-section">
                          by
                          <Link
                            href={analysts && `/analysts/${analysts[0].id}`}
                            to={analysts && `/analysts/${analysts[0].id}`}
                          >
                            {' '}
                            {analysts && analysts[0].name}{' '}
                          </Link>
                          {parameters?.bEnableConnectBookings == '1' ? (
                            <Link
                              to={`../connect/appointment/${analysts[0].id}`}
                              href={`../connect/appointment/${analysts[0].id}`}
                            >
                              <span style={{ fontSize: '12px', color: '#00b0f0' }}>
                                {parameters?.rw_CNTBkTalkToThe}
                              </span>
                            </Link>
                          ) : null}
                        </div>
                      ) : null}
                    </>
                  </div>
                  {/* LINK AND AUTHOR INFO */}

                  {/* RIGHT BUTTON */}
                  <div style={{ display: 'flex' }}>
                    <span className="pull-right">
                      <div className="buttons-box">
                        {/* SAVE REPORT BUTTON */}
                        {!EPDF && (
                          <span className="pull-right" style={{ marginLeft: 10, marginRight: 5 }}>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="bookmark-tooltip">
                                  {saved ? 'Unsave Report' : 'Save Report'}
                                </Tooltip>
                              }
                            >
                              {saved ? (
                                <Button
                                  bsSize="xs"
                                  bsStyle="primary"
                                  className="pull-right expand"
                                  onClick={SaveReports}
                                >
                                  <img src={starFilled} style={{ width: 22 }} />
                                </Button>
                              ) : (
                                <Button
                                  bsSize="xs"
                                  bsStyle="primary"
                                  className="pull-right copy"
                                  onClick={SaveReports}
                                >
                                  <Glyphicon glyph="glyphicon glyphicon-star-empty" size={22} />
                                </Button>
                              )}
                            </OverlayTrigger>
                          </span>
                        )}
                        {/* SAVE REPORT BUTTON */}

                        {/* SOCIAL MEDIA BUTTON */}
                        {user != null && user.type == 'admin' ? (
                          <span className="pull-right" style={{ marginLeft: 10, marginRight: 5 }}>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="socialMedia-tooltip">
                                  {SocialMediaTiles
                                    ? 'Disable Social Media Tiles View'
                                    : 'Enable Social Media Tiles View'}
                                </Tooltip>
                              }
                            >
                              <Button
                                bsSize="xs"
                                bsStyle="primary"
                                className="pull-right copy"
                                onClick={() => setSocialMediaTiles(!SocialMediaTiles)}
                              >
                                <Glyphicon glyph="glyphicon glyphicon-picture" />
                              </Button>
                            </OverlayTrigger>
                          </span>
                        ) : null}
                        {/* SOCIAL MEDIA BUTTON */}

                        {/* HIGHLIGHT REPORT BUTTON */}
                        {user != null && user.type == 'admin' && !EPDF ? (
                          <span className="pull-right" style={{ marginLeft: 10, marginRight: 5 }}>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="highlight-tooltip">
                                  {isHighlighted ? 'Unhighlight Report' : 'Highlight Report'}
                                </Tooltip>
                              }
                            >
                              <Button
                                bsSize="xs"
                                bsStyle="primary"
                                className="pull-right copy"
                                onClick={highlightReportHandler}
                              >
                                {isHighlighted ? (
                                  <Glyphicon
                                    glyph={'glyphicon glyphicon-eye-close'}
                                    style={{ color: '#ffec00' }}
                                  />
                                ) : (
                                  <Glyphicon glyph={'glyphicon glyphicon-eye-open'} />
                                )}
                              </Button>
                            </OverlayTrigger>
                          </span>
                        ) : null}
                        {/* HIGHLIGHT REPORT BUTTON */}

                        {/* COPY LINK BUTTON */}
                        {document.queryCommandSupported('copy') && (
                          <span className="pull-right" style={{ marginLeft: 10, marginRight: 5 }}>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="copy-tooltip">Copy Link</Tooltip>}
                            >
                              <Button
                                bsSize="xs"
                                bsStyle="primary"
                                className="pull-right copy"
                                onClick={e => copyLinkToClipboard(window.location.href, e)}
                              >
                                <Glyphicon glyph="file" />
                              </Button>
                            </OverlayTrigger>
                          </span>
                        )}
                        {/* COPY LINK BUTTON */}

                        {/* FULL SCREEN BUTTON */}
                        <span className="pull-right" style={{ marginLeft: 10, marginRight: 5 }}>
                          {relatedSection ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip style={{ zIndex: 60000 }} id="copy-tooltip">
                                  Full Screen
                                </Tooltip>
                              }
                            >
                              <Button
                                bsSize="xs"
                                bsStyle="primary"
                                className="pull-right expand"
                                onClick={() => {
                                  const mainBody = document.getElementById('main-body');

                                  if (relatedSection) {
                                    setRelatedSection(false);
                                    setComponentLayout(true);
                                    if (mainBody) {
                                      mainBody.style.margin = '15px 0px';
                                    }
                                  } else {
                                    setRelatedSection(true);
                                    setComponentLayout(false);
                                    if (mainBody) {
                                      mainBody.style.margin = '80px 0px';
                                    }
                                  }
                                }}
                              >
                                <Image
                                  alt="full screen icon"
                                  style={{ width: 22 }}
                                  src={fullScreenIcon}
                                />
                              </Button>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="copy-tooltip" style={{ zIndex: 60000 }}>
                                  Exit Full Screen
                                </Tooltip>
                              }
                            >
                              <Button
                                bsSize="xs"
                                bsStyle="primary"
                                className="pull-right expand"
                                onClick={() => {
                                  const mainBody = document.getElementById('main-body');

                                  if (relatedSection) {
                                    setRelatedSection(false);
                                    setComponentLayout(true);
                                    if (mainBody) {
                                      mainBody.style.margin = '15px 0px';
                                    }
                                  } else {
                                    setRelatedSection(true);
                                    setComponentLayout(false);
                                    if (mainBody) {
                                      mainBody.style.margin = '80px 0px';
                                    }
                                  }
                                }}
                              >
                                <Image
                                  style={{ width: 22 }}
                                  alt="exit full screen icon"
                                  src={exitFullScreenIcon}
                                />
                              </Button>
                            </OverlayTrigger>
                          )}
                        </span>
                        {/* FULL SCREEN BUTTON */}
                      </div>
                    </span>
                  </div>
                  {/* RIGHT BUTTON */}
                </div>
              )}
              {path && (
                <Viewer
                  type={filetype}
                  path={`${path}`}
                  title={title}
                  externalPdfView={EPDF}
                  fullScreenMode={fullScreenMode}
                  role={user ? user.type : 'npi'}
                  SocialMediaViewer={SocialMediaTiles}
                />
              )}
            </Box>
          </Col>

          {/* Related Reports */}
          {relatedSection && (
            <Col md={12} lg={3} className="related-reports-section">
              <Box style={{ padding: 0, borderRadius: '5px' }}>
                <div
                  style={{
                    background: '#036ca6',
                    fontSize: '115%',
                    padding: '3%',
                    color: 'white',
                    fontWeight: 700,
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                  }}
                >
                  Related Reports
                </div>
                <div className="related-reports-box" style={{ height: divHieght }}>
                  {relatedResearches.data.slice(0, 20).map((item, i) => (
                    <ReportCard
                      key={`macro_highlighted_research_${item.id}_${i}`}
                      research={{
                        ...item,
                        title: (
                          <>
                            <Link
                              href={
                                item.video
                                  ? `/connect/videos/${item.video}`
                                  : `/reports/${item.id}_${sanitizeText(item.title)}`
                              }
                              to={
                                item.video
                                  ? `/connect/videos/${item.video}`
                                  : `/reports/${item.id}_${sanitizeText(item.title)}`
                              }
                              className="card-title"
                            >
                              {item.title}
                            </Link>
                            {item.analysts[0] && (
                              <small>
                                {` by `}
                                <Link
                                  to={`/analysts/${item.analysts[0].id}`}
                                  className="card-title"
                                >
                                  {item.analysts[0].name}
                                </Link>
                              </small>
                            )}
                          </>
                        ),
                        published_date: getStandardDateTime(item.published_date, { part: 'date' }),
                      }}
                      style={{ margin: '4px 0px', borderRadius: '5px' }}
                      showAvatar={false}
                    />
                  ))}
                </div>
              </Box>
            </Col>
          )}
          {/* Related Reports */}
        </Row>
      )}
    </>
  );
};

const mapStateToProps = ({
  research: {
    research,
    latestResearches,
    relatedResearches,
    viewCookie,
    savedReports,
    highlightedResearches,
  },
  user: {
    currentUser: { data: user },
    error: user_error,
  },

  Config: { fullScreenMode, parameters },
}) => ({
  research,
  user,
  latestResearches,
  relatedResearches,
  fullScreenMode,
  viewCookie,
  user_error,
  savedReports,
  highlightedResearches,
  parameters,
}); //M003

const mapDispatchToProps = dispatch => ({
  getResearch: bindActionCreators(researchActions.getResearch, dispatch),
  showUserModal: bindActionCreators(userModalActions.show, dispatch),
  getRelatedResearch: bindActionCreators(researchActions.getRelatedReports, dispatch),
  setComponentLayout: bindActionCreators(configActions.setComponentLayout, dispatch),
  getViewReportCookie: bindActionCreators(researchActions.getViewReportCookie, dispatch), //M003
  SaveReport: bindActionCreators(researchActions.SaveReport, dispatch),
  GetSavedReports: bindActionCreators(researchActions.GetSavedReports, dispatch),
  logIn: bindActionCreators(userActions.logIn, dispatch), //M003
  highlightReport: bindActionCreators(researchActions.highlightReport, dispatch),
  getHighlightedResearches: bindActionCreators(researchActions.getHighlighted, dispatch),
  getParameter: bindActionCreators(configActions.getParameter, dispatch),
});

Container.propTypes = {
  research: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object,
    progress: PropTypes.number,
    error: PropTypes.string,
  }).isRequired,
  relatedResearches: PropTypes.object.isRequired,
  getResearch: PropTypes.func.isRequired,
  getRelatedResearch: PropTypes.func.isRequired,
  savedReports: PropTypes.object.isRequired,
  SaveReport: PropTypes.func.isRequired,
  GetSavedReports: PropTypes.func.isRequired,
  setComponentLayout: PropTypes.func.isRequired,
  fullScreenMode: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.object,
  getViewReportCookie: PropTypes.func.isRequired,
  viewCookieMsg: PropTypes.string.isRequired, //M003
  logIn: PropTypes.func.isRequired, //M003
  highlightedResearches: PropTypes.object.isRequired,
  highlightReport: PropTypes.func.isRequired,
  getHighlightedResearches: PropTypes.func.isRequired,
};

Container.defaultProps = {
  user: null,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
