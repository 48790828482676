import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import qs from 'querystring';

import ReportsContainer from '../../containers/Reports';

const Container = ({ location: { pathname } }) => {
  const querystring = pathname.split('/')[2];
  return <ReportsContainer q={qs.parse(querystring).q} showHighlighted />;
};

Container.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Container);
