/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 14 Jan 2020 : Nick
    * M-0003218: Must Read section to be shown in every research product page
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import groupBy from 'lodash/groupBy';
import { Row, Col, Tabs, Tab, Nav, NavItem } from 'react-bootstrap';

import * as analystActions from '../../actionCreators/Analyst';
import * as sectorActions from '../../actionCreators/Sector';

import { doGet } from '../../utils/request';

const OurTeamBox = React.lazy(() => import('./components/OurTeamBox'));
const ChartTab = React.lazy(() => import('./components/ChartTab'));
const ReportsContainer = React.lazy(() => import('../../containers/Reports'));

const Container = ({ analysts, sector, location: { pathname }, getAnalysts, getSector }) => {
  const id = pathname.split('/')[2].split('_')[0];
  const [charts, setCharts] = React.useState([]);
  const [activeKey, setActiveKey] = React.useState(1);
  const [activeChartCategory, setActiveChartCategory] = React.useState();
  React.useEffect(() => {
    async function getCharts() {
      const response = await doGet(`/keycharts?sectorId=${id}`);
      setCharts(response.data);

      if (response.data[0]) {
        setActiveChartCategory('Global IP');
        // setActiveChartCategory(response.data[0].category);
      }
    }

    if (!analysts.data.length && !analysts.data.loading) {
      getAnalysts();
    }

    getCharts();
    getSector({ id });
  }, [id, getAnalysts, getSector]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{sector.data && `${sector.data.sName}`}</title>
        <meta
          name="description"
          content={`Here all reports are related to ${sector.data && sector.data.sName} sector`}
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <React.Suspense fallback={<></>}>
        <OurTeamBox
          title={sector.data && `${sector.data.sName} Team`}
          className="our-team-box"
          analysts={analysts.data.filter(analyst => analyst.sectors.find(s => String(s) === id))}
          loading={analysts.loading || sector.loading}
        />
        {id === '10000131' ? (
          <Tabs id="sectors_tabs" activeKey={activeKey} onSelect={key => setActiveKey(key)}>
            <Tab
              title="Reports"
              eventKey={1}
              style={{
                borderLeft: '1px solid #ddd',
                borderRight: '1px solid #ddd',
                borderBottom: '1px solid #ddd',
              }}
            >
              <div style={{ padding: 10 }}>
                <ReportsContainer sectorId={id} showHighlighted={true} />  {/* M001 */}
              </div>
            </Tab>
            <Tab
              title="Charts"
              eventKey={2}
              disabled={!charts.length}
              style={{
                borderLeft: '1px solid #ddd',
                borderRight: '1px solid #ddd',
                borderBottom: '1px solid #ddd',
              }}
            >
              <div style={{ padding: 10 }}>
                <Row className="global-charts-wells">
                  <Col xs={4} sm={3} lg={2}>
                    <Nav bsStyle="pills" stacked>
                      {Object.keys(groupBy(charts, 'category'))
                        .sort()
                        .map((key, index) => (
                          <NavItem
                            key={`sector_chart_category_${index}`}
                            active={key === activeChartCategory}
                            onSelect={() => setActiveChartCategory(key)}
                          >
                            {key}
                          </NavItem>
                        ))}
                    </Nav>
                  </Col>
                  <Col xs={8} sm={9} lg={10}>
                    <Row>
                      {charts
                        .filter(
                          chart => !activeChartCategory || chart.category === activeChartCategory
                        )
                        .map((chart, index) => (
                          <Col key={`sector_chart_${index}`} xs={12} sm={4} lg={3}>
                            <ChartTab data={chart} />
                          </Col>
                        ))}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Tab>
          </Tabs>
        ) : (
          <div style={{ padding: 10 }}>
            <ReportsContainer sectorId={id} showHighlighted={true} /> {/* M001 */}
          </div>
        )}
      </React.Suspense>
    </>
  );
};

const mapStateToProps = ({ analyst: { analysts }, sector: { sector } }) => ({ analysts, sector });

const mapDispatchToProps = dispatch => ({
  getAnalysts: bindActionCreators(analystActions.getAll, dispatch),
  getSector: bindActionCreators(sectorActions.getSector, dispatch),
});

Container.propTypes = {
  getAnalysts: PropTypes.func.isRequired,
  getSector: PropTypes.func.isRequired,
  analysts: PropTypes.object.isRequired,
  sector: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Container)
);
