import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Row, Form, FormGroup, Button, Glyphicon } from 'react-bootstrap';
import Box from '../../components/Box';
import Throbber from '../../components/Throbber';
import '../../assets/sass/admin-panel.scss';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { doPost } from '../../utils/request';
import * as firmActions from '../../actionCreators/OurFirm';
import Notifications, { notify } from 'react-notify-toast';

const Container = ({ loading, getContent, updateContent, content }) => {
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const onEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  React.useEffect(() => {
    getContent();
  }, []);

  React.useEffect(() => {
    if (content) {
      setEditorState(EditorState.createWithContent(convertFromRaw(content)));
    }
  }, [content]);

  const uploadCallback = image => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append('file', image);
      doPost('/posts/uploadImage', data)
        .then(response => resolve({ data: { link: response.data && response.data.path } }))
        .catch(err => reject(err));
    });
  };
  return (
    <div className="ourFirmEditor">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Firm Management</title>
      </Helmet>
      <Row className="box-upload-image" style={{ marginBottom: '3%' }}>
        <Box className="box-upload-image text-center">
          <h4> Edit Our Firm Page </h4>
          {loading ? (
            <Throbber loading style={{ position: 'relative', top: '40%' }} />
          ) : (
            <>
              <Form
                onSubmit={event => {
                  event.preventDefault();
                  const raw = convertToRaw(editorState.getCurrentContent());
                  const data = JSON.stringify(raw);
                  updateContent(data, data);
                  const popUpColor = { background: '#337ab7', text: '#FFFFFF' };
                  notify.show(
                    'Your changes has been saved successfully!',
                    'custom',
                    5000,
                    popUpColor
                  );
                }}
              >
                <FormGroup controlId="formInlineName">
                  <Row>
                    <div>
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        stripPastedStyles={true}
                        toolbar={{
                          image: {
                            urlEnabled: true,
                            uploadEnabled: true,
                            previewImage: true,
                            uploadCallback: uploadCallback,
                            alignmentEnabled: true,
                            defaultSize: {
                              height: 'auto',
                              width: '400px',
                            },
                          },
                          colorPicker: {
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                            colors: ['rgb(19,108,167)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                              'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                              'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                              'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                              'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                              'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
  
                          },
                          emoji: false,
                        }}
                        
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                      >
                        <textarea
                          disabled
                          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                        />
                      </Editor>
                    </div>
                  </Row>
                  <Row className="save-button-row">
                    <div className="save-button">
                      <Button
                        type="submit"
                        bsStyle="primary"
                        disabled={!editorState.getCurrentContent().hasText()}
                      >
                        Save
                      </Button>
                    </div>
                  </Row>
                </FormGroup>
              </Form>
            </>
          )}
        </Box>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  const content = state.ourfirm.content.data;
  return { content };
}

const mapDispatchToProps = dispatch => ({
  getContent: bindActionCreators(firmActions.getContent, dispatch),
  updateContent: bindActionCreators(firmActions.updateContent, dispatch),
});

Container.propTypes = {
  getContent: PropTypes.func.isRequired,
  updateContent: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
