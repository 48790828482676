/*
**************************************************************************************************************
												R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author	:	Nick Ng
	Date	:	18 Oct 2022
**************************************************************************************************************
												Usage Notes
**************************************************************************************************************

**************************************************************************************************************
												Amendments
**************************************************************************************************************
	M001 : 18 Oct 2022 : Nick Ng : Core
		 * M-4172: Fix Must Read and Free Access buttons
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Row, Glyphicon } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import ReportCard from '../../containers/Reports/components/ReportCard';
import Card from '../../components/Card';
import { usePreviousProps, sanitizeText } from '../../utils';
import { Link, withRouter } from 'react-router-dom';
import { getStandardDateTime } from '../../utils/dateTime';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import Box from '../../components/Box';
import List from '../../components/List';
import Confirm from '../../components/Confirm';
import { reportLabels } from './config';
import ReportPage from '../../containers/Reports';
import PageHeader from '../../components/PageHeader';

import * as reportActions from '../../actionCreators/Research';

class Container extends React.Component {
  constructor() {
    super();
    this.state = {
      showConfirm: false,
      TempReportID: '',
      TempReportStatus: '',
      paginatedData: [],
      page: 0,
      ResearchesData: [],
    };
  }

  componentDidMount() {
    const { getHighlightedResearches } = this.props; //M001
    getHighlightedResearches();
  }

  handleHighlightReport = obj => {
    const { highlightReport } = this.props; //M001

    //M001
    highlightReport({
      id: obj.id
    });
  };

  handleTempReport = (obj, status) => {
    this.setState(
      {
        TempReportID: obj.id,
        TempReportStatus: status,
      },
      () =>
        this.setState({
          showConfirm: true,
        })
    );
  };

  confirmTempReport = () => {
    const { tempReport } = this.props; //M001
    tempReport({
      id: this.state.TempReportID,
    });
    this.setState({
      showConfirm: false,
    });
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Reports Management</title>
        </Helmet>
        <PageHeader
          pageName="Reports Management"
          pageDesc=""
        />
        <br/>
        <Confirm
          show={this.state.showConfirm}
          message={`Are you going to ${this.state.TempReportStatus} this report ${
            this.state.TempReportStatus == 'remove'
              ? `from selected report`
              : `into selected report`
          }`}
          subHeading=""
          cancelText="Cancel"
          confirmText="Yes"
          onCancel={() => this.setState({ showConfirm: false })}
          onConfirm={this.confirmTempReport}
        />
        <ReportPage
          showHighlighted={true}
          Bookmark={false}
          isManagement={true}
          mgtAddTemp={this.handleTempReport}
          mgtAddMustRead={this.handleHighlightReport}
          showSwitchView={false}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  research: { researches, highlightedResearches, tempResearches, reportManagementResearches },
}) => ({
  researches,
  highlightedResearches,
  tempResearches,
  reportManagementResearches,
});

const mapDispatchToProps = dispatch => ({
  getAllReports: bindActionCreators(reportActions.reportManagementGetAll, dispatch),
  highlightReport: bindActionCreators(reportActions.highlightReport, dispatch),
  tempReport: bindActionCreators(reportActions.tempReport, dispatch),
  getHighlightedResearches: bindActionCreators(reportActions.getHighlighted, dispatch),
  fetchLatestDoc: bindActionCreators(reportActions.fetchLatestDoc, dispatch),
  getTempReports: bindActionCreators(reportActions.getTempReports, dispatch),
});

Container.propTypes = {
  researches: PropTypes.object.isRequired,
  reportManagementResearches: PropTypes.object.isRequired,
  getAllReports: PropTypes.func.isRequired,
  highlightedResearches: PropTypes.object.isRequired,
  highlightReport: PropTypes.func.isRequired,
  tempReport: PropTypes.func.isRequired,
  showHighlighted: PropTypes.bool,
  getHighlightedResearches: PropTypes.func.isRequired,
  fetchLatestDoc: PropTypes.func.isRequired,
  tempResearches: PropTypes.object.isRequired,
  getTempReports: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
