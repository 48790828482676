import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import Marquee from '../../../components/Marquee';

import { sanitizeText } from '../../../utils';
import { doGet } from '../../../utils/request';

const Component = ({ homePageLatestResearches, currentUser, history }) => {
  const [isSafariOrFirefox, setIsSafariOrFirefox] = React.useState(false);

  const checkIfSafariOrFirefox = () => {
    // Detecting Browser by duck typing
    let isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
    if (isSafari) {
      if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
        isSafari = false;
      }
    }
    const isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox || isSafari) {
      return true;
    }
    return false;
  };

  const getReportInfo = async id => {
    const response = await doGet(`/reports/${id}`);
    return response.data.report.path;
  };

  const onLinkClick = item => {
    const windowNew = window.open();
    getReportInfo(item.id)
      .then(path => {
        windowNew.location = path;
      })
      .catch(() => {
        windowNew.close();
        history.push(`/reports/${item.id}_${sanitizeText(item.title)}`);
      });
  };

  React.useEffect(() => {
    setIsSafariOrFirefox(checkIfSafariOrFirefox());
  }, []);

  if (homePageLatestResearches.data.length) {
    return (
      <div className="ticker-section">
        <Marquee
          className="ticker"
          hoverToStop="true"
          text={homePageLatestResearches.data.map((report, index) => {
            return (
              <div class="ticker-report-div" key={`val_${index}`}>
                <span className="dot" />
                {report.sectors[0] && (
                  <span>
                    <Link
                      to={`/sectors/${report.sectors[0].id}_${sanitizeText(
                        report.sectors[0].sName
                      )}`}
                      className="ticker-sector"
                    >
                      {report.sectors[0].sName}
                    </Link>
                  </span>
                )}
                {'   '}
                {report.companies[0] && (
                  <span>
                    <Link to={`/companies/${report.companies[0].id}`} className="ticker-company">
                      {report.companies[0].display || report.companies[0].name}
                    </Link>
                  </span>
                )}
                {'   '}
                {currentUser.data && isSafariOrFirefox ? (
                  <span>
                    <Link
                      href={
                        report.video
                          ? `/connect/videos/${report.video}`
                          : `/reports/${report.id}_${sanitizeText(report.title)}`
                      }
                      to={
                        report.video
                          ? `/connect/videos/${report.video}`
                          : `/reports/${report.id}_${sanitizeText(report.title)}`
                      }
                      className="ticker-report-title"
                    >
                      {report.title}
                    </Link>
                  </span>
                ) : (
                  <span>
                    <Link
                      href={
                        report.video
                          ? `/connect/videos/${report.video}`
                          : `/reports/${report.id}_${sanitizeText(report.title)}`
                      }
                      to={
                        report.video
                          ? `/connect/videos/${report.video}`
                          : `/reports/${report.id}_${sanitizeText(report.title)}`
                      }
                      className="ticker-report-title"
                    >
                      {report.title}
                    </Link>
                  </span>
                )}
                {'   '}
                {report.analysts[0] && report.analysts[0].name && 'by   '}
                <span>
                  <Link
                    to={`/analysts/${
                      report.analysts[0] ? report.analysts[0].id : null
                    }_${sanitizeText(report.analysts[0] && report.analysts[0].name)}`}
                    href={`/analysts/${report.analysts[0] ? report.analysts[0].id : null}`}
                    className="ticker-analyst"
                  >
                    {report.analysts[0] && report.analysts[0].name}
                  </Link>
                </span>
              </div>
            );
          })}
        />
      </div>
    );
  }
  return null;
};

const mapStateToProps = ({ research: { homePageLatestResearches }, user: { currentUser } }) => ({
  homePageLatestResearches,
  currentUser,
});

Component.propTypes = {
  homePageLatestResearches: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps)(Component));
