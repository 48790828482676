import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as configActions from '../../actionCreators/Config';
import parse from 'html-react-parser';

const CustomPage = ({ getParameter, content, getCustomPageParameter, customContent }) => {
  const location = useLocation();

  let urlParameters = location.pathname.split('/');
  var id = urlParameters[2];
  console.log(id);

  const [pageHTML, setPageHTML] = useState('');

  React.useEffect(() => {
    getParameter(id);
    // getCustomPageParameter(id);
  }, [id]);

  React.useEffect(() => {
    if (content != null && content[id]) {
      const parsedHTML = parse(content[id]);
      setPageHTML(parsedHTML);
    } else {
      console.log('else entered');
    }
  }, [content]);
  return (
    <>
      {pageHTML != '' ? pageHTML : null}
      {/* <div>index</div> */}
    </>
  );
};

function mapStateToProps(state) {
  const content = state.Config.parameters;
  const customContent = state.Config.customPageParameter;
  return { content, customContent };
}

const mapDispatchToProps = dispatch => ({
  getParameter: bindActionCreators(configActions.getParameter, dispatch),
  getCustomPageParameter: bindActionCreators(configActions.getCustomPageParameter, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomPage));
