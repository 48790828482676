/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 21 August 2020 : Nicho
		  * M-0002989: Clicking on a particular topic should show any previous blog posts
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Row,
  Form,
  FormGroup,
  Button,
  Glyphicon,
  Col,
  FormControl,
  ControlLabel,
  Tab,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';

import Box from '../../components/Box';
import Throbber from '../../components/Throbber';
import '../../assets/sass/admin-panel.scss';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
// import { OnMobile, OnAtLeastTablet } from '../../components/ReactBreak';

import * as configActions from '../../actionCreators/Config';
import { doPost } from '../../utils/request';

import Confirm from '../../components/Confirm';
import EditPost from '../../components/EditPost';

import '../../assets/sass/blogs.scss';

const Container = ({
  createPost,
  getPosts,
  loading,
  posts,
  currentUser,
  deletePost,
  updatePost,
  showEditPopUp,
  showEdit,
  publishPost,
  unPublishPost,
  uniqueKey,
  topic,
  showError,
}) => {
  const user = currentUser && currentUser.data;
  const [tab, setTab] = React.useState('Published');
  React.useEffect(() => {
    if (user) {
      getPosts({ id: user.id, key: uniqueKey });
    }
  }, [uniqueKey]);

  React.useEffect(() => {
    if (posts) {
      setEditorState(EditorState.createEmpty());
    }
  }, [posts]);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [html, setHtml] = React.useState();
  const [post, setPost] = React.useState();
  const onEditorStateChange = editorState => {
    setEditorState(editorState);
  };
  //====================M001==========================
  const [anyPublishedPost, setAnyPublishedPost] = React.useState(false);
  const publishedPostExist = () =>
    anyPublishedPost ? setAnyPublishedPost(false) : setAnyPublishedPost(true);
  //====================M001==========================
  const uploadCallback = image => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append('file', image);
      doPost('/posts/uploadImage', data)
        .then(response => resolve({ data: { link: response.data && response.data.path } }))
        .catch(err => reject(err));
    });
  };

  const textToHTML = text => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(text, 'text/html');
    return doc.body;
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs Management</title>
      </Helmet>
      <EditPost
        postId={post}
        show={showEdit}
        html={html}
        onCancel={() => showEditPopUp(false)}
        update={(html, draft, title) => {
          if (uniqueKey) {
            updatePost({
              postId: post,
              html,
              draft,
              key: uniqueKey,
              title: title,
            });
            setTimeout(()=>{
              getPosts({ id: user.id, key: uniqueKey });
            },3000);
          } else {
            showError(true);
          }
        }}
        oldTitle={title}
      />
      <Confirm
        show={showConfirm}
        message="Are you sure you want to delete this post?"
        subHeading={null}
        onConfirm={() => {
          setShowConfirm(false);
          deletePost({ id: post, user_id: user.id, key: uniqueKey });
        }}
        onCancel={() => setShowConfirm(false)}
      />
      <Row className="box-upload-image" style={{ marginBottom: '3%' }}>
        <Box className="box-upload-image text-center">
          <h4> Add New Post </h4>
          {loading ? (
            <Throbber loading style={{ position: 'relative', top: '40%' }} />
          ) : (
            <>
              <Form
                onSubmit={event => {
                  event.preventDefault();
                  const rawDraftContentState = JSON.stringify(
                    convertToRaw(editorState.getCurrentContent())
                  );
                  if (uniqueKey) {
                    createPost({
                      user: user && user.id,
                      html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                      draft: rawDraftContentState,
                      key: uniqueKey,
                      title: title,
                    });
                  } else {
                    showError(true);
                  }
                }}
              >
                <FormGroup controlId="formInlineName">
                  <Row>
                    <div>
                      <Row>
                        <Col xs={4} componentClass={ControlLabel} className="topic-label">
                          Post Title:
                        </Col>
                        <Col xs={7}>
                          <FormControl
                            type="text"
                            placeholder={'Post Title'}
                            required={true}
                            onKeyUp={e => setTitle(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <br />
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        stripPastedStyles={true}
                        toolbar={{
                          image: {
                            urlEnabled: true,
                            uploadEnabled: true,
                            previewImage: true,
                            uploadCallback: uploadCallback,
                            alignmentEnabled: true,
                            defaultSize: {
                              height: 'auto',
                              width: '400px',
                            },
                          },
                          emoji: false,
                        }}
                        wrapperClassName="post-content"
                        editorClassName="post-content"
                        onEditorStateChange={onEditorStateChange}
                      >
                        <textarea
                          disabled
                          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                        />
                      </Editor>
                    </div>
                  </Row>
                  <Row className="save-button-row">
                    <div className="save-button">
                      <Button
                        type="submit"
                        bsStyle="primary"
                        disabled={!editorState.getCurrentContent().hasText()}
                      >
                        Save
                      </Button>
                    </div>
                  </Row>
                </FormGroup>
              </Form>
            </>
          )}
        </Box>
      </Row>

      <Tab.Container id="left-tabs-example" defaultActiveKey={tab}>
        <Tab.Content animation>
          <Tab.Pane eventKey="Published">
            <div className="button-group-box favourites">
              <ButtonToolbar>
                <ToggleButtonGroup type="radio" name="options" defaultValue={tab}>
                  <ToggleButton value="Published" onClick={() => setTab('Published')}>
                    Published
                  </ToggleButton>
                  <ToggleButton value="Drafts" onClick={() => setTab('Drafts')}>
                    Drafts
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </div>
            {tab === 'Published' && (
              <>
                {posts && posts.length > 0 && (
                  <>
                    <div className="title-posts">
                      <h4> Published Posts: </h4>
                    </div>
                    <Row>
                      {posts.map(post => (
                        <>
                          {post.publish && (
                            <Box className="posts-box">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  marginBottom: '10px',
                                }}
                              >
                                <Glyphicon
                                  glyph="glyphicon glyphicon-edit"
                                  className="slider-edit-icon"
                                  style={{
                                    width: '5%',
                                    position: 'relative',
                                    top: '10px',
                                    right: '4px',
                                  }}
                                  onClick={() => {
                                    setHtml(post.draft && post.draft);
                                    showEditPopUp(true);
                                    setPost(post.id);
                                    setTitle(post.title);
                                  }}
                                />
                                {/*====================M001==========================*/}

                                <div className="publish-post-mobile">
                                  <Button
                                    onClick={() => unPublishPost({ id: post.id, key: post.key })}
                                  >
                                    Unpublish Post
                                  </Button>
                                </div>

                                {/*====================M001==========================*/}
                                <Glyphicon
                                  glyph="glyphicon glyphicon-trash"
                                  className="slider-delete-icon"
                                  style={{ width: '5%', position: 'relative', top: '10px' }}
                                  onClick={() => {
                                    setShowConfirm(true);
                                    setPost(post.id);
                                  }}
                                />
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '95%' }}>
                                  <Link
                                    href={`/post/` + post.id}
                                    to={`/post/` + post.id}
                                    className="card-title"
                                  >
                                    <h1>{post.title}</h1>
                                  </Link>
                                </div>
                              </div>
                            </Box>
                          )}
                        </>
                      ))}
                    </Row>
                  </>
                )}
              </>
            )}
            {tab === 'Drafts' && (
              <>
                {posts && posts.length > 0 && (
                  <>
                    <div className="title-posts">
                      <h4> Drafts: </h4>
                    </div>
                    <Row>
                      {posts.map(post => (
                        <>
                          {post.publish == false && (
                            <Box className="posts-box">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  marginBottom: '10px',
                                }}
                              >
                                <Glyphicon
                                  glyph="glyphicon glyphicon-edit"
                                  className="slider-edit-icon"
                                  style={{
                                    width: '5%',
                                    position: 'relative',
                                    top: '10px',
                                    right: '4px',
                                  }}
                                  onClick={() => {
                                    setHtml(post.draft && post.draft);
                                    showEditPopUp(true);
                                    setPost(post.id);
                                    setTitle(post.title);
                                  }}
                                />
                                {/*====================M001==========================*/}

                                <div className="publish-post-mobile">
                                  <Button
                                    onClick={() => publishPost({ id: post.id, key: post.key })}
                                    // onClick={() => {
                                    //   publishPost({ id: post.id, key: post.key });
                                    //   publishedPostExist();
                                    // }}
                                    // disabled={!anyPublishedPost ? " " : null}
                                  >
                                    Publish Post
                                  </Button>
                                  <Link
                                      href={`/post/` + post.id}
                                      to={`/post/` + post.id}
                                      className="card-title"
                                    >
                                    <Button>
                                      Preview
                                    </Button>
                                  </Link>
                                </div>

                                {/*====================M001==========================*/}
                                <Glyphicon
                                  glyph="glyphicon glyphicon-trash"
                                  className="slider-delete-icon"
                                  style={{ width: '5%', position: 'relative', top: '10px' }}
                                  onClick={() => {
                                    setShowConfirm(true);
                                    setPost(post.id);
                                  }}
                                />
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '95%' }}>
                                    <h1>{post.title}</h1>
                                </div>
                              </div>
                            </Box>
                          )}
                        </>
                      ))}
                    </Row>
                  </>
                )}
              </>
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

const mapStateToProps = ({
  Config: {
    posts: {
      loading,
      data: { posts },
    },
    showEdit,
  },
  user: { currentUser },
}) => ({ loading, posts, currentUser, showEdit });

const mapDispatchToProps = dispatch => ({
  createPost: bindActionCreators(configActions.createPost, dispatch),
  getPosts: bindActionCreators(configActions.getPosts, dispatch),
  deletePost: bindActionCreators(configActions.deletePost, dispatch),
  updatePost: bindActionCreators(configActions.updatePost, dispatch),
  publishPost: bindActionCreators(configActions.publishPost, dispatch),
  unPublishPost: bindActionCreators(configActions.unPublishPost, dispatch),
  showEditPopUp: bindActionCreators(configActions.showEditPopUp, dispatch),
});

Container.propTypes = {
  history: PropTypes.object.isRequired,
  posts: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  uniqueKey: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
  showError: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
