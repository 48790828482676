/*
**************************************************************************************************************
												R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author	:	Nick Ng
	Date	:	10 Oct 2022
**************************************************************************************************************
												Usage Notes
**************************************************************************************************************

**************************************************************************************************************
												Amendments
**************************************************************************************************************
	M006 : 18 Jul 2023 : Carl Chan : Core
		 * M-4464: Upload image for the home page slider glitch
  M005 : 10 Oct 2022 : Nick Ng : Core
		 * M-4180: Mirgrate research portal to use public module api and deprecate Node.js
  M004 : 19 May 2022 : Adil
		* M-3944: Deleting a homepage slider sometimes deletes 2 instead of the one selected
  M003 : 01 Jun 2021 : Aman
		* M-3426: Add markets and stocks coverage menu under latest research
  M002 : 21 Apr 2021 : Aman
		* M-3347: Allow upload of Our People photos to be uploaded via Team Management page
  M001 : 24 Nov 2020 : Aman
		* M-3069: Allow arranging the order of the homepage sliders
**************************************************************************************************************
*/

import { all, put, takeLatest } from 'redux-saga/effects';
import qs from 'querystring';
import { doGet, doPost, doUpload } from '../utils/request';

import {
  uploadHeaderImageSuccess,
  uploadHeaderImageFail,
  getHeaderImageSuccess,
  getHeaderImageFail,
  setHeaderBannerDefaultSuccess,
  setHeaderBannerDefaultFail,
  setActiveTabState,
  saveFavouritesSuccess,
  saveFavouritesFail,
  getFavouritesSuccess,
  getFavouritesFail,
  getPostsSuccess,
  getPostsFail,
  createPostSuccess,
  createPostFail,
  deletePostSuccess,
  deletePostFail,
  updatePostSuccess,
  updatePostFail,
  publishPostSuccess,
  publishPostFail,
  deleteKeywordSuccess,
  deleteKeywordFail,
  unPublishPostFail,
  unPublishPostSuccess,
  saveBlogResearchSuccess,
  saveBlogResearchFail,
  uploadAnalystImageSuccess,
  uploadAnalystImageFail,
  getEventsApprovalEventsSuccess,
  getEventsApprovalEventsFail,
  updateBlogsListSuccess,
  updateBlogsListFail,
  approveEventsSuccess,
  approveEventsFail,
  getNavItemsSuccess,
  getNavItemsFail,
  editBiographySuccess,
  editBiographyFail,
  getEventApprovalListSuccess,
  getEventApprovalListFail,
  saveSliderOrderSuccess,
  saveSliderOrderFail,
  getParameterSuccess,
  getParameterFail,
  getCustomPageParameterSuccess,
  getCustomPageParameterFail,
  getMatrixDataSuccess,
  getMatrixDataFail,
} from '../actionCreators/Config';

function* uploadHeaderImage({ obj }) {
  try {
    const data = new FormData();
    data.append('file', obj.imageFile);
    const response = yield doUpload('/upload?type=banner', data); //M001, M006
    const dataCopy = [...obj.sliderData];
    if (dataCopy[obj.index].type === 'image') {
      dataCopy[obj.index].tempImage = response.data && response.data.path;
      if (response.data && response.data.file && response.data.file.public_id) {
        const oldPublicKey = dataCopy[obj.index].mediaKey;
        dataCopy[obj.index].oldMediaKey = oldPublicKey;
        dataCopy[obj.index].mediaKey = response.data.file.public_id;
      }
    }
    if (dataCopy[obj.index].type === 'video') {
      dataCopy[obj.index].tempVideo = response.data && response.data.path;
      if (response.data && response.data.file && response.data.file.public_id) {
        const oldPublicKey = dataCopy[obj.index].mediaKey;
        dataCopy[obj.index].oldMediaKey = oldPublicKey;
        dataCopy[obj.index].mediaKey = response.data.file.public_id;
      }
    }
    dataCopy[obj.index].duration = obj.duration;
    dataCopy[obj.index].format = obj.format;
    dataCopy[obj.index].active = true;
    yield put(setActiveTabState(obj.index));
    yield put(uploadHeaderImageSuccess(response.data, dataCopy));
  } catch (err) {
    console.log(err.message);
    yield put(uploadHeaderImageFail(err.message));
  }
}
//====================M002=========================
function* uploadAnalystImage({ obj }) {
  try {
    const data = new FormData();
    data.append('file', obj.imageFile);
    // data.append('id', obj.ID);
    const response = yield doPost('/upload?type=analyst&id=' + obj.ID, data); //M001
    yield put(uploadAnalystImageSuccess(response.data));
  } catch (err) {
    yield put(uploadAnalystImageFail(err.message));
  }
}
//====================M002=========================
function* saveSliderSettings({ obj }) {
  try {
    const response = yield doPost('/saveSlider', obj); //M001
    // const dataCopy = [...obj.sliderData];
    // dataCopy[obj.index].tempImage = response.data && response.data.path;
    // yield put(uploadHeaderImageSuccess(response.data, dataCopy));
    // history.push('/');
    // const userAgnt = window.navigator.userAgent;
    // const isIE = /MSIE|Trident|Edge\//.test(userAgnt);
    // if (isIE) {
    //   window.location.reload();
    // }
  } catch (err) {
    yield put(uploadHeaderImageFail(err.message));
  }
}

//====================M001==========================
function* saveSliderOrder({ obj, history }) {
  try {
    const response = yield doPost('/saveSlider', obj);
    yield put(saveSliderOrderSuccess(response)); // M004
  } catch (err) {
    yield put(saveSliderOrderFail(err.message)); // M004
  }
}

function* saveBlogResearch({ obj }) {
  try {
    const response = yield doPost('/post/saveBlogResearch', obj);
    yield put(saveBlogResearchSuccess(response.data));
  } catch (err) {
    yield put(saveBlogResearchFail(err.message));
  }
}

function* editBiography({ obj }) {
  try {
    const response = yield doPost('/editBiography', obj);
    yield put(editBiographySuccess(response.data));
  } catch (err) {
    yield put(editBiographyFail(err.message));
  }
}
//====================M001==========================
function* uploadHeaderImageV1({ obj, history }) {
  try {
    const data = new FormData();
    data.append('file', obj.imageFile);
    data.append('key', 'HeaderImage');
    const response = yield doPost('/upload?type=banner', data); //M001
    yield put(getHeaderImageSuccess(response.data, obj));
    history.push('/');
    const userAgnt = window.navigator.userAgent;
    const isIE = /MSIE|Trident|Edge\//.test(userAgnt);
    if (isIE) {
      window.location.reload();
    }
  } catch (err) {
    yield put(uploadHeaderImageFail(err.message));
  }
}

function* getHeaderImage({ obj }) {
  try {
    const response = yield doGet(`/getHeaderImage?${qs.stringify(obj)}`);
    yield put(getHeaderImageSuccess(response.data, obj));
  } catch (err) {
    yield put(getHeaderImageFail(err.message));
  }
}

function* setHeaderImageDefault({ obj }) {
  try {
    const response = yield doGet(`/setHeaderImageDefault?${qs.stringify(obj)}`);
    yield put(setHeaderBannerDefaultSuccess(response.data));
  } catch (err) {
    yield put(setHeaderBannerDefaultFail(err.message));
  }
}

function* getFavouritesTopic({ obj }) {
  const response = yield doGet(`/blogs`); //M001
  try {
    yield put(getFavouritesSuccess(response.data));
  } catch (err) {
    yield put(getFavouritesFail(err.message));
  }
}

function* getMatrixData({ obj }) {
  const response = yield doGet(`/getMatrixData`); //M001
  try {
    yield put(getMatrixDataSuccess(response.data));
  } catch (err) {
    yield put(getMatrixDataFail(err.message));
  }
}

function* setFavouritesTopic({ obj }) {
  const response = yield doPost(`/blog/config`, obj);
  try {
    yield put(saveFavouritesSuccess());
  } catch (err) {
    yield put(saveFavouritesFail(err.message));
  }
}

function* updateBlogsList({ obj }) {
  const response = yield doPost(`/updateBlogsList`, obj);
  try {
    yield put(updateBlogsListSuccess(response.data));
  } catch (err) {
    yield put(updateBlogsListFail(err.message));
  }
}

function* deleteFavouritesTopic({ obj }) {
  const response = yield doGet(`/deleteFavourite?${qs.stringify(obj)}`);
  try {
    yield put(deleteKeywordSuccess(response.data));
  } catch (err) {
    yield put(deletePostFail(err.message));
  }
}
function* createPost({ obj }) {
  const response = yield doPost(`/blog/createPost`, obj);
  try {
    yield put(createPostSuccess(response.data));
  } catch (err) {
    yield put(createPostFail(err.message));
  }
}
function* getPost({ obj }) {
  const response = yield doGet(`/blog/getPosts?${qs.stringify(obj)}`);
  try {
    yield put(getPostsSuccess(response.data));
  } catch (err) {
    yield put(getPostsFail(err.message));
  }
}
function* deletePost({ obj }) {
  const response = yield doGet(`/post/delete?${qs.stringify(obj)}`);
  try {
    yield put(deletePostSuccess(response.data));
  } catch (err) {
    yield put(deletePostFail(err.message));
  }
}
function* updatePost({ obj }) {
  const response = yield doPost(`/post/updatePost/${obj.postId}`, obj);
  try {
    yield put(updatePostSuccess(response.data));
  } catch (err) {
    yield put(updatePostFail(err.message));
  }
}
function* publishPost({ obj }) {
  const response = yield doGet(`/publishPost?${qs.stringify(obj)}`);
  try {
    yield put(publishPostSuccess(response.data));
  } catch (err) {
    yield put(publishPostFail(err.message));
  }
}
function* unPublishPost({ obj }) {
  const response = yield doGet(`/unPublishPost?${qs.stringify(obj)}`);
  try {
    yield put(unPublishPostSuccess(response.data));
  } catch (err) {
    yield put(unPublishPostFail(err.message));
  }
}

function* getApprovalEvents() {
  const response = yield doGet(`/connect/approvalEvents`);
  try {
    yield put(getEventsApprovalEventsSuccess(response.data));
  } catch (err) {
    yield put(getEventsApprovalEventsFail(err.message));
  }
}

function* getApprovalEventList({ cmpg_id, evt_id }) {
  const response = yield doGet(`/connect/approvalEvent/${cmpg_id}/${evt_id}`);
  try {
    yield put(getEventApprovalListSuccess(response.data));
  } catch (err) {
    yield put(getEventApprovalListFail(err.message));
  }
}

function* approveEvents({ cmpg_id, evt_id, approveList }) {
  const response = yield doPost(`/connect/approvalEvent/${cmpg_id}/${evt_id}/approve`, {
    approveList: approveList,
  });
  try {
    yield put(approveEventsSuccess(response.data));
  } catch (err) {
    yield put(approveEventsFail(err.message));
  }
}
//====================M003==========================
function* getNavItems({ obj }) {
  const response = yield doGet(`/getNavItems`);
  try {
    yield put(getNavItemsSuccess(response.data));
  } catch (err) {
    yield put(getNavItemsFail(err.message));
  }
}

function* getParameter({ obj }) {
  const response = yield doGet(`/getParameter/${obj}`);
  try {
    yield put(getParameterSuccess(response.data));
  } catch (err) {
    yield put(getParameterFail(err.message));
  }
}

function* getCustomPageParameter({ obj }) {
  const response = yield doGet(`/getCustomPageParam/${obj}`);
  try {
    yield put(getCustomPageParameterSuccess(response.data));
  } catch (err) {
    yield put(getCustomPageParameterFail(err.message));
  }
}
//====================M003==========================
export default function* watcher() {
  yield all([takeLatest('PUBLISH_POST', publishPost)]);
  yield all([takeLatest('UNPUBLISH_POST', unPublishPost)]);
  yield all([takeLatest('GET_FAVOURITES', getFavouritesTopic)]);
  yield all([takeLatest('CREATE_POST', createPost)]);
  yield all([takeLatest('GET_POSTS', getPost)]);
  yield all([takeLatest('UPDATE_POST', updatePost)]);
  yield all([takeLatest('DELETE_POST', deletePost)]);
  yield all([takeLatest('DELETE_KEYWORD', deleteFavouritesTopic)]);
  yield all([takeLatest('SET_FAVOURITES', setFavouritesTopic)]);
  yield all([takeLatest('UPDATE_BLOGS_LIST', updateBlogsList)]);
  yield all([takeLatest('UPLOAD_HEADER_IMAGE', uploadHeaderImage)]);
  yield all([takeLatest('UPLOAD_ANALYST_IMAGE', uploadAnalystImage)]);
  yield all([takeLatest('GET_HEADER_IMAGE', getHeaderImage)]);
  yield all([takeLatest('SET_HEADER_IMAGE_DEFAULT', setHeaderImageDefault)]);
  yield all([takeLatest('SAVE_SLIDER_SETTINGS', saveSliderSettings)]);
  yield all([takeLatest('SAVE_BLOG_RESEARCH', saveBlogResearch)]);
  //====================M001==========================
  yield all([takeLatest('SAVE_SLIDER_ORDER', saveSliderOrder)]);
  //====================M001==========================
  yield all([takeLatest('GET_EVENTS_APPROVAL_EVENTS', getApprovalEvents)]);
  yield all([takeLatest('GET_EVENT_APPROVAL_LIST', getApprovalEventList)]);
  yield all([takeLatest('APPROVE_EVENTS', approveEvents)]);
  yield all([takeLatest('GET_NAVITEMS', getNavItems)]); //M003
  yield all([takeLatest('EDIT_BIOGRAPHY', editBiography)]);
  yield all([takeLatest('GET_PARAMETER', getParameter)]);
  yield all([takeLatest('GET_CUSTOMPAGE_PARAMETER', getCustomPageParameter)]);
  yield all([takeLatest('GET_MATRIX_DATA', getMatrixData)]);
}
