/*
**************************************************************************************************************
						ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
						Amendments
========================================================================
	M001 : 23 July 2020 : Aman
    * M-0002952: Research menu does not have China Strategy category.
  M002 : 29 Oct 2020  : Nick
    * M-0003079: Reorder products in the research menu
  M003 : 14 Jan 2020  : Nick
    * M-0003217: Research menu should have an option Latest Research
  M004 : 26 February 2021 : Nick
		* M-0003289: Multilevel of menu bar
**************************************************************************************************************
*/
export const getUsername = user => {
  try {
    return user.full_name
      .trim()
      .split(' ')
      .filter((v, i) => i < 2)
      .map(name => name[0].toUpperCase())
      .join('');
  } catch (err) {
    return null;
  }
};

export const navItems = [
  {
    label: 'Research',
    path: '/home',
    type: 'dropdown',
    items: [
      {
        label: 'Latest Research',
        path: '/home',
      },
      {
        type: 'dropdown',
        label: 'Macro',
        items: [
          {
            label: 'Global Strategy',
            path: '/sectors/10000131',
          },
          {
            label: 'Asia Equity Strategy',
            path: '/sectors/10000047',
          },
          {
            label: 'Asianomics',
            path: '/sectors/10000133',
          },
          {
            label: 'China Strategy',
            path: '/sectors/10000142',
          },
          {
            label: 'India Strategy',
            path: '/sectors/10000147',
          },
          {
            label: 'Tech Thematic Strategy',
            path: '/sectors/10000087',
          },
          {
            label: 'Aletheia 360',
            path: '/sectors/10000138',
          },
        ],
      },
      {
        type: 'dropdown',
        label: 'Sectors',
        items: [
          {
            type: 'dropdown',
            label: 'Energy',
            path: '/sectors/10000136',
            items: [
              {
                label: 'Oil & Gas',
                path: '/sectors/10000157',
              },
              {
                label: 'Coal',
                path: '/sectors/10000158',
              },
              {
                label: 'Energy Equipment & Services',
                path: '/sectors/10000159',
              },
            ],
          },
          {
            type: 'dropdown',
            label: 'Materials',
            path: '/sectors/10000148',
            items: [
              {
                label: 'Chemicals',
                path: '/sectors/10000160',
              },
              {
                label: 'Agrochemicals',
                path: '/sectors/10000161',
              },
              {
                label: 'Construction Materials',
                path: '/sectors/10000162',
              },
              {
                label: 'Cement',
                path: '/sectors/10000163',
              },
            ],
          },
          {
            type: 'dropdown',
            label: 'Industrials',
            path: '/sectors/10000149',
            items: [
              {
                label: 'Constructioin & Infra',
                path: '/sectors/10000164',
              },
              {
                label: 'Logistics',
                path: '/sectors/10000165',
              },
              {
                label: 'Transport Tech',
                path: '/sectors/10000143',
              },
              {
                label: 'Ports',
                path: '/sectors/10000166',
              },
              {
                label: 'General',
                path: '/sectors/10000167',
              },
            ],
          },
          {
            type: 'dropdown',
            label: 'Consumer Discretionary',
            path: '/sectors/10000150',
            items: [
              {
                label: 'Automobiles',
                path: '/sectors/10000168',
              },
              {
                label: 'Consumer Durables',
                path: '/sectors/10000169',
              },
              {
                label: 'Other Consumer',
                path: '/sectors/10000170',
              },
            ],
          },
          {
            label: 'Consumer Staples',
            path: '/sectors/10000152',
          },
          {
            label: 'Health Care',
            path: '/sectors/10000151',
          },
          {
            type: 'dropdown',
            label: 'Financials',
            path: '/sectors/10000137',
            items: [
              {
                label: 'Banks',
                path: '/sectors/10000171',
              },
              {
                label: 'Consumer Finance',
                path: '/sectors/10000172',
              },
              {
                label: 'Insurance',
                path: '/sectors/10000173',
              },
            ],
          },
          {
            label: 'Technology Hardware',
            path: '/sectors/10000126',
          },
          {
            label: 'IT Services & Software',
            path: '/sectors/10000153',
          },
          {
            label: 'Internet',
            path: '/sectors/10000132',
          },
          {
            label: 'Media',
            path: '/sectors/10000154',
          },
          {
            label: 'Telecoms',
            path: '/sectors/10000140',
          },
          {
            label: 'Utilities',
            path: '/sectors/10000155',
          },
          {
            label: 'Real Estate',
            path: '/sectors/10000156',
          },
        ],
      },
      {
        type: 'dropdown',
        label: 'Markets',
        items: [
          {
            label: 'Global',
            tag: 'Geography: Global',
            path: '/home',
          },
          {
            label: 'Asia',
            tag: 'Geography: Asia',
            path: '/home',
          },
          {
            label: 'Greater China',
            tag: 'Geography: Greater China',
            path: '/home',
          },
          {
            label: 'China',
            tag: 'Geography: China',
            path: '/home',
          },
          {
            label: 'Hong Kong',
            tag: 'Geography: Hong Kong',
            path: '/home',
          },
          {
            label: 'Taiwan',
            tag: 'Geography: Taiwan',
            path: '/home',
          },
          {
            label: 'Singapore',
            tag: 'Geography: Singapore',
            path: '/home',
          },
          {
            label: 'South Korea',
            tag: 'Geography: South Korea',
            path: '/home',
          },
          {
            label: 'Asean',
            tag: 'Geography: Asean',
            path: '/home',
          },
          {
            label: 'Indonesia',
            tag: 'Geography: Indonesia',
            path: '/home',
          },
          {
            label: 'Malasysia',
            tag: 'Geography: Malasysia',
            path: '/home',
          },
          {
            label: 'Philippines',
            tag: 'Geography: Philippines',
            path: '/home',
          },
          {
            label: 'Thailand',
            tag: 'Geography: Thailand',
            path: '/home',
          },
          {
            label: 'Viet Nam',
            tag: 'Geography: Viet Nam',
            path: '/home',
          },
          {
            label: 'India',
            tag: 'Geography: India',
            path: '/home',
          },
          {
            label: 'Japan',
            tag: 'Geography: Japan',
            path: '/home',
          },
          {
            label: 'Australia',
            tag: 'Geography: Australia',
            path: '/home',
          },
          {
            label: 'Europe',
            tag: 'Geography: Europe',
            path: '/home',
          },
          {
            label: 'Eastern Europe',
            tag: 'Geography: Eastern Europe',
            path: '/home',
          },
          {
            label: 'USA',
            tag: 'Geography: USA',
            path: '/home',
          },
          {
            label: 'LATAM',
            tag: 'Geography: LATAM',
            path: '/home',
          },
        ],
      },
      {
        label: 'Stocks Coverage',
        path: '/companies',
      },
      {
        label: 'My Saved Reports',
        path: '/SavedResearches',
      },
    ],
  },
  {
    label: 'CONNECT',
    path: '/connect/videos',
    type: 'dropdown',
    items: [
      {
        label: 'Replay',
        path: '/connect/videos',
      },
      {
        label: 'Event Registration',
        path: '/connect/registration',
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'Our Firm',
    items: [
      {
        label: 'Our Firm at a Glance',
        path: '/about',
      },
      {
        label: 'Our People',
        path: '/people',
      },
      {
        label: 'Contact Us',
        path: '/contact',
      },
      {
        label: 'Careers',
        path: '/careers',
      },
    ],
  },
  {
    label: 'Blogs',
    path: '/blogs',
    type: 'favourites',
  },
];

export const preloginNavItems = [
  {
    label: 'Research',
    path: '/home',
  },
  {
    type: 'dropdown',
    label: 'Our Firm',
    items: [
      {
        label: 'Our Firm at a Glance',
        path: '/about',
      },
      {
        label: 'Our People',
        path: '/people',
      },
      {
        label: 'Contact Us',
        path: '/contact',
      },
      {
        label: 'Careers',
        path: '/careers',
      },
    ],
  },

  {
    label: 'Investor or Analyst',
    type: 'modal',
  },
];

export const TempUserNavItems = [
  {
    label: 'Research',
    path: '/home',
  },
  {
    type: 'dropdown',
    label: 'Our Firm',
    items: [
      {
        label: 'Our Firm at a Glance',
        path: '/about',
      },
      {
        label: 'Our People',
        path: '/people',
      },
      {
        label: 'Contact Us',
        path: '/contact',
      },
      {
        label: 'Careers',
        path: '/careers',
      },
    ],
  },
  {
    label: 'Blogs',
    path: '/blogs',
    type: 'favourites',
  },
  {
    label: 'Investor or Analyst',
    type: 'modal',
  },
];
