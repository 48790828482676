import React from 'react';
import { Grid } from 'react-bootstrap';

import Box from '../../components/Box';
import disclaimerText from './disclaimer.json';

const Container = () => (
  <Grid>
    <Box
      style={{ borderRadius: 5, textAlign: 'center' }}
      dangerouslySetInnerHTML={{
        __html: disclaimerText.content,
      }}
    />
  </Grid>
);

export default Container;
