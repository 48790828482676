import React from 'react';
import PropTypes from 'prop-types';
import { Modal,Button } from 'react-bootstrap';
import Table from '../../../components/Table'
import { confirmTableConfig } from './signupTableConfig';

const ConfirmModal = ({show, onConfirm, onCancel, evtList}) => {
  return (
    <Modal show={show} className="container-fake connect-confirm-modal">
        <Modal.Body>
            <div style={{ paddingLeft: '3%', fontSize: 12, marginTop: '5%', paddingRight: '3%'}}>
                <p>Thank you very much for registering for the following events.</p>
                <Table
                    title={"Events"}
                    searchAble={false}
                    labels={confirmTableConfig()}
                    data={evtList}
                />
                <p>We look forward to your participation in the event.</p>
                <p>Our team will get back to you shortly with your meeting schedule.</p>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button bsStyle="primary" onClick={onConfirm}>
                Confirm
            </Button>
            <Button onClick={onCancel}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
    // ========M001===================
  );
};

ConfirmModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
  };

export default ConfirmModal;
