const initialState = {
  stockInfo: {
    loading: false,
    error: null,
    data: [],
  },
  company: {
    loading: false,
    error: null,
    data: [],
  },
};

export default (state = initialState, { type, error, payload }) => {
  switch (type) {
    case 'GET_LATEST_RESEARCHES':
      return {
        ...state,
        stockInfo: {
          ...state.stockInfo,
          loading: true,
          error: null,
        },
      };

    case 'GET_COMPANY_STOCK_INFO_SUCCESS':
      return {
        ...state,
        stockInfo: {
          ...state.stockInfo,
          loading: false,
          data: [...state.stockInfo.data, payload],
        },
      };

    case 'GET_COMPANY_STOCK_INFO_FAIL':
      return {
        ...state,
        stockInfo: {
          ...state.stockInfo,
          loading: false,
          error,
        },
      };

    case 'GET_COMPANY':
      return {
        ...state,
        company: {
          ...state.company,
          loading: true,
          error: null,
        },
      };

    case 'GET_COMPANY_SUCCESS':
      return {
        ...state,
        company: {
          ...state.company,
          loading: false,
          data: payload,
          // data: [...state.company.data, payload],
        },
      };

    case 'GET_COMPANY_FAIL':
      return {
        ...state,
        company: {
          ...state.company,
          loading: false,
          error,
        },
      };

    default:
      return state;
  }
};
