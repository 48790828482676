/*
**************************************************************************************************************
											R&D TECH LIMITED
==============================================================================================================
The contents of this file ("Software") is the property of R&D Tech Limited ("R&D Tech") and is protected by
copyright and other intellectual property laws and treaties.

R&D Tech owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of copies of the
Software, redistribution of the Software and creation of derived works from the Software (and the
redistribution thereof) are subject to the terms of a license agreement issued by R&D Tech.

If the person in possession of the Software has not entered into a license agreement with R&D Tech, then any
use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author:
	Date:
**************************************************************************************************************
											Usage Notes
==============================================================================================================

**************************************************************************************************************
											Amendments
==============================================================================================================
    M001 : 05 Sep 2022 : Nick Ng
      * M-4112: Most Viewed options for time period - Research Portal changes
**************************************************************************************************************
*/


import React from 'react';
import { Col, Row, Glyphicon, NavDropdown, MenuItem } from 'react-bootstrap'; //M001
import ReportCard from '../../containers/Reports/components/ReportCard';
import Box from '../Box';
import { Link } from 'react-router-dom';
import { getStandardDateTime } from '../../utils/dateTime';
import { sanitizeText } from '../../utils';
import Card from '../Card';

const Component = ({
  leftResearches,
  rightResearches,
  loading,
  panelText,
  saveReports,
  savedVideos,
  Bookmark,
}) => {
  //==================M001==========[===============
  const [isHighlighted, setIsHighlighted] = React.useState(true);
  const [mostViewItem, setMostViewItem] = React.useState("Past Month");
  var researches = {};
  researches.data = [];
  if (isHighlighted) {
    researches.data = leftResearches;
  } else {
    researches.data = rightResearches;
    if (researches.data[0].mostviewed){
      researches.data = researches.data.filter(e=>{
        if(e.mostviewed[mostViewItem]){
          return true;
        } else {
          return false;
        }
      }).sort((a,b) => {
        return b.mostviewed[mostViewItem].count - a.mostviewed[mostViewItem].count;
      })
    }
  }
  const mostViewedOptionMenu = (researches) =>{
    //Edge Case
    if (researches.length < 1){
      return <></>;
    }
    if (researches[0].mostviewed){
      const menuItem = formatMenuItem(researches);
      return (
        <NavDropdown 
          pullRight         
          noCaret={true} 
          style={{float:"right"}} 
          title={<div style={{display: "inline-block"}}><Glyphicon glyph="glyphicon glyphicon-cog"/></div>}
          className="most-viewed-dropdown"
        >
          {menuItem}
        </NavDropdown>
      );
    } else {
      return <></>;
    }
  }

  const formatMenuItem = (researches) => {
    let menuItemMap = new Map();
    let menuItem = [];
    for (let r of researches){
      if (r.mostviewed){
        for (let key in r.mostviewed){
          menuItemMap.set(key, r.mostviewed[key].menuSort);
        }
      }
    }
    menuItemMap = new Map([...menuItemMap.entries()].sort((a, b) => a[1] - b[1]));
    menuItemMap.forEach((v,k)=>{
      menuItem.push(<MenuItem active={k===mostViewItem?true:false} key={k} onClick={()=>{setMostViewItem(k)}}>{k}</MenuItem>)
    })
    return menuItem
  }
  //==================M001==========]===============

  return (
    <Col xsHidden={true} mdHidden={true} smHidden={true} sm={4}>
      <Box style={{ padding: 0, borderRadius: '5px' }}>
        {isHighlighted ? (
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '115%',
              fontWeight: 700,
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
            }}
          >
            <Col
              sm={6}
              md={6}
              lg={6}
              style={{ margin: '0px', padding: '0px', paddingLeft: '15px' }}
            >
              <div
                style={{
                  textAlign: 'center',
                  padding: '3%',
                  background: '#036ca6',
                  color: 'white',
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                }}
              >
                <span className="HighlightTabs" onClick={() => setIsHighlighted(true)}>{panelText[0]}</span> {/* M001 */}
              </div>
            </Col>
            <Col
              sm={6}
              md={6}
              lg={6}
              style={{ margin: '0px', padding: '0px', paddingRight: '15px' }}
            >
              <div
                style={{
                  textAlign: 'center',
                  padding: '3%',
                  background: '#b8e6fe',
                  color: '#036ca6',
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                }}
              >
                <span className="HighlightTabs" onClick={() => setIsHighlighted(false)}>{panelText[1]}</span> {/* M001 */}
              </div>
            </Col>
          </Row>
        ) : (
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '115%',
              fontWeight: 700,
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
            }}
          >
            <Col
              sm={6}
              md={6}
              lg={6}
              style={{ margin: '0px', padding: '0px', paddingLeft: '15px' }}
            >
              <div
                style={{
                  textAlign: 'center',
                  padding: '3%',
                  background: '#b8e6fe',
                  color: '#036ca6',
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                }}
              >
                <span className="HighlightTabs" onClick={() => setIsHighlighted(true)}>{panelText[0]}</span> {/* M001 */}
              </div>
            </Col>
            <Col
              sm={6}
              md={6}
              lg={6}
              style={{ margin: '0px', padding: '0px', paddingRight: '15px' }}
            >
              <div
                style={{
                  textAlign: 'center',
                  padding: '3%',
                  background: '#036ca6',
                  color: 'white',
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                }}
              >
                <span className="HighlightTabs" onClick={() => setIsHighlighted(false)}>{panelText[1]}</span> {/* M001 */}
                {/* Option Menu For Most View Back Days */}
                  {loading || !researches.data? <></>: mostViewedOptionMenu(researches.data)} {/* M001 */}
              </div>
            </Col>
          </Row>
        )}

        {/* Loading */}
        {loading || !researches.data
          ? Array.from({ length: 20 }).map((v, i) => (
              <Card key={`loading_research_i_${i}`} style={{ margin: '2px 0px' }} loading />
            ))
          : researches.data.map((item, i) => (
              <ReportCard
                Bookmark={Bookmark}
                SavedVideos={savedVideos}
                OnSaveClick={saveReports}
                reportid={item.id}
                key={`macro_highlighted_research_${item.id}_${i}`}
                research={{
                  ...item,
                  title:
                    typeof item.title === 'string' ? (
                      <>
                        <Link
                          to={`/reports/${item.id}_${sanitizeText(item.title)}`}
                          className="card-title"
                        >
                          {item.title}
                        </Link>
                        {item.analysts[0] && (
                          <small>
                            {` by `}
                            <Link to={`/analysts/${item.analysts[0].id}`} className="card-title">
                              {item.analysts[0].name}
                            </Link>
                          </small>
                        )}
                      </>
                    ) : (
                      <>{item.title}</>
                    ),
                  published_date: getStandardDateTime(item.published_date, { part: 'date' }),
                }}
                style={{ margin: '4px 0px', borderRadius: '5px' }}
                showAvatar={false}
              />
            ))}
      </Box>
    </Col>
  );
};

Component.defaultProps = {
  panelText: ['Must Read', 'Most Viewed'],
  Bookmark: true,
};

export default Component;
