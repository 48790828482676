/*
**************************************************************************************************************
            ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
            Amendments
========================================================================
    M001 : 23 July 2020 : Aman
    * M-0002945: Homepage - Tickers section redesign.
    M002 : 24 July 2020 : Aman
    * M-0002946: Homepage - Vital Information redesign.
    M003 : 5 August 2020 : Nicho
    * M-0002970: Homepage - Vision section redesign
    M004 : 14 August 2020 : Nicho
    * M-0002968: Homepage - Our Research section redesign
    M005 : 14 August 2020 : Nick
    * M-0002944: Homepage - Banner section redesign
    M006 : 19 April 2023 : Zeeshan
    * M-0004369: Remove the "Our Vision Our Mission, Our Values" section on the homepage of the research portal  
**************************************************************************************************************
*/
import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import NewsTicker from './components/NewsTicker';
import Banner from './components/Banner';
import OurResearch from './components/OurResearch/OurResearch';
import OurPeople from './components/OurPeople';
import OurMission from './components/OurMission';
import OurVision from './components/OurVision';
import OurValues from './components/OurValues';
import VisionMissionValuesIcon from './components/VisionMissionValuesIcon';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import FullPageLoader from '../../components/FullPageLoader/index';

//====================M002=====[====================
import VitalInfo from './components/VitalInfo';
//====================M002=====]====================
import useFullPageLoader from '../../hooks/useFullPageLoader';
const Container = ({ latestResearch, homepageResearch }) => {
  const [hackLoading, sethackLoading] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      sethackLoading(false);
    }, 1000);
  });
  return (
    <>
      <Helmet>
        <title>Aletheia Capital</title>
        <meta
          name="description"
          content="Alētheia Capital has built Asia’s First and Largest Fully Independent Content Firm through organic growth as well as acquisitions. Our Vision is to be a client centric  technology-enabled firm with a market-leading, independent, unbiased and fully compliant Asian Content. We intend to create the first Independent Research Entity with scale by attracting and retaining the best analysts, and by providing research based on client requirements with delivery customized to the clients'​ choosing. "
        />
      </Helmet>
      {/* //====================M001=====[==================== */}

      {/* //====================M005=====[==================== */}
      {latestResearch.loading || homepageResearch.loading || hackLoading ? (
        <FullPageLoader></FullPageLoader>
      ) : (
        <></>
      )}
      {/* {loader} */}
      <Banner />
      {/* //====================M005=====[==================== */}

      <NewsTicker />
      {/* //====================M001=====[==================== */}

      {/* //====================M002=====[==================== */}
      <VitalInfo />
      {/* //====================M002=====[==================== */}

      {/*====================M004==========================*/}
      <OurResearch />
      {/*====================M004==========================*/}
      <Grid>
        <OurPeople />
      </Grid>
      {/*====================M003==========================*/}
      <Row
        style={{ display: 'none' }} //M006
        className="vmv-container">
        <VisionMissionValuesIcon />
        <Grid className="vmv-text">
          <OurVision />
          <OurMission />
          <OurValues />
        </Grid>
      </Row>
      {/*====================M003==========================*/}
    </>
  );
};

function mapStateToProps(state) {
  const latestResearch = state.research.latestResearches;
  const homepageResearch = state.research.homePageLatestResearches;
  return { latestResearch, homepageResearch };
}

export default withRouter(connect(mapStateToProps)(Container));
