import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl, HelpBlock, Checkbox, Radio } from 'react-bootstrap';

const Component = ({
  input,
  options,
  style,
  label,
  required,
  type,
  disabled,
  meta: { touched, error, warning },
}) => {
  let validationState = null;

  if (touched) {
    if (error) {
      validationState = 'error';
    } else if (warning) {
      validationState = 'warning';
    }
  }

  switch (type) {
    case 'LoginEmail':
    case 'SignupFullName':
      return (
        <FormGroup
          controlId={label
            .split(' ')
            .join('_')
            .toLowerCase()}
          validationState={validationState}
          // className={`${type}-form-group`}
        >
          <FormControl
            {...input}
            type="text"
            placeholder={label}
            required={required}
            disabled={disabled}
            autoComplete="off"
            autoFocus
          />
          {touched && <HelpBlock>{error || warning}</HelpBlock>}
        </FormGroup>
      );

    case 'text':
    case 'password':
      return (
        <FormGroup
          controlId={label
            .split(' ')
            .join('_')
            .toLowerCase()}
          validationState={validationState}
          // className={`${type}-form-group`}
        >
          <FormControl
            {...input}
            type={type}
            placeholder={label}
            required={required}
            disabled={disabled}
            autoComplete="off"
          />
          {touched && <HelpBlock>{error || warning}</HelpBlock>}
        </FormGroup>
      );

    case 'checkbox':
      return (
        <Checkbox style={style} required={required} disabled={disabled}>
          {label}
        </Checkbox>
      );
    case 'textarea':
      return (
        <FormControl
          componentClass="textarea"
          {...input}
          type={type}
          placeholder={label}
          required={required}
          disabled={disabled}
          autoComplete="off"
          className="text-area"
        />
      );
    case 'radio-group':
      return (
        <FormGroup>
          <span className="pad-right">{label}:</span>
          {options.map(option => (
            <Radio
              key={`${label
                .split(' ')
                .join('_')
                .toLowerCase()}_${Math.random()}`}
              name={label
                .split(' ')
                .join('_')
                .toLowerCase()}
              value={option.value}
              className="pad-right"
              disabled={disabled}
              required={required}
              inline
            >
              {option.name}
            </Radio>
          ))}
        </FormGroup>
      );

    default:
      return null;
  }
};

Component.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.object,
  options: PropTypes.array,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object,
  style: PropTypes.object,
  required: PropTypes.bool,
};

Component.defaultProps = {
  disabled: false,
  input: {},
  options: [],
  meta: {},
  style: undefined,
  required: false,
};

export default Component;
