import React from 'react';
import { Button } from 'react-bootstrap';
import { Typography } from '@mui/material';

const BookingCard = ({ Date, Time, Timezone, ClickHandler, isBooked, CancelClickHandler }) => {
  return (
    <div className="bookingCard-container">
      <div style={{ marginBottom: '25px' }}>
        <span style={{ fontSize: '13px', fontWeight: 'bold' }}>{Date}</span>
      </div>
      <Typography style={{ fontSize: '14px' }}>{Time}</Typography>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {isBooked == 'AA==' ? (
          <>
            <div>
              <Typography style={{ fontSize: '12px', fontWeight: '500' }}>{Timezone}</Typography>
            </div>
            <div>
              <Button
                bsStyle="primary"
                className="bookingCard-button"
                onClick={() => ClickHandler()}
              >
                Book
              </Button>
            </div>
          </>
        ) : (
          <>
            <div>
              <Typography style={{ fontSize: '12px', fontWeight: '500' }}>{Timezone}</Typography>
            </div>
            <div>
              <Button
                bsStyle="primary"
                className="bookingCard-cancel-button"
                onClick={() => CancelClickHandler()}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BookingCard;
