export const getBlogInfo = obj => ({
    type: 'GET_BLOG_INFO',
    obj,
});

export const getBlogInfoSuccess = (data, obj) => ({
    type: 'GET_BLOG_INFO_SUCCESS',
    data,
    obj,
});

export const getBlogInfoFail = error => ({
    type: 'GET_BLOG_INFO_FAIL',
    error,
});

export const getPosts = obj => ({
    type: 'GET_BLOG_POSTS',
    obj,
});

export const getPostsSuccess = (data, obj) => ({
    type: 'GET_BLOG_POSTS_SUCCESS',
    data,
    obj,
});

export const getPostsFail = error => ({
    type: 'GET_BLOG_POSTS_FAIL',
    error,
});

export const getPost = obj => ({
    type: 'GET_BLOG_POST',
    obj,
});

export const getPostSuccess = (data, obj) => ({
    type: 'GET_BLOG_POST_SUCCESS',
    data,
    obj,
});

export const getPostFail = error => ({
    type: 'GET_BLOG_POST_FAIL',
    error,
});

export const getPostAssociatedResearches = obj => ({
    type: 'GET_BLOG_POST_ASSOCIATED_RESEARCHES',
    obj,
});

export const getPostAssociatedResearchesSuccess = (data, obj) => ({
    type: 'GET_BLOG_POST_ASSOCIATED_RESEARCHES_SUCCESS',
    data,
    obj,
});

export const getPostAssociatedResearchesFail = error => ({
    type: 'GET_BLOG_POST_ASSOCIATED_RESEARCHES_FAIL',
    error,
});

export const getPostStat = id => ({
    type: 'GET_BLOG_POST_STAT',
    id,
});

export const getPostStatSuccess = (data) => ({
    type: 'GET_BLOG_POST_STAT_SUCCESS',
    data
});

export const getPostStatFail = error => ({
    type: 'GET_BLOG_POST_STAT_FAIL',
    error,
});