import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

// import InstagramIcon from '../../../assets/images/instagram.svg';
import TwitterIcon from '../../../assets/images/twitter.svg';
import FacebookIcon from '../../../assets/images/facebook.svg';
import LinkedInIcon from '../../../assets/images/linkedin.svg';

const Component = ({ externalPdfUrl, signUpRoute, iconHeight, iconMargin, ...props }) => (
  <div {...props}>
    {/* <a href="https://www.instagram.com/aletheiacapital" target="_blank" rel="noopener noreferrer">
      <Image src={InstagramIcon} style={{ height: iconHeight, margin: iconMargin }} />
    </a> */}
    {externalPdfUrl ? (
      <>
        <a href={signUpRoute} target="_blank" rel="noopener noreferrer">
          <Image
            alt="twitterIcon"
            src={TwitterIcon}
            style={{ height: iconHeight, margin: iconMargin }}
          />
        </a>
        <a href={signUpRoute} target="_blank" rel="noopener noreferrer">
          <Image
            alt="FacebookIcon"
            src={FacebookIcon}
            style={{ height: iconHeight, margin: iconMargin }}
          />
        </a>
        <a href={signUpRoute} target="_blank" rel="noopener noreferrer">
          <Image
            alt="LinkedInIcon"
            src={LinkedInIcon}
            style={{ height: iconHeight, margin: iconMargin }}
          />
        </a>
      </>
    ) : (
      <>
        <a
          href="https://www.facebook.com/AletheiaCapital"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            alt="FacebookIcon"
            src={FacebookIcon}
            style={{ height: iconHeight, margin: iconMargin }}
          />
        </a>
        <a href="https://twitter.com/AletheiaCapital" target="_blank" rel="noopener noreferrer">
          <Image
            alt="TwitterIcon"
            src={TwitterIcon}
            style={{ height: iconHeight, margin: iconMargin }}
          />
        </a>
        <a
          href="https://www.linkedin.com/company/aletheiacapital"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            alt="LinkedInIcon"
            src={LinkedInIcon}
            style={{ height: iconHeight, margin: iconMargin }}
          />
        </a>
      </>
    )}
  </div>
);

Component.propTypes = {
  iconHeight: PropTypes.number,
  iconMargin: PropTypes.number,
  externalPdfUrl: PropTypes.object,
  signUpRoute: PropTypes.string,
};

Component.defaultProps = {
  iconHeight: 15,
  iconMargin: 5,
  externalPdfUrl: null,
  signUpRoute: null,
};

export default Component;
